const ObjFormat = {
    camelToSnakeCase: (obj: object) => {
        return convertToSnakeCase(obj)
    },
    snakeToCamelCase: (obj: object): object => {
        return convertToCamelCase(obj)
    },
}

function convertToCamelCase(obj: any): object {
    let newObject = {}

    for (let i in obj) {

        if (Array.isArray(obj[i])) {
            const newKey = stringToCamelCase(i)
            newObject = {...newObject, [newKey]: obj[i]}
        }
        else if (typeof obj[i] === 'object' && obj[i] !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let aux = convertToCamelCase(obj[i])
            const newKey = stringToCamelCase(i)
            newObject = {...newObject, [newKey]: aux}
        }
        else {
            const newKey = stringToCamelCase(i)
            newObject = {...newObject, [newKey]: obj[i]}
        }
    }

    return newObject
}

function convertToSnakeCase(obj: any): object {
    let newObject = {}

    for (let i in obj) {

        if (Array.isArray(obj[i])) {
            const newKey = stringToSnakeCase(i)
            newObject = {...newObject, [newKey]: obj[i]}
        }
        else if (typeof obj[i] === 'object' && obj[i] !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let aux = convertToSnakeCase(obj[i])
            const newKey = stringToSnakeCase(i)
            newObject = {...newObject, [newKey]: aux}
        } else {
            const newKey = stringToSnakeCase(i)
            newObject = {...newObject, [newKey]: obj[i]}
        }
    }

    return newObject
}

function stringToCamelCase(value: string): string {
    let newKey: string = ''
    value.split('_').forEach((key, index) => {
        if (index === 0) {
            newKey += key
        } else {
            newKey += key.charAt(0).toUpperCase() + key.slice(1)
        }
    })

    return newKey
}

function stringToSnakeCase(value: string): string {
    return value.replace(/[A-Z0-9]/g, (letter: string) => `_${letter.toLowerCase()}`);
}

export default ObjFormat
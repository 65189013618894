// styles
import {Grid} from "@mui/material"
import {styled} from "@mui/material/styles";

export const Container = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        height: '100%'
    },
    [theme.breakpoints.up('md')]: {
        height: '100vh'
    }
}))

export const Content = styled(Grid)({
    margin: 0,
    padding: 0
})
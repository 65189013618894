// components
import { Bar } from "react-chartjs-2";
import {Chart, registerables } from 'chart.js'

// styles
import {
    CardContainer,
    CardHeaderTitle,
    GraphicContainer,
} from "./styles";

interface DataGraphic {
    labels: string[]
    datasets: any[]
}

interface Props {
    title: string
    data: DataGraphic
}

function BarGraphic(props: Props) {
    Chart.register(...registerables)
    const {title, data} = props

    let options = {
        responsive: true,
		indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            }
        }
    };

    return (
        <CardContainer>
            <CardHeaderTitle>{title}</CardHeaderTitle>
            <GraphicContainer>
                <Bar options={options} data={data} />
            </GraphicContainer>
        </CardContainer>
    )
}

export default BarGraphic
// main libraries

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StorefrontIcon from "@mui/icons-material/Storefront";

// styles
import {Container, Item, EndIcon, Row, Title} from "./styles";

interface Props {
    firstTitle: string
    secondTitle: string
    disabled: boolean
    handleOpen: () => void
}

function ArrowButton(props: Props) {
    const {firstTitle, secondTitle, disabled, handleOpen} = props

    return (
        <Item disabled={disabled} onClick={handleOpen}>
            <Container>
                <Row>
                    <Title>{firstTitle} <strong>{secondTitle}</strong></Title>
                </Row>
            </Container>
            <EndIcon disabled={disabled}>
                <ChevronRightIcon fontSize="inherit"/>
            </EndIcon>
        </Item>
    )
}

export default ArrowButton
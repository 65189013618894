import {styled} from "@mui/system";
import {Card, Divider, Typography} from "@mui/material";
import colors from "../../../utils/colors";

const getHeight = (cardHeight?: boolean): string => {
    if (cardHeight) return '400px'
    return '460px'
}

interface ItemProps {
    showscroll?: boolean
    cardHeight?: boolean
}

export const AvatarTitle = styled(Typography)({
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 14,
    color: colors.text,
    margin: '5px 0 15px'
})

export const CardContainer = styled(Card)<ItemProps>(({cardHeight}) => ({
    width: '100%',
    height: getHeight(cardHeight),
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '12px 18px',
    marginBottom: 20,
}))

export const CustomDivider = styled(Divider)({
    borderColor: colors.text,
    margin: '15px 0'
})

export const EmptyContainer = styled('div')({
    marginTop: 25,
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})

export const EmptyIcon = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    fontSize: 60,
    color: colors.text,
    padding: 8,
})

export const TextEmpty = styled('div')({
    display: 'flex',
    textAlign: 'center',
    fontSize: 23,
    fontWeight: 500,
    color: colors.text,
    padding: 8,
})
// main libraries
import {Close, Done, PriorityHigh, WarningAmber} from "@mui/icons-material";

//styles
import {ConfirmButton, Container, CustomCard, Description, IconContainer, Title} from './styles';
import colors from "../../../utils/colors";
import { Grid } from "@mui/material";

interface Props {
    open?: boolean
    isConfirmation?: boolean
    icon?: JSX.Element
    type: 'success' | 'error' | 'info' | 'warning' | 'custom' | 'confirmation'
    title: string;
    description?: string
    labelButton: string
    labelButtonConfirm?: string
    onClickConfirm?: () => void
    onClick: () => void
}

function getIcon(type: 'success' | 'error' | 'info' | 'warning' | 'custom' | 'confirmation', icon?: JSX.Element) {
    switch (type) {
        case 'success':
            return <IconContainer backgroundColor={colors.second}>
                <Done style={{fontSize: 100, color: '#fff'}}/>
            </IconContainer>
        case "error":
            return <IconContainer backgroundColor={colors.redColor}>
                <Close style={{fontSize: 100, color: '#fff'}}/>
            </IconContainer>
        case "info":
            return <IconContainer backgroundColor={colors.text}>
                <PriorityHigh style={{fontSize: 100, color: '#fff'}}/>
            </IconContainer>
        case "warning":
            return <IconContainer backgroundColor={colors.orangeColor}>
                <WarningAmber style={{fontSize: 100, color: '#fff'}}/>
            </IconContainer>
        case "custom":
            return <IconContainer backgroundColor={colors.second}>{icon}</IconContainer>
        case "confirmation":
            return <IconContainer backgroundColor={colors.redColor}>
                <Close style={{fontSize: 100, color: '#fff'}}/>
            </IconContainer>
    }
}

function ModalLoading(props: Props) {
    const {open, isConfirmation, type, icon, description, title, labelButton, labelButtonConfirm, onClick, onClickConfirm} = props

    return (
        <>
            {open ?
                <Container>
                    <CustomCard elevation={8}>
                        {getIcon(type, icon)}
                        <Title>{title}</Title>
                        <Description><div dangerouslySetInnerHTML={{__html: description || ''}} /></Description>
                        <Grid container spacing={2}>
                            <Grid item md={isConfirmation ? 6 : 12}>
                                <ConfirmButton onClick={onClick}>{labelButton}</ConfirmButton>
                            </Grid>
                            <Grid item md={6}>
                                {isConfirmation && <ConfirmButton isConfirmation onClick={onClickConfirm}>{labelButtonConfirm}</ConfirmButton>}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Container>
                : null
            }
        </>
    )
}

export default ModalLoading
import {Http} from "../utils/http";
import Router from "../router";

class DedicationApi {
    private static instance: DedicationApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list(): Promise<any> {
        return this.http.get({
            route: Router.apiDedications,
            hasToken: true
        })
    }
}

export default DedicationApi
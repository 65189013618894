// main libraries
import React from "react";
import {IconButton, List, ListItem, Tooltip} from "@mui/material";
import {LinkProps, NavLink} from "react-router-dom";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AssignmentIndRounded from "@mui/icons-material/AssignmentIndRounded";
import AssignmentIcon from '@mui/icons-material/Assignment';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';

// styles
import {Drawer, DrawerHeader, ItemIcon, ItemText, MenuTitle} from "./styles";
import {useTheme} from "@mui/material/styles";
import "../../assets/css/ListItem.css"

// utils
import Router from "../../../router";
import { Auth } from "../../../utils/auth";

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
    (props: any, ref: any) => {
        return <NavLink ref={ref} to={Router.appEntrepreneurship} {...props}/>
    }
)

interface Props {
    open: boolean
    onClose: () => void
}

interface Route {
    icon?: JSX.Element
    text: string
    to: string
    roles: string[]
}

const routes: Route[] = [
    {
        icon: <DataSaverOffIcon style={{fontSize: 26}}/>,
        text: 'Información general',
        to: Router.appDashboard,
        roles: ['Consultor', 'Admin', 'Institucion']
    },
    {
        icon: <AssignmentIndRounded style={{fontSize: 26}}/>,
        text: 'Perfil del emprendedor',
        to: Router.appProfile,
        roles: ['Emprendedor']
    },
    {
        icon: <StorefrontIcon style={{fontSize: 26}}/>,
        text: 'Emprendimientos',
        to: Router.appEntrepreneurship,
        roles: ['Consultor', 'Admin', 'Emprendedor', 'Institucion']
    },
    {
        icon: <AssignmentIcon style={{fontSize: 26}}/>,
        text: 'Cargar diagnósticos',
        to: Router.appDiagnostics,
        roles: ['Consultor', 'Admin']
    },
]

function Sidebar({open, onClose}: Props) {
    const theme = useTheme()
    const auth = Auth.Instance

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <Tooltip title="Colapsar">
                    <IconButton onClick={onClose}>
                        {theme.direction === 'rtl' ?
                            <ChevronRightIcon fontSize="large" style={{color: '#FFF', fontSize: '2rem'}}/> :
                            <ChevronLeftIcon fontSize="large" style={{color: '#FFF', fontSize: '2rem'}}/>}
                    </IconButton>
                </Tooltip>
            </DrawerHeader>
            {open ? <MenuTitle>Menú de navegación</MenuTitle> : null}
            <List style={{marginTop: open ? 25 : 85}}>
                {routes.map((item, index) => (
                    auth.isRoleAllowed(item.roles) &&
                        <ListItem className={"list-item"} button key={index} component={LinkBehavior} to={item.to}>
                            <Tooltip title={!open ? item.text : ''}>  
                                <ItemIcon>
                                    {item.icon}
                                </ItemIcon>
                            </Tooltip>
                            {open && 
                                <React.Fragment>
                                    {item.text === 'Emprendimientos' ?
                                        <React.Fragment>
                                            {auth.getUser()?.role === 'Emprendedor' ?
                                                <ItemText primary='Mis emprendimientos'/> 
                                            :
                                                <ItemText primary={item.text}/>
                                            }
                                        </React.Fragment>
                                    :
                                        <ItemText primary={item.text}/>
                                    }
                                </React.Fragment>
                            }
                        </ListItem>
                ))}
            </List>
        </Drawer>
    )
}

export default Sidebar
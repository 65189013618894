// main libraries
import React from "react";
import {useSelector} from 'react-redux'
import {Navigate, useLocation} from 'react-router-dom'

// components
import Dashboard from "../../containers/Dashboard"

// reducers
import {selectUser} from '../../../reducers/user/userSlice'

// routes
import Router from '../../../router'

// utils
import {Auth} from '../../../utils/auth'
import Security from "../Security";
import ProfilePage from "../../pages/profile/ProfilePage";

interface Props {
    children: JSX.Element
    roles?: string[]
}

export const PrivateRoute = (props: Props) => {
    const {children, roles} = props
    const location = useLocation()
    const user = useSelector(selectUser)
    const auth = Auth.Instance

    if (user === null && !auth.isAuthenticated())
        return (
            <Navigate
                to={Router.appLogin}
                state={{from: location}}
            />
        )

    if (user?.role === 'Emprendedor' && !user?.profile?.isCompleted && location.pathname !== Router.appProfile) {
        return (
            <Dashboard>
                <Security/>
            </Dashboard>
        )
    }

    if (!auth.isRoleAllowed(roles || [])) {
        return (
            <Dashboard>
                <ProfilePage/>
            </Dashboard>
        )
    }

    return (
        <Dashboard>
            {children}
        </Dashboard>
    )
}

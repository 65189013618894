import {styled} from "@mui/system";
import {Card, Typography} from "@mui/material";
import colors from "../../../utils/colors";

interface ItemProps {
    disabled: boolean
}

export const CardForm = styled(Card)({
    width: '100%',
    minHeight: '530px',
    borderRadius: 10,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 60,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Content = styled('div')({
    padding: '40px 80px',
    overflowY: 'inherit',
    height: '400px',
    display: 'flex'
})

export const Background = styled('div') ({
    backgroundColor: colors.grayColor,
    borderRadius: 20,
    width: '100%',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
})

export const Container = styled('div')<ItemProps>(({disabled}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: disabled ? '#cdced1' : colors.text,
    fontSize: 80,
    border: `3px dashed ${disabled ? '#cdced1' : colors.text}`,
    height: '70%',
    width: '78%',
    borderRadius: 10,
    transition: 'all 0.5s ease-in-out',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '& p:last-child': {
        fontWeight: 600
    },
    '&:hover': {
        backgroundColor: disabled ? '#e5e7ea' : colors.text,
        color: disabled ? '#cdced1' : colors.whiteColor,
    },
    '&:hover & p': {
        color: disabled ? '#cdced1' : colors.whiteColor,
    }
}))

export const Text = styled('p')({
    fontSize: 20,
    textTransform: 'uppercase',
    margin: 0,
})
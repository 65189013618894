// main libraries
import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, Grid} from "@mui/material";
import { FileDownload, FileDownloadOff } from "@mui/icons-material";
import {useDispatch} from "react-redux";

// components
import StatisticsList from "../../containers/StatisticsList";
import CircleGraphic from "../../containers/CircleGraphic";
import BarGraphic from "../../containers/BarGraphic";
import TextField from "../../components/TextField";
import ModalMessage from "../../components/ModalMessage";

// models
import Dashboard from "../../../models/Dashboard";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// utils
import ObjFormat from "../../../utils/objFormat";
import colors from "../../../utils/colors";
import ModalProps from "../../../utils/modalProps";

// api
import DashboardApi from "../../../api/DashboardApi";

// styles
import "../../assets/css/ArrowInfo.css";
import {
    Container,
    Expanded,
    ExpandedContent,
    CardForm,
    CardHeader,
    CardHeaderTitle,
    ExportButton,
    SuccessButton,
    CloseButton,
} from "./styles";

function DashboardPage() {
    const dispatch = useDispatch();

    // init API
    const dashboardApi = DashboardApi.Instance

    // list
    const [dashboard, setDashboard] = React.useState<any>({})
    const [form, setForm] = React.useState<any>({
        from: '',
        to: '',
    })
    const [open, setOpen] = React.useState(false)
    const [generalInformation, setGeneralInformation] = React.useState<any>([])
    const [educationalLevel, setEducationalLevel] = React.useState<any>([])
    const [dataGenre, setDataGenre] = React.useState({
        labels: [] as any,
        datasets: [{
            label: '',
            data: [] as any,
            backgroundColor: [] as any
        }]
    })
    const [dataAge, setDataAge] = React.useState({
        labels: [] as any,
        datasets: [{
            label: '',
            data: [] as any,
            backgroundColor: [] as any
        }]
    })
    const [dataLocation, setDataLocation] = React.useState({
        labels: [] as any,
        datasets: [{
            label: '',
            data: [] as any,
            backgroundColor: [] as any
        }]
    })
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    
    const getDashboard = React.useCallback(() => {
        dispatch(activeLoading())
        dashboardApi.list().then((response) => {
            if (response?.status === 200) {

                let data = ObjFormat.snakeToCamelCase(response.data) as Dashboard
                data.entrepreneurshipCity = Object.values(data.entrepreneurshipCity)
                data.entrepreneurAge = Object.values(data.entrepreneurAge)
                data.entrepreneurGender = Object.values(data.entrepreneurGender)
                data.entrepreneurEducationalLevel = Object.values(data.entrepreneurEducationalLevel)

                setGeneralInformation([
                    {name: 'Emprendedores registrados', value: data.entrepreneurs, icon: 'entrepreneurs'},
                    {name: 'Emprendimientos registrados', value: data.entrepreneurships, icon: 'entrepreneurships'},
                    {name: 'Emprendimientos sin diagnósticos', value: data.entrepreneurshipsWithoutDiagnosis, icon: 'diagnosis'},
                ])

                setEducationalLevel(data.entrepreneurEducationalLevel)

                setDataGenre({
                    labels: data.entrepreneurGender.map(a => a.gender),
                    datasets: [
                        {
                            label: 'Dataset 1',
                            data: data.entrepreneurGender.map(a => a.total),
                            backgroundColor: [colors.main, colors.second, colors.grayColor],
                        }
                    ]
                })

                let rangeValues = [] as any
                rangeValues.push(getSumRange(data.entrepreneurAge, 65, null))
                rangeValues.push(getSumRange(data.entrepreneurAge, 50, 64))
                rangeValues.push(getSumRange(data.entrepreneurAge, 29, 49))
                rangeValues.push(getSumRange(data.entrepreneurAge, 18, 28))
                rangeValues.push(getSumRange(data.entrepreneurAge, 0, 17))

                setDataAge({
                    labels: ['65 +', '50 - 64', '29 - 49', '18 - 28',  '14 - 17'],
                    datasets: [
                        {
                            label: '',
                            data: rangeValues,
                            backgroundColor: [colors.main, colors.text, colors.grayColor],
                        }
                    ]
                })

                setDataLocation({
                    labels: data.entrepreneurshipCity.map(a => a.name),
                    datasets: [
                        {
                            label: 'Dataset 1',
                            data: data.entrepreneurshipCity.map(a => a.total),
                            backgroundColor: [colors.redColor, colors.grayColor, colors.text, colors.yellowColor, colors.orangeColor],
                        }
                    ]
                })

                setDashboard(data)
            } 
            dispatch(deactivateLoading())
        })
    }, [dashboardApi, dispatch])

    const exportFile = React.useCallback((data: any) => {

        if (!data.from || !data.to) {
            setModal({
                open: true,
                title: '',
                message: 'Debe seleccionar una fecha inicial y una fecha final',
                type: 'warning',
                onClick: () => handleClose()
            })
            return
        }

        dispatch(activeLoading())
        dashboardApi.download(data.from, data.to).then(async (response) => {
            if (response?.status === 200) {
                let blob = await response.blob()
                let contentDisposition = response.headers.get('content-disposition')?.split('filename=')
                let filename = contentDisposition[1]

                let file = new File([blob], filename, { type: response.headers.get('content-type') });
                let url = window.URL.createObjectURL(file);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            }
            else if (response?.detail) {
                setModal({
                    open: true,
                    title: 'Error',
                    message: response?.detail || '',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
            dispatch(deactivateLoading())
        })

    }, [dashboardApi, dispatch])

    const getSumRange = (array: any[], min: number, max: number | null): number => {
        let total = 0
        array.map((row: any) => {
            if (max) {
                if (row.age >= min && row.age <= max) total = total + row.total
            }
            else {
                if (row.age >= min) total = total + row.total
            }
        })
        return total
    }

    const handleChange = (event: any) => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const handleOpen = (value: boolean) => {
        setOpen(value)
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    React.useEffect(() => {
        getDashboard()
    }, [])

    return (
        <Container container>
            <Grid item md={4} style={{paddingRight: '20px'}}>
                <Expanded>
                    <ExpandedContent
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <StatisticsList
                            title="Información general"
                            type="generalInfo"
                            list={generalInformation}
                        />
                        <StatisticsList
                            title="Nivel educativo"
                            type="educationalLevel"
                            list={educationalLevel}
                        />
                    </ExpandedContent>
                </Expanded>
            </Grid>
            <Grid item md={8} style={{paddingRight: '20px'}}>
                <CardForm elevation={0}>
                    <CardHeader>
                        <CardHeaderTitle>Dashboard</CardHeaderTitle>
                        <ExportButton endIcon={<FileDownload fontSize='large' />} onClick={() => handleOpen(true)}>
                            GENERAR REPORTE
                        </ExportButton>
                    </CardHeader>
                </CardForm>

                <Dialog open={open} onClose={() => handleOpen(false)} maxWidth='xs' fullWidth={true}> 
                    <DialogContent>
                        <DialogContentText style={{ marginBottom: '1rem' }}>
                            Seleccionar rango para el reporte
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="from"
                                    name="from"
                                    label="Fecha inicial"
                                    type="date"
                                    required
                                    value={form.from}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="to"
                                    name="to"
                                    label="Fecha final"
                                    type="date"
                                    required
                                    value={form.to}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CloseButton onClick={() => handleOpen(false)}>Cerrar</CloseButton>
                        <SuccessButton onClick={() => exportFile(form)}>Exportar</SuccessButton>
                    </DialogActions>
                </Dialog>
                
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <Expanded>
                            <ExpandedContent
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <CircleGraphic
                                    title='Género emprendedores'
                                    data={dataGenre}
                                />
                            </ExpandedContent>
                        </Expanded>
                    </Grid>
                    
                    <Grid item md={6}>
                        <Expanded>
                            <ExpandedContent
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <BarGraphic
                                    title='Edades emprendedores'
                                    data={dataAge}
                                />
                            </ExpandedContent>
                        </Expanded>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Expanded>
                        <ExpandedContent
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ marginBottom: 20 }}
                        >
                            <CircleGraphic
                                title='Ubicación emprendimientos'
                                data={dataLocation}
                            />
                        </ExpandedContent>
                    </Expanded>
                </Grid>
            </Grid>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<FileDownloadOff style={{fontSize: 100, color: '#fff'}}/>}
            />
        </Container>
    )
}

export default DashboardPage
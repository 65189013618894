// main libraries
import {useSearchParams} from "react-router-dom";

// styles
import {Content, EndIcon, Item, Text, TextError} from "./styles";

interface Props {
    firstTitle: string
    secondTitle: string
    result: string
    isError: boolean
    icon: JSX.Element
    params?: any
}

function ResultCard(props: Props) {
    const {firstTitle, secondTitle, result, isError, icon, params} = props
    let [searchParams, setSearchParams] = useSearchParams();

    const isSelected = (): boolean => {
        return searchParams.get('item') === params?.item?.toString();
    }

    return (
        <Item 
            onClick={() => !isError ? setSearchParams(params) : null}
            selected={isSelected()}
            disabled={isError}
        >
            <Content>
                <Text>{firstTitle}</Text>
                <Text second="true">{secondTitle}</Text>
                <TextError iserror={isError.toString()}>{result}</TextError>
            </Content>
            <EndIcon iserror={isError.toString()}>
                {icon}
            </EndIcon>
        </Item>
    )
}

export default ResultCard
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const Item = styled('div')({
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    backgroundColor: colors.main,
    borderRadius: 8,
    marginBottom: 10,
    '&:hover': {
        backgroundColor: colors.main,
        transition: 'all 0.3s ease-in 0s'
    }
})

export const Content = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: colors.whiteColor,
    '& p:last-child': {
        fontWeight: 500,
        textTransform: 'capitalize',
    },
})

export const Text = styled('p')({
    margin: 0,
    fontSize: 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

export const Title = styled('p')({
    width: '100%',
    margin: '0 0 3px 0',
    fontSize: 14,
    color: colors.whiteColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    fontWeight: 600,
})

export const IconContainer = styled('div')({
    backgroundColor: colors.notification,
    padding: 4,
    alignItems: 'center',
    display: 'flex',
    borderRadius: 6
})

export const Icon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 25,
    color: 'rgba(255, 255, 255, 0.8)',
    marginRight: 15
})

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 30,
    padding: 1,
    color: colors.whiteColor,
    backgroundColor: '#559ab5',
    borderRadius: '8px 0 0 8px'
})

export const Container = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 16px'
})

export const Row = styled('div')({
    display: 'flex'
})
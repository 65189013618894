// main libraries
import React from "react";

// components
import Layout from "../../containers/Layout";
import SignUpForm from "../../containers/SignUpForm";

// styles & assets
import CustomCard from "../../components/CustomCard";

function SignUpPage() {
    return (
        <Layout>
            <CustomCard title="Registro de usuario" width={580} height={800}>
                <SignUpForm/>
            </CustomCard>
        </Layout>
    )
}

export default SignUpPage
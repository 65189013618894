import React from "react";

// styles & assets
import logo from "../../assets/images/red-i-logo.png";
import {Container, ContainerCard, Content, Image, Title} from "./styles";

interface Props {
    children: React.ReactNode,
    title: string
    width?: number
    height?: number
}

function CustomCard({children, title, width, height}: Props) {
    return (
        <Container>
            <ContainerCard elevation={1} width={width} height={height}>
                <Content>
                    <Image src={logo} alt="logo"/>
                    <Title>{title}</Title>
                    {children}
                </Content>
            </ContainerCard>
        </Container>
    )
}

export default CustomCard
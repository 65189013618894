import {styled} from "@mui/system";
import {Button, Card, Typography} from "@mui/material";
import colors from "../../../utils/colors";

export const CardForm = styled(Card)({
    width: '100%',
    height: '94%',
    borderRadius: 10,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Content = styled('div')({
    padding: '30px 50px',
    overflowY: 'inherit'
})

export const SaveButton = styled(Button)({
    width: '100%',
    height: 50,
    margin: '15px 0 5px 0',
    backgroundColor: colors.dark,
    color: colors.whiteColor,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    textTransform: 'uppercase',
    '&:hover': {
        backgroundColor: colors.text,
    },
    '&:disabled': {
        backgroundColor: 'rgb(16 56 91 / 76%)',
        color: 'rgba(255, 255, 255, 0.26)'
    }
})

export const SectionDrop = styled('section')({
    flex: 1,
    display: 'flex',
    fontSize: 12,
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#bdbdbd',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
})

export const Label = styled('p')({
    color: 'inherit',
    fontFamily: 'inherit',
    fontSize: 12
})
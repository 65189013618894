import {styled} from "@mui/system";
import colors from "../../../utils/colors";

const getColors = (selected?: boolean): string[] => {
    if (selected) return [colors.grayColor, colors.grayColor]

    return [colors.whiteColor, colors.grayColor]
}

const getIconColor = (color?: string): string => {
    if (color === 'service') return colors.dark
    else if (color === 'entrepreneurship') return colors.notification

    return colors.dark
}

interface ItemProps {
    selected?: boolean
    color?: string
    isbutton?: boolean
    small?: boolean
}

export const Item = styled('div')<ItemProps>(({selected}) => ({
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    height: 50,
    backgroundColor: getColors(selected)[0],
    borderRadius: 8,
    marginBottom: 10,
    lineHeight: 1.3,
    '&:hover': {
        backgroundColor: getColors(selected)[1],
        transition: 'all 0.3s ease-in 0s'
    }
}))

export const Text = styled('p')<ItemProps>(({small}) => ({
    width: '100%',
    margin: 0,
    fontSize: small ? 10 : 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& p:first-letter': {
        textTransform: 'capitalize',
    },
}))

export const Content = styled('div')<ItemProps>(({isbutton}) => ({
    maxWidth: '66%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    '& p:first-letter': {
        textTransform: 'capitalize',
    },
    '& p:last-child': {
        fontWeight: !isbutton ? 600 : 500,
        color: !isbutton ? colors.dark : colors.text,
        textTransform: 'capitalize',
    },
}))

export const IconContainer = styled('div')<ItemProps>(({color}) => ({
    backgroundColor: getIconColor(color),
    padding: 3,
    alignItems: 'center',
    display: 'flex',
    borderRadius: 5
}))

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 28,
    padding: '10px 15px',
    color: 'rgba(255, 255, 255, 0.8)'
})

export const EndIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 50,
    padding: 1,
    color: colors.text
})
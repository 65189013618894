// components
import SpinnerLoader from "../SpinnerLoader";

//styles
import {Container, Card, ContainerSpinner, TextSpinner} from './styles';

interface Props {
    title: string
}

function ModalLoading(props: Props) {
    const {title} = props

    return (
        <Container>
            <Card>
                <ContainerSpinner>
                    <SpinnerLoader/>
                </ContainerSpinner>
                <TextSpinner>{title}</TextSpinner>
            </Card>
        </Container>
    )
}

export default ModalLoading
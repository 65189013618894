// main libraries
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

// utils
import Router from '../router';

// pages
import LoginPage from './pages/login/LoginPage';
import SignUpPage from "./pages/signUp/SignUpPage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import ProfilePage from "./pages/profile/ProfilePage";
import EntrepreneurshipPage from "./pages/entrepreneurship/EntrepreneurshipPage";
import DiagnosticPage from "./pages/diagnostic/DiagnosticPage";
import DashboardPage from './pages/dashboard/DashboardPage';
import ChangePasswordPage from './pages/changePassword/ChangePasswordPage';

// components
import {PrivateRoute} from "./components/PrivateRoute";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LoginPage/>}/>
                <Route path={Router.appLogin} element={<LoginPage/>}/>
                <Route path={Router.appSignUp} element={<SignUpPage/>}/>
                <Route path={Router.appResetPassword} element={<ResetPasswordPage/>}/>
                <Route path={Router.appChangePasswordToken} element={<ChangePasswordPage/>}/>
                <Route path={Router.appChangePassword} element={<ChangePasswordPage/>}/>
                <Route
                    path={Router.appDashboard}
                    element={
                        <PrivateRoute roles={['Consultor', 'Admin', 'Institucion']}>
                            <DashboardPage/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Router.appProfile}
                    element={
                        <PrivateRoute roles={['Emprendedor']}>
                            <ProfilePage/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Router.appEntrepreneurship}
                    element={
                        <PrivateRoute roles={['Consultor', 'Admin', 'Emprendedor', 'Institucion']}>
                            <EntrepreneurshipPage/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Router.appDiagnostics}
                    element={
                        <PrivateRoute roles={['Consultor', 'Admin']}>
                            <DiagnosticPage/>
                        </PrivateRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App;

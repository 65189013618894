// components
import ListItem from "../../components/ListItem";

// icons
import StorefrontIcon from "@mui/icons-material/Storefront";
import InfoIcon from "@mui/icons-material/Info";
import BallotIcon from "@mui/icons-material/Ballot";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// styles
import {
    AvatarTitle,
    CardContainer,
    CustomDivider,
    EmptyContainer,
    EmptyIcon,
    TextEmpty
} from "./styles";
import CustomListItem from "../../components/CustomListItem";
import ResultCard from "../../components/ResultCard";
import DiagnosticListItem from "../../components/DiagnosticListItem";
import {useSearchParams} from "react-router-dom";
import UploadFile from "../../components/UploadFile";
import React, { Fragment } from "react";

interface Item {
    code: string
}

interface Props {
    title: string
    firstItemText: string
    secondItemText: string
    result: any
    list: any
    type: string
    hide: boolean
    cardHeight: boolean
    onChange: (value: any) => void
}

function DiagnosticsList(props: Props) {
    const {title, firstItemText, secondItemText, result, list, type, hide, cardHeight, onChange} = props
    let [searchParams, ] = useSearchParams();

    const getIcon = () => {
        return <AssignmentIcon fontSize="inherit" style={{ color: '#FFF' }}/>
    }

    const getDetailIcon = (value: boolean) => {
        if (value) return <CancelIcon fontSize="inherit"/>
        else return <CheckCircleIcon fontSize="inherit"/> 
    }

    const isDetail = () => {
        return searchParams.get('type') === 'detail'
    }

    return (
        <CardContainer cardHeight={cardHeight}>
            <AvatarTitle>{title}</AvatarTitle>
            <UploadFile
                icon={getIcon()}
                firstTitle={firstItemText}
                secondTitle={secondItemText}
                hide={hide}
                onChange={onChange}
            />
            
            <CustomDivider/>

            {isDetail() ?
                <React.Fragment>
                    {list &&
                        list.map((item: any, index: number) => (
                            <Fragment key={index}>
                                <DiagnosticListItem
                                    firstTitle={item.diagnosticDate}
                                    secondTitle={item.phase}
                                    icon={getIcon()}
                                    params={item.params}
                                />
                            </Fragment>
                        ))
                    }
                </React.Fragment>
            :
                <React.Fragment>
                    {result.detail.length > 0 &&
                        result.detail.map((item: any, index: number) => (
                            <Fragment key={index}>
                                <ResultCard
                                    firstTitle={item.entrepreneurship}
                                    secondTitle={item.diagnosticDate}
                                    result={item.result}
                                    isError={item.error}
                                    icon={getDetailIcon(item.error)}
                                    params={item.params}
                                />
                            </Fragment>
                        ))
                    }
                </React.Fragment>
            }
        </CardContainer>
    )
}

export default DiagnosticsList
import {styled} from "@mui/material/styles";
import colors from "../../../utils/colors";
import {Box} from "@mui/material";

const drawerWidth = 250;

export const DrawerHeader = styled('div')(({theme}) => ({
    color: colors.whiteColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    height: 80
}));

interface ContainerProps {
    isOpen: boolean
}

export const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})<ContainerProps>(({isOpen}) => (({theme}) => ({
    minWidth: `calc(100vw - ${isOpen ? drawerWidth : 73}px)`,
    minHeight: '100vh',
    backgroundColor: colors.grayColor,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
})))
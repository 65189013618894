// main libraries
import React, {Fragment} from "react";
import {Typography} from "@mui/material";
import { useDispatch } from "react-redux";

// components
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineConnector, TimelineContent } from "@mui/lab";
import InfiniteScroll from 'react-infinite-scroll-component';

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// models

// api
import TimelineApi from "../../../api/TimelineApi";

// icons
import { Assignment, Ballot, HomeRepairService, MyLocation, Storefront } from "@mui/icons-material";

// utils

// styles
import {Container, TimelineDotStyle, Row, Text, TextTypo, TextTitle} from "./styles";
import moment from "moment";
import { CustomDate } from "../../../utils/date";

interface Props {
    entrepreneurshipId: number
}

function EntrepreneurshipTimeline(props: Props) {
    const {entrepreneurshipId} = props
    const dispatch = useDispatch();

    const [data, setData] = React.useState({
        results: Array<any>()
    })
    const [page, setPage] = React.useState(1)

    // init API
    const timelineApi = TimelineApi.Instance 

    const searchTimeline = React.useCallback((code: number, current: number) => {
        if (current) {
            dispatch(activeLoading())
            
            timelineApi.findByEntrepreneurship(code.toString(), current).then((response) => {
                if (response?.status === 200) {

                    // Check what is the next page
                    let next = response.data.next
                    if (next) {
                        let nextPage = next.split('page=')
                        setPage(parseInt(nextPage[1]))
                    }
                    else {
                        setPage(0)
                    }
    
                    let res = [] as any
                    res = response.data?.results
                    res.map((row: any) => {
                        row.date = getDate(row.created)
                        row.type = 'register'
                        if (row.diagnostic) {
                            row.type = 'diagnostic'
                            row.data = [
                                {title: 'Etapa: ', value: row.diagnostic.phase?.name},
                                {title: 'Consultor: ', value: row.diagnostic.mise_consultant},
                                {title: '', value: row.diagnostic.mise_result, type: 'percentage'},
                            ]
                        }
                        else if (row.entrepreneurship) {
                            row.type = 'register'
                            row.data = [
                                {title: 'Nombre: ', value: row.entrepreneurship.name},
                                {title: 'Código: ', value: row.entrepreneurship.code},
                            ]
                        }
                        else if (row.service) {
                            row.type = 'service'
                            row.data = [
                                {title: 'Etapa: ', value: row.service.axis?.name},
                                {title: 'Institución: ', value: row.service.institution?.name},
                                {title: 'Temas: ', value: row.service.topic},
                                {title: 'Horas entrenamiento: ', value: row.service.hours_training},
                                {title: 'Horas asesoría: ', value: row.service.hours_consulting},
                                {title: 'Fecha inicio programa: ', value: row.service.date_start},
                                {title: 'Fecha terminación programa: ', value: row.service.date_end},
                            ]
                        }
                        else if (row.tracking) {
                            row.type = 'tracking'
                            row.data = [
                                {title: 'Descripción: ', value: row.tracking.description},
                            ]
                        }
                        else if (row.characterization) {
                            row.type = 'characterization'
                            row.data = [
                                {title: 'Clasificación: ', value: row.characterization.classification?.name},
                                {title: 'Tiempo en el mercado: ', value: row.characterization.market_time?.name},
                            ]
                        }

                    })

                    // Concat data when scroll
                    let newArray = data.results
                    newArray.push(...res)
                    setData({results: newArray})
                }
                dispatch(deactivateLoading())
            })
        }
    }, [timelineApi, dispatch])

    const getDate = (date: any) => {
        const d = new Date(date);
        let monthNumber = moment(d).format('MM')
        let monthName = CustomDate.getMonthName(parseInt(monthNumber))
        let newDate = moment(d).format('DD, YYYY')
        return `${monthName} ${newDate}`; 
    }

    const getIcon = (type: string) => {
        if (type === 'register') {
            return <Storefront fontSize="medium"/>
        }
        else if (type === 'service') {
            return <HomeRepairService fontSize="medium"/>
        }
        else if (type === 'tracking') {
            return <MyLocation fontSize="medium"/>
        }
        else if (type === 'diagnostic') {
            return <Assignment fontSize="medium"/>
        }
        else if (type === 'characterization') {
            return <Ballot fontSize="medium"/>
        }

        return <Ballot fontSize="medium"/>
    }

    React.useEffect(() => {
        setData({results: []})
        data.results.length = 0
        if (entrepreneurshipId) searchTimeline(entrepreneurshipId, 1)
    }, [entrepreneurshipId])

    return (
        <InfiniteScroll
            dataLength={data.results.length}
            next={() => searchTimeline(entrepreneurshipId, page)}
            hasMore={true}
            loader={<h4></h4>}
        >
            <Timeline position="alternate" style={{ marginTop: 0, paddingTop: 0 }}>
                {data.results?.map((row: any, index: number) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            <TextTypo>{row.date}</TextTypo>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDotStyle type={row.type}>
                                {getIcon(row.type)}
                            </TimelineDotStyle>
                            <TimelineConnector sx={{ width: 3, background: '#6789CE', height: '2rem' }}/>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <TextTitle>{row.name}</TextTitle>
                            {row.data &&
                                row.data.map((detail: any, indexDetail: number) => (
                                    <Fragment key={'detail-' + indexDetail}>
                                        <Row>
                                            <Text type="title">{detail.title}</Text>
                                            {detail.type === 'percentage' ?
                                                <Text type='percentage'>{detail.value}%</Text>
                                            :
                                                <Text type='text'>{detail.value}</Text>
                                            }
                                        </Row>
                                    </Fragment>
                                ))
                            }
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </InfiniteScroll>
    )
}

export default EntrepreneurshipTimeline
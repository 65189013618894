// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// styles
import {Content, EndIcon, Item, StartIcon, Text} from "./styles";
import React from 'react';

interface Props {
    icon: JSX.Element
    firstTitle: string
    secondTitle: string
    hide: boolean
    onChange: (value: any) => void
}

function UploadFile(props: Props) {
    const {icon, firstTitle, secondTitle, hide, onChange} = props

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  
    const handleClick = () => {
        if (hiddenFileInput.current) hiddenFileInput.current!.click();
    };

    return (    
        <>     
            <Item onClick={handleClick} hide={hide?.toString()}>
                <input 
                    type="file" 
                    style={{display:'none'}} 
                    accept="application/JSON"
                    onChange={onChange} 
                    ref={hiddenFileInput}
                />
                <StartIcon>
                    {icon}
                </StartIcon>
                <Content>
                    <Text>{firstTitle}</Text>
                    <Text>{secondTitle}</Text>
                </Content>
                <EndIcon>
                    <ChevronRightIcon fontSize="inherit"/>
                </EndIcon>
            </Item>
        </>
    )
}

export default UploadFile
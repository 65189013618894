import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import counterReducer from '../reducers/counter/counterSlice';
import loadingReducer from '../reducers/loading/loadingSlice';
import userReducer from '../reducers/user/userSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        loading: loadingReducer,
        user: userReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;

import React from "react";
import {FormControl, FormHelperText} from "@mui/material";
import {CustomCheckbox, Required, CustomControlLabel, Anchor} from "./styles";

interface LabelProps {
    label?: string
    isRequired?: boolean
    download?: boolean
}

interface Props {
    label?: string
    required?: boolean
    disabled?: boolean
    error?: boolean
    helperText?: string
    name: string
    checked: boolean
    download?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Label = (props: LabelProps) => {
    const {label, isRequired, download} = props

    function downloadFile() {
        let anchor = window.document.createElement('a');
        anchor.href = './terms-conditions.pdf';
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
    }

    return (
        <span style={{ fontSize: 11, zIndex: 100 }}>
            {download 
                ?  
                    <React.Fragment>
                        Aceptar
                        <Anchor onClick={downloadFile}> términos y condiciones</Anchor>
                        <React.Fragment>
                            {isRequired ? <Required>*</Required> : null}
                        </React.Fragment>
                    </React.Fragment>
                :   <React.Fragment>
                        {label ?? ''}
                        <React.Fragment>
                            {isRequired ? <Required>*</Required> : null}
                        </React.Fragment>
                    </React.Fragment>
            }
        </span>
    )
}

function CheckboxField(props: Props) {
    const {label, required, name, checked, onChange, disabled, error, helperText, download} = props

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event);
    }

    return (
        <FormControl
            error={error}
            component="fieldset"
            variant="standard"
            style={{paddingTop: 25}}
        >
            <CustomControlLabel
                label={<Label label={label} isRequired={required} download={download}/>}
                control={
                    <CustomCheckbox name={name} checked={checked} onChange={handleChange} disabled={disabled}/>
                }
                disabled={disabled}
            />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    )
}

export default CheckboxField;
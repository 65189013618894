import {alpha, styled} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import colors from "../../../utils/colors";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";

export const CustomFormControl = styled(FormControl)({
    width: '100%',
    '& div, & div input': {
        width: '100%',
        marginTop: '0 !important'
    },
    '& .Mui-error input': {
        border: '1px solid red'
    },
    '& .Mui-error input:focus': {
        boxShadow: `${alpha(colors.redColor, 0.25)} 0 0 0 0.2rem`,
        borderColor: colors.redColor,
    },
})

export const CustomInputLabel = styled(InputLabel)({
    fontSize: 14,
    fontWeight: 600,
    position: 'inherit',
    whiteSpace: 'break-spaces',
    minWidth: 400,
    '&.Mui-focused': {
        color: colors.text
    }
})

export const BootstrapInput = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(colors.text, 0.25)} 0 0 0 0.2rem`,
            borderColor: colors.text,
        },
        '&:disabled': {
            backgroundColor: colors.disabled,
            cursor: 'not-allowed',
            '-webkit-text-fill-color': colors.disabledColor, 
        }
    },
    '& .MuiInputBase-inputMultiline': {
        minHeight: '70px !important',
        maxHeight: '80px !important'
    },
}))
// main libraries
import React from "react";
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import Section from "../../components/Section";
import TextField from "../../components/TextField";

// models
import Tracking from "../../../models/Tracking";

// api

// icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// utils

// styles
import {CardForm, CardHeader, CardHeaderTitle, Content, Label, IconContainer, Text} from "./styles";
import { CustomInputLabel } from "../../components/TextField/styles";

interface Props {
    data: Tracking
}

function TrackingShow(props: Props) {
    const {data} = props
    const dispatch = useDispatch()

    const [form, setForm] = React.useState({
        description: '',
        file: [] as any,
    })
    const [url, setUrl] = React.useState('https://reddeemprendimiento-imas.org')

    // init API

    const initForm = React.useCallback(() => {
        dispatch(activeLoading())
        setTimeout(function () {
            data.file.map((obj: any) => {
                obj.download = url + obj.file
                obj.name = obj.file
                let split = obj.file.split('/')
                if (split[4]) obj.name = split[4]
            })
            setForm({...form,
                description: data.description,
                file: data.file
            })
            dispatch(deactivateLoading())
        }, 1000)
    }, [data, dispatch])

    React.useEffect(() => {
        initForm()
    }, [dispatch, initForm])

    const handleChange = (event: any) => {}

    const downloadFile = (file: any) => {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.download = file.name;
        a.target = '_blank';
        a.href = file.download;
        a.click();
    }

    return (
        <>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>Detalle seguimiento</CardHeaderTitle>
                </CardHeader>
                <Content>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Section name="Seguimiento"/>
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="description"
                                name="description"
                                value={form.description}
                                onChange={handleChange}
                                label="Observaciones"
                                multiline
                                maxRows={4}
                                disabled
                            />
                        </Grid>
                        <Grid item md={12}>
                            <CustomInputLabel shrink>Archivos</CustomInputLabel>
                            {form.file.map((row: any, index: number) => (
                                <IconContainer key={index}>
                                    <FileDownloadIcon style={{ color: "#6789CE", cursor: 'pointer' }} fontSize="medium" onClick={() => downloadFile(row)}/>
                                    <Text>{row.name}</Text>
                                </IconContainer>
                            ))}
                        </Grid>
                    </Grid>
                </Content>
            </CardForm>
        </>
    )
}

export default TrackingShow
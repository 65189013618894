// main libraries
import React from "react";
import {Grid, SelectChangeEvent} from "@mui/material";
import {useDispatch} from "react-redux";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import Section from "../../components/Section";
import TextField from "../../components/TextField";
import CustomSelect from "../../components/CustomSelect";
import ModalMessage from "../../components/ModalMessage";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";

// models
import Classification from "../../../models/Classification";
import Status from "../../../models/Status";
import Motivation from "../../../models/Motivation";
import EconomicActivity from "../../../models/EconomicActivity";
import Financing from "../../../models/Financing";
import MarketTime from "../../../models/MarketTime";
import WorkTeam from "../../../models/WorkTeam";
import Dedication from "../../../models/Dedication";
import Topics from "../../../models/Topics";
import EconomicRecords from "../../../models/EconomicRecords";
import Channel from "../../../models/Channel";
import Average from "../../../models/Average";
import MarketType from "../../../models/MarketType";
import IncomeMethod from "../../../models/IncomeMethod";
import Characterization from "../../../models/Characterization";

// api
import EconomicActivityApi from "../../../api/EconomicActivityApi";
import FinancingApi from "../../../api/FinancesApi";
import MarketTimeApi from "../../../api/MarketTimeApi";
import WorkTeamApi from "../../../api/WorkTeamApi";
import DedicationApi from "../../../api/DedicationApi";
import TopicApi from "../../../api/TopicApi";
import ChannelApi from "../../../api/ChannelApi";
import EconomicRecordsApi from "../../../api/EconomicRecordsApi";
import AverageApi from "../../../api/AverageApi";
import MarketTypeApi from "../../../api/MarketTypeApi";
import IncomeMethodApi from "../../../api/IncomeMethodApi";
import ClassificationApi from "../../../api/ClassificationApi";
import StatusApi from "../../../api/StatusApi";
import MotivationApi from "../../../api/MotivationApi";
import CharacterizationApi from "../../../api/CharacterizationApi";
import PortfolioApi from "../../../api/PortfolioApi";
import PersonApi from "../../../api/PersonApi";

// icons
import BallotIcon from "@mui/icons-material/Ballot";

// utils
import ObjFormat from "../../../utils/objFormat";
import ModalProps from "../../../utils/modalProps";

// styles
import {CardForm, CardHeader, CardHeaderTitle, Content, SaveButton} from "./styles";

interface Role {
    create: string[]
    edit: string[]
    delete: string[]
}

interface Props {
    refresh: () => void
    entrepreneurshipId: number
    isAllowed: (value: any) => boolean 
    roles: Role
}

function CharacterizationForm(props: Props) {
    const {refresh, entrepreneurshipId, isAllowed, roles} = props
    const dispatch = useDispatch()
    const [form, setForm] = React.useState({
        classification: '',
        status: '',
        motivation: '',
        economicActivity: '',
        financing: '',
        marketTime: '',
        workTeam: '',
        jobsGenerated: 0,
        dedication: '',
        topics: '',
        topicsList: [] as any,
        economicRecords: '',
        channel: '',
        average: '',
        marketType: '',
        marketTypeList: [] as any,
        incomeMethod: '',
        incomeMethodList: [] as any,
        hasRegistry: false,
        person: '' as any,
        hasPatent: false,
        hasTrademark: false,
        immediateNeed: '',
        vision: '',
        visionNeed: '',
        hasBenefited: false,
        entityBenefited: '',
        solvesProblem: '',
        description: '',
        portfolio: '',
        hasGuild: false,
        guild: ''
    })
    const [error, setError] = React.useState({
        classification: '',
        status: '',
        motivation: '',
        economicActivity: '',
        financing: '',
        marketTime: '',
        workTeam: '',
        jobsGenerated: '',
        dedication: '',
        topics: '',
        topicsList: '',
        economicRecords: '',
        channel: '',
        average: '',
        marketType: '',
        marketTypeList: '',
        incomeMethod: '',
        incomeMethodList: '',
        hasRegistry: '',
        person: '',
        hasPatent: '',
        hasTrademark: '',
        immediateNeed: '',
        vision: '',
        visionNeed: '',
        hasBenefited: '',
        entityBenefited: '',
        solvesProblem: '',
        description: '',
        portfolio: '',
        hasGuild: '',
        guild: ''
    })
    const [answerList, ] = React.useState<any[]>([
        {value: true, name: 'Sí'},
        {value: false, name: 'No'},
    ])
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    const [disabled, setDisabled] = React.useState<boolean>(false)

    // init API
    const classificationApi = ClassificationApi.Instance
    const statusApi = StatusApi.Instance
    const motivationApi = MotivationApi.Instance
    const economicActivityApi = EconomicActivityApi.Instance
    const financingApi = FinancingApi.Instance
    const marketTimeApi = MarketTimeApi.Instance
    const workTeamApi = WorkTeamApi.Instance
    const dedicationApi = DedicationApi.Instance
    const topicApi = TopicApi.Instance
    const economicRecordsApi = EconomicRecordsApi.Instance
    const channelApi = ChannelApi.Instance
    const averageApi = AverageApi.Instance
    const marketTypeApi = MarketTypeApi.Instance
    const incomeMethodApi = IncomeMethodApi.Instance
    const personApi = PersonApi.Instance
    const portfolioApi = PortfolioApi.Instance
    const characterizationApi = CharacterizationApi.Instance

    // list of select fields
    const [classifications, setClassifications] = React.useState<Classification[]>([])
    const [status, setStatus] = React.useState<Status[]>([])
    const [motivations, setMotivations] = React.useState<Motivation[]>([])
    const [economicActivities, setEconomicActivities] = React.useState<EconomicActivity[]>([])
    const [finances, setFinances] = React.useState<Financing[]>([])
    const [marketTimes, setMarketTimes] = React.useState<MarketTime[]>([])
    const [workTeams, setWorkTeams] = React.useState<WorkTeam[]>([])
    const [dedications, setDedications] = React.useState<Dedication[]>([])
    const [topics, setTopics] = React.useState<Topics[]>([])
    const [economicRecords, setEconomicRecords] = React.useState<EconomicRecords[]>([])
    const [channels, setChannels] = React.useState<Channel[]>([])
    const [averages, setAverages] = React.useState<Average[]>([])
    const [marketTypes, setMarketTypes] = React.useState<MarketType[]>([])
    const [incomeMethods, setIncomeMethods] = React.useState<IncomeMethod[]>([])
    const [persons, setPersons] = React.useState<string[]>([])
    const [portfolios, setPortfolios] = React.useState<string[]>([])

    const handleChange = (event: any) => {
        let array = ['hasRegistry', 'hasBenefited', 'hasGuild'] 
        if (array.includes(event.target.name) && !event.target.value) {
            handleClear(event.target.name)
            return
        }
        
        if (event.target.type === 'checkbox') {
            setForm({...form, [event.target.name]: event.target.checked})
        } else {
            setForm({...form, [event.target.name]: event.target.value})
        }
    }

    const handleClear = (name: any) => {
        let data = {...form, [name]: false}
        if (name === 'hasRegistry') {
            data = {...data, person: ''}
        }
        else if (name === 'hasBenefited') {
            data = {...data, entityBenefited: ''}
        }
        else if (name === 'hasGuild') {
            data = {...data, guild: ''}
        }
        setForm({...form, ...data})
    }

    const handleChangeSelect = (event: SelectChangeEvent<any>) => {
        const { target: { value, name } } = event;
        setForm({...form, [name]: value})
    };

    const init = React.useCallback(async () => {
        dispatch(activeLoading())
        await classificationApi.list().then((response) => {
            if (response?.status === 200) {
                setClassifications(response.data)
            }
        })
        await statusApi.list().then((response) => {
            if (response?.status === 200) {
                setStatus(response.data)
            }
        })
        await motivationApi.list().then((response) => {
            if (response?.status === 200) {
                setMotivations(response.data)
            }
        })
        await economicActivityApi.list().then((response) => {
            if (response?.status === 200) {
                setEconomicActivities(response.data)
            }
        })
        await financingApi.list().then((response) => {
            if (response?.status === 200) {
                setFinances(response.data)
            }
        })
        await marketTimeApi.list().then((response) => {
            if (response?.status === 200) {
                setMarketTimes(response.data)
            }
        })
        await workTeamApi.list().then((response) => {
            if (response?.status === 200) {
                setWorkTeams(response.data)
            }
        })
        await dedicationApi.list().then((response) => {
            if (response?.status === 200) {
                setDedications(response.data)
            }
        })
        await topicApi.list().then((response) => {
            if (response?.status === 200) {
                setTopics(response.data)
            }
        })
        await economicRecordsApi.list().then((response) => {
            if (response?.status === 200) {
                setEconomicRecords(response.data)
            }
        })
        await channelApi.list().then((response) => {
            if (response?.status === 200) {
                setChannels(response.data)
            }
        })
        await averageApi.list().then((response) => {
            if (response?.status === 200) {
                setAverages(response.data)
            }
        })
        await marketTypeApi.list().then((response) => {
            if (response?.status === 200) {
                setMarketTypes(response.data)
            }
        })
        await incomeMethodApi.list().then((response) => {
            if (response?.status === 200) {
                setIncomeMethods(response.data)
            }
        })
        await personApi.list().then((response) => {
            if (response?.status === 200) {
                setPersons(response.data)
            }
        })
        await portfolioApi.list().then((response) => {
            if (response?.status === 200) {
                setPortfolios(response.data)
            }
        })
    }, [averageApi, channelApi, classificationApi, statusApi, dedicationApi, dispatch, economicActivityApi, economicRecordsApi, financingApi, incomeMethodApi, marketTimeApi, marketTypeApi, motivationApi, personApi, portfolioApi, topicApi, workTeamApi])

    React.useEffect(() => {
        init().then(() => {
            setDisabled(!isAllowed(roles.create))
            dispatch(deactivateLoading())
        })
    }, [init, dispatch])

    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            classification: '',
            status: '',
            motivation: '',
            economicActivity: '',
            financing: '',
            marketTime: '',
            workTeam: '',
            jobsGenerated: '',
            dedication: '',
            topics: '',
            topicsList: '',
            economicRecords: '',
            channel: '',
            average: '',
            marketType: '',
            marketTypeList: '',
            incomeMethod: '',
            incomeMethodList: '',
            hasRegistry: '',
            person: '',
            hasPatent: '',
            hasTrademark: '',
            immediateNeed: '',
            vision: '',
            visionNeed: '',
            hasBenefited: '',
            entityBenefited: '',
            solvesProblem: '',
            description: '',
            portfolio: '',
            hasGuild: '',
            guild: ''
        }
        setError({...errorsAux, ...errors})
    }

    const clearForm = () => {
        setForm({
            classification: '',
            status: '',
            motivation: '',
            economicActivity: '',
            financing: '',
            marketTime: '',
            workTeam: '',
            jobsGenerated: 0,
            dedication: '',
            topics: '',
            topicsList: [] as any,
            economicRecords: '',
            channel: '',
            average: '',
            marketType: '',
            marketTypeList: [] as any,
            incomeMethod: '',
            incomeMethodList: [] as any,
            hasRegistry: false,
            person: '',
            hasPatent: false,
            hasTrademark: false,
            immediateNeed: '',
            vision: '',
            visionNeed: '',
            hasBenefited: false,
            entityBenefited: '',
            solvesProblem: '',
            description: '',
            portfolio: '',
            hasGuild: false,
            guild: ''
        })
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    const refreshData = () => {
        setModal({...modal, open: false})
        refresh()
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        if (!form.person) form.person = null
        const data = ObjFormat.camelToSnakeCase(form) as Characterization

        characterizationApi.create(entrepreneurshipId, data).then((response) => {
            if (response?.status === 201) {
                clearForm()
                setModal({
                    open: true,
                    title: 'Información registrada correctamente',
                    message: 'La información ampliada del emprendimiento se ha registrado satisfactoriamente',
                    type: 'custom',
                    onClick: () => refreshData()
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data)
                cleanErrors(resp)
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Hay errores en el formulario',
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado, por favor intentelo en unos minutos',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    return (
        <>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>Información ampliada del Emprendimiento</CardHeaderTitle>
                </CardHeader>
                <Content>
                    <form onSubmit={handleSubmit}>
                        <Grid 
                            spacing={2}
                            container  
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Grid item md={12}>
                                <Section name="Información"/>
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="classification"
                                    value={form.classification}
                                    label="¿En cuál de los siguientes grupos usted podría clasificar su emprendimiento?"
                                    required
                                    error={error.classification !== ''}
                                    helperText={error.classification[0] ?? ''}
                                    onChange={handleChange}
                                    options={classifications.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="status"
                                    value={form.status}
                                    label="¿En qué estado se encuentra su emprendimiento actualmente?"
                                    required
                                    disabled={disabled}
                                    error={error.status !== ''}
                                    helperText={error.status[0] ?? ''}
                                    onChange={handleChange}
                                    options={status.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="motivation"
                                    value={form.motivation}
                                    label="¿Cuál es la principal razón que te motiva a emprender?"
                                    required
                                    error={error.motivation !== ''}
                                    helperText={error.motivation[0] ?? ''}
                                    onChange={handleChange}
                                    options={motivations.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="economicActivity"
                                    value={form.economicActivity}
                                    label="Seleccione el sector en el cual desarrolla la actividad económica su Emprendimiento"
                                    required
                                    error={error.economicActivity !== ''}
                                    helperText={error.economicActivity[0] ?? ''}
                                    onChange={handleChange}
                                    options={economicActivities.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="financing"
                                    value={form.financing}
                                    label="¿Por medio de que fuentes de financiación inició su emprendimiento?"
                                    required
                                    error={error.financing !== ''}
                                    helperText={error.financing[0] ?? ''}
                                    onChange={handleChange}
                                    options={finances.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="marketTime"
                                    value={form.marketTime}
                                    label="¿Cuánto tiempo lleva su emprendimiento en el mercado?"
                                    required
                                    error={error.marketTime !== ''}
                                    helperText={error.marketTime[0] ?? ''}
                                    onChange={handleChange}
                                    options={marketTimes.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="workTeam"
                                    value={form.workTeam}
                                    label="¿Cuántas personas conforman el equipo de trabajo de su Emprendimiento, incluyéndose?"
                                    required
                                    error={error.workTeam !== ''}
                                    helperText={error.workTeam[0] ?? ''}
                                    onChange={handleChange}
                                    options={workTeams.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="jobsGenerated"
                                    name="jobsGenerated"
                                    value={form.jobsGenerated}
                                    onChange={handleChange}
                                    label="¿Cuántos empleos genera?"
                                    required
                                    disabled={disabled}
                                    error={error.jobsGenerated !== ''}
                                    helperText={error.jobsGenerated[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="dedication"
                                    value={form.dedication}
                                    label="Dedicación de tiempo del equipo emprendedor"
                                    required
                                    error={error.dedication !== ''}
                                    helperText={error.dedication[0] ?? ''}
                                    onChange={handleChange}
                                    options={dedications.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelectMultiple
                                    name="topicsList"
                                    value={form.topicsList}
                                    label="¿Cuáles  de los siguientes temas, consideras necesarios hoy, para el desarrollo de tu modelo de negocio?"
                                    required
                                    error={error.topics !== ''}
                                    helperText={error.topics[0] ?? ''}
                                    onChange={handleChangeSelect}
                                    options={topics.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="economicRecords"
                                    value={form.economicRecords}
                                    label="¿Dónde registra el comportamiento economico de su emprendimiento?"
                                    required
                                    error={error.economicRecords !== ''}
                                    helperText={error.economicRecords[0] ?? ''}
                                    onChange={handleChange}
                                    options={economicRecords.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="channel"
                                    value={form.channel}
                                    label="¿Cuál es su canal de venta?"
                                    required
                                    error={error.channel !== ''}
                                    helperText={error.channel[0] ?? ''}
                                    onChange={handleChange}
                                    options={channels.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="average"
                                    value={form.average}
                                    label="¿Cuál es el nivel de ventas promedio mensual en el último año?"
                                    required
                                    error={error.average !== ''}
                                    helperText={error.average[0] ?? ''}
                                    onChange={handleChange}
                                    options={averages.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelectMultiple
                                    name="marketTypeList"
                                    value={form.marketTypeList}
                                    label="¿En qué tipo de mercado realizas tus ventas?"
                                    required
                                    error={error.marketTypeList !== ''}
                                    helperText={error.marketTypeList[0] ?? ''}
                                    onChange={handleChangeSelect}
                                    options={marketTypes.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>                  
                                <CustomSelectMultiple
                                    name="incomeMethodList"
                                    value={form.incomeMethodList}
                                    label="¿Cuál es la forma de generar ingresos en tu emprendimiento?"
                                    required
                                    error={error.incomeMethodList !== ''}
                                    helperText={error.incomeMethodList[0] ?? ''}
                                    onChange={handleChangeSelect}
                                    options={incomeMethods.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>           
                                <CustomSelect
                                    name="hasRegistry"
                                    value={form.hasRegistry.toString()}
                                    label="¿Está registrado en la Cámara de Comercio?"
                                    error={error.hasRegistry !== ''}
                                    helperText={error.hasRegistry[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="person"
                                    value={form.person}
                                    label="Si tiene registro en Cámara de Comercio, indique qué tipo de persona es"
                                    required={form.hasRegistry}
                                    disabled={disabled || !form.hasRegistry}
                                    error={error.person !== ''}
                                    helperText={error.person[0] ?? ''}
                                    onChange={handleChange}
                                    options={persons.map((item) => ({
                                        value: item,
                                        label: item
                                    })) as []}
                                />
                            </Grid>
                            <Grid item md={6}>    
                                <CustomSelect
                                    name="hasPatent"
                                    value={form.hasPatent.toString()}
                                    label="¿Su emprendimiento cuenta con alguna patente?"
                                    error={error.hasPatent !== ''}
                                    helperText={error.hasPatent[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="hasTrademark"
                                    value={form.hasTrademark.toString()}
                                    label="¿Tiene su marca registrada ante Superintendencia de Industria y Comercio?"
                                    error={error.hasTrademark !== ''}
                                    helperText={error.hasTrademark[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="immediateNeed"
                                    name="immediateNeed"
                                    value={form.immediateNeed}
                                    onChange={handleChange}
                                    label="¿Cuál cree usted que es la necesidad inmediata de su emprendimiento para avanzar al siguiente nivel?"
                                    disabled={disabled}
                                    error={error.immediateNeed !== ''}
                                    helperText={error.immediateNeed[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="vision"
                                    name="vision"
                                    value={form.vision}
                                    onChange={handleChange}
                                    label="¿Cómo visualizas tu emprendimiento en 5 años?"
                                    required
                                    error={error.vision !== ''}
                                    helperText={error.vision[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="visionNeed"
                                    name="visionNeed"
                                    value={form.visionNeed}
                                    onChange={handleChange}
                                    label="¿Que consideras que te hace falta para lograr esta visión de 5 años?"
                                    required
                                    error={error.visionNeed !== ''}
                                    helperText={error.visionNeed[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="hasBenefited"
                                    value={form.hasBenefited.toString()}
                                    label="¿Ha sido beneficiado con capital semilla, maquinaria, equipos e insumos?"
                                    error={error.hasBenefited !== ''}
                                    helperText={error.hasBenefited[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="entityBenefited"
                                    name="entityBenefited"
                                    value={form.entityBenefited}
                                    onChange={handleChange}
                                    label="¿En qué entidad?"
                                    required={form.hasBenefited}
                                    disabled={disabled || !form.hasBenefited}
                                    error={error.entityBenefited !== ''}
                                    helperText={error.entityBenefited[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="solvesProblem"
                                    name="solvesProblem"
                                    value={form.solvesProblem}
                                    onChange={handleChange}
                                    label="¿Cuál es el problema o necesidad existente que tu producto/servicio resuelve en nuestra sociedad?"
                                    disabled={disabled}
                                    error={error.solvesProblem !== ''}
                                    helperText={error.solvesProblem[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="description"
                                    name="description"
                                    value={form.description}
                                    onChange={handleChange}
                                    label="Describa de manera especifica en que consiste la oferta de su emprendimiento"
                                    required
                                    error={error.description !== ''}
                                    helperText={error.description[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="hasGuild"
                                    value={form.hasGuild.toString()}
                                    label="¿Está afiliado a algún gremio o asociación?"
                                    error={error.hasGuild !== ''}
                                    helperText={error.hasGuild[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="guild"
                                    name="guild"
                                    value={form.guild}
                                    onChange={handleChange}
                                    label="¿Cuál?"
                                    required={form.hasGuild}
                                    disabled={disabled || !form.hasGuild}
                                    error={error.guild !== ''}
                                    helperText={error.guild[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="portfolio"
                                    value={form.portfolio}
                                    label="¿Cuenta con portafolio de productos o servicios?"
                                    required
                                    error={error.portfolio !== ''}
                                    helperText={error.portfolio[0] ?? ''}
                                    onChange={handleChange}
                                    options={portfolios.map((item) => ({
                                        value: item,
                                        label: item
                                    })) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}></Grid>
                            <Grid item md={6}>
                                <SaveButton type="submit" disabled={disabled}>Guardar</SaveButton>
                            </Grid>
                        </Grid>
                    </form>
                </Content>
            </CardForm>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<BallotIcon style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default CharacterizationForm
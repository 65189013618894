import {styled} from "@mui/system";
import {Card, Typography} from "@mui/material";
import colors from "../../../utils/colors";

export const CardContainer = styled(Card)({
    width: '100%',
    maxHeight: 260,
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    marginBottom: 10,
})

export const CardForm = styled(Card)({
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
})

export const CardHeader = styled('div')({
    width: '100%',
    height: 60,
    backgroundColor: colors.light,
    color: colors.text,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 14,
    fontWeight: 700,
    flexGrow: 1,
    textTransform: 'uppercase'
})

export const ListContainer = styled('div')({
    height: '100%',
    maxHeight: 600,
    flexGrow: 1,
    width: '100%',
    marginBottom: 20,
})

const getIconColor = (type?: string): string => {
    if (type === 'entrepreneurs') return colors.second
    else if (type === 'entrepreneurships') return colors.yellowColor
    else if (type === 'diagnosis') return colors.dark
    return colors.dark
}

const getFont = (type?: string): string[] => {
    if (type === 'title') return [colors.dark, '12px']
    else if (type === 'value') return [colors.text, '20px']
    else if (type === 'secondValue') return [colors.text, '12px']
    return [colors.text, '12px']
}

interface Props {
    size?: string
    type?: string
    line?: string
}

export const Row = styled('div')({
    width: '100%',
    display: 'flex',
    padding: '.8rem 1.5rem',
})

export const Column = styled('div')<Props>(({size, line}) => ({
    width: size ? `${size}%` : '50%',
    margin: 'auto',
    textAlign: 'left',
    borderBottom: line ? `1px solid ${colors.text}` : '',
}))

export const Text = styled('div')<Props>(({type}) => ({
    fontWeight: 'bold',
    color: getFont(type)[0],
    fontSize: getFont(type)[1],
}))

export const IconContainer = styled('div')<Props>(({type}) => ({
    backgroundColor: getIconColor(type),
    padding: 6,
    alignItems: 'center',
    display: 'flex',
    borderRadius: 5
}))

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
    color: 'rgba(255, 255, 255, 0.8)'
})
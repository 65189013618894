import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import colors from "../../../utils/colors";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {IconButton} from "@mui/material";

const drawerWidth = 250;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    backgroundColor: colors.whiteColor,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const ImageContainer = styled('div')({
    flexGrow: 1
})

export const Image = styled('img')({
    height: 66,
    marginTop: 7
})

export const CustomIconButton = styled(IconButton)({
    color: colors.main,
    fontSize: '1.8rem'
})
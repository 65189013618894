import {styled} from "@mui/system";

export const Container = styled('div')({
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(47 134 166 / 90%)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 500ms ease',
})

export const Card = styled('div')({
    width: 400,
    minHeight: 500,
    borderRadius: 20,
    backgroundColor: 'transparent',
    padding: '4rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'all 2s ease',
})

export const ContainerSpinner = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '2.3rem',
})

export const TextSpinner = styled('div')({
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#fff',
    paddingBottom: '1rem'
})
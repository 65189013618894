import {styled} from "@mui/system";
import {Button} from "@mui/material";
import colors from "../../../utils/colors";
import {Link} from "react-router-dom";


export const ResetPasswordButton = styled(Button)({
    width: '100%',
    height: 50,
    marginTop: 35,
    backgroundColor: colors.dark,
    marginRight: 5,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.text,
    }
})

export const LoginLink = styled(Link)({
    width: 270,
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'block',
    margin: '50px auto',
    textDecoration: 'none',
    textAlign: 'center',
    '&:hover': {
        textDecoration: 'underline'
    }
});
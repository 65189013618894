// main libraries

// icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StorefrontIcon from "@mui/icons-material/Storefront";

// styles
import {Container, Content, StartIcon, IconContainer, Item, Row, Icon, Text, Title} from "./styles";
import {useSearchParams} from "react-router-dom";
import React from "react";

interface Props {
    firstTitle: string
    secondTitle: string
    back?: boolean
}

function BackListItem(props: Props) {
    const {firstTitle, secondTitle, back} = props
    let [searchParams, setSearchParams] = useSearchParams();

    const getParams = () => {
        if (back) {
            let item = {}
            if (searchParams.get('item')) {
                item = {item: searchParams.get('item')}
            }
    
            return {...item, detail: 'entrepreneurship', type: 'show_entrepreneurship'}
        }
        return {}
    }

    return (
        <Item onClick={() => setSearchParams(getParams())}>
            <StartIcon>
                <ChevronLeftIcon fontSize="inherit"/>
            </StartIcon>
            <Container>
                <Row>
                    <Title>Emprendimiento</Title>
                </Row>
                <Row>
                    <Icon>
                        <IconContainer>
                            <StorefrontIcon fontSize="inherit"/>
                        </IconContainer>
                    </Icon>
                    <Content>
                        <Text>{firstTitle}</Text>
                        <Text>{secondTitle}</Text>
                    </Content>
                </Row>
            </Container>
        </Item>
    )
}

export default BackListItem
// main libraries
import React from "react";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

// components & utils
import {InputField} from "../../components/InputField";
import Router from "../../../router";

//api
import SecurityApi from "../../../api/SecurityApi";

// styles
import {LoginLink, ResetPasswordButton} from "./styles";
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

function ResetPasswordForm() {
    const [form, setForm] = React.useState({email: ''})
    const [error, setError] = React.useState({hasError: false, message: 'Ingresar correo electrónico registrado'})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const security = SecurityApi.Instance

    const handleSubmit = (event: any) => {
        event.preventDefault()
        dispatch(activeLoading())
        security.resetPassword(form.email).then((response) => {
            dispatch(deactivateLoading())
            if (response?.status === 200) {
                navigate(Router.appLogin)
            } else if (response?.status === 400) {
                setError({
                    hasError: true,
                    message: 'El correo electrónico ingresado no se encuentra registrado. Por favor verifiquelo e intente nuevamente.'
                })
            } else {
                setError({message: 'Se presentó un error inesperado', hasError: true})
            }
        })
    }

    function handleChange(event: any) {
        setForm({...form, [event.target.name]: event.target.value})
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12} style={{marginBottom: 15}}>
                    <InputField
                        id="email"
                        name="email"
                        label="Correo electrónico"
                        variant="standard"
                        helperText={error.message}
                        value={form.email}
                        onChange={handleChange}
                        error={error.hasError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ResetPasswordButton variant="contained" type="submit" disableElevation>
                        ENVIAR SOLICITUD
                    </ResetPasswordButton>
                </Grid>
                <LoginLink to={Router.appLogin}>
                    {'¿Deseas regresar a iniciar sesión? Ingresa aquí.'}
                </LoginLink>
            </Grid>
        </form>
    )
}

export default ResetPasswordForm
// main libraries
import {FormHelperText} from "@mui/material";

// styles
import {BootstrapInput, CustomFormControl, CustomInputLabel} from "./styles";


interface Props {
    error?: boolean
    required?: boolean
    helperText?: string
    id: string
    name: string
    label?: string
    placeholder?: string
    onChange: (value: any) => void
    value: any
    type?: string
    multiline?: boolean
    maxRows?: number
    disabled?: boolean
}

function TextField(props: Props) {
    const {type, error, required, helperText, id, name, label, placeholder, value, onChange, multiline, maxRows, disabled} = props
    return (
        <CustomFormControl variant="standard" error={error} required={required} disabled={disabled}>
            <CustomInputLabel shrink htmlFor={id}>
                {label}
            </CustomInputLabel>
            <BootstrapInput id={id} name={name} onChange={onChange} value={value} type={type} placeholder={placeholder} multiline={multiline} maxRows={maxRows}/>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </CustomFormControl>
    )
}

export default TextField
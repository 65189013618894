import {Container, CustomDivider} from "./styles";

interface Props {
    name: string
}

function Section({name}: Props) {
    return (
        <Container>
            {name}
            <CustomDivider/>
        </Container>
    )
}

export default Section
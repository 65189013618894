import {styled} from "@mui/system";
import colors from "../../../utils/colors";

const getColors = (transparent?: string, disabled?: boolean, create?: string, selected?: boolean): string[] => {
    if (transparent === 'true') return [colors.whiteColor, colors.grayColor]
    else if (disabled) return ['#bfc8d2', '#bfc8d2']
    else if (selected) return [colors.dark, colors.dark]
    else if (create === 'true') return [colors.text, colors.dark]

    return [colors.text, colors.dark]
}

interface ItemProps {
    transparent?: string
    disabled?: boolean
    create?: string
    selected: boolean
}

export const Item = styled('div')<ItemProps>(({transparent, disabled, create, selected}) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    width: '100%',
    height: 50,
    backgroundColor: getColors(transparent, disabled, create, selected)[0],
    borderRadius: 8,
    marginBottom: 10,
    '&:hover': {
        backgroundColor: getColors(transparent, disabled, create, selected)[1],
        transition: 'all 0.3s ease-in 0s'
    }
}))

export const Content = styled('div')({
    maxWidth: '69%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    color: colors.whiteColor,
    '& p:last-child': {
        fontWeight: 600
    }
})

export const Text = styled('p')({
    width: '100%',
    margin: 0,
    fontSize: 11,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 28,
    padding: '10px 15px',
    color: 'rgba(255, 255, 255, 0.55)'
})

export const EndIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 50,
    padding: 1,
    color: colors.whiteColor
})
import {styled} from "@mui/system";
import colors from "../../../utils/colors";
import {Divider} from "@mui/material";

export const Container = styled('div')({
    color: colors.text,
    fontWeight: 600,
    fontSize: 12
})

export const CustomDivider = styled(Divider)({
    borderColor: colors.text,
    marginBottom: 10
})
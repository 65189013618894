import {styled} from "@mui/system";
import colors from "../../../utils/colors";

interface ItemProps {
    selected?: boolean
}

export const Item = styled('div')<ItemProps>(({selected}) => ({
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    height: 50,
    borderRadius: 8,
    backgroundColor: selected ? colors.grayColor : colors.whiteColor,
    marginBottom: 10,
    '&:hover': {
        backgroundColor: colors.grayColor,
        transition: 'all 0.3s ease-in 0s'
    }
}))

export const Content = styled('div')({
    maxWidth: '66%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    '& p:last-child': {
        fontWeight: 500,
        color: colors.text,
        textTransform: 'capitalize',
    },
})

export const Text = styled('p')({
    width: '100%',
    margin: 0,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize'
})

export const IconContainer = styled('div')({
    backgroundColor: colors.darkText,
    padding: 5,
    alignItems: 'center',
    display: 'flex',
    borderRadius: 6,
})

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 24,
    padding: '10px 15px',
    color: 'rgba(255, 255, 255, 0.8)'
})

export const EndIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 30,
    padding: 1,
    color: colors.text
})
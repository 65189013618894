import {styled} from "@mui/system";
import colors from "../../../utils/colors";

interface ItemProps {
    disabled?: boolean
}

export const Item = styled('div')<ItemProps>(({disabled}) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    width: '100%',
    backgroundColor: disabled ? colors.grayColor : '#2fdd92',
    borderRadius: 6,
    '&:hover': {
        backgroundColor: disabled ? colors.grayColor : '#2fdd92',
        transition: 'all 0.3s ease-in 0s'
    }
}))

export const Title = styled('p')({
    width: '100%',
    margin: '0 0 3px 0',
    fontSize: 14,
    color: colors.whiteColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

export const EndIcon = styled('div')<ItemProps>(({disabled}) => ({   
    display: 'flex',
    alignItems: 'center',
    fontSize: 30,
    padding: 3,
    color: colors.whiteColor,
    backgroundColor: disabled ? colors.grayColor : '#55e0a5',
    borderRadius: '0 6px 6px 0'
}))

export const Container = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 16px'
})

export const Row = styled('div')({
    display: 'flex'
})
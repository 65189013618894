import {Http} from "../utils/http";
import Router from "../router";
import Characterization from "../models/Characterization";

class CharacterizationApi {
    private static instance: CharacterizationApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list(entrepreneurshipId: number): Promise<any> {
        return this.http.get({
            route: Router.apiCharacterization.replace(':id', entrepreneurshipId.toString()),
            hasToken: true
        })
    }

    public async create(entrepreneurshipId: number, data: Characterization): Promise<any> {
        return this.http.post({
            route: Router.apiCharacterization.replace(':id', entrepreneurshipId.toString()),
            data: data,
            hasToken: true
        })
    }

    public async update(entrepreneurshipId: number, characterizationId: number, data: Characterization): Promise<any> {
        return this.http.patch({
            route: Router.apiCharacterizationEdit
                .replace(':id', entrepreneurshipId.toString())
                .replace(':characterizationId', characterizationId.toString()),
            data: data,
            hasToken: true
        })
    }
}

export default CharacterizationApi
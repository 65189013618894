// models
import Entrepreneurship from "../models/Entrepreneurship";

// utils
import {Http} from "../utils/http";
import Router from "../router";

class EntrepreneurshipApi {
    private static instance: EntrepreneurshipApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list(search?: string | null): Promise<any> {
        return this.http.get({
            route: Router.apiEntrepreneurships,
            hasToken: true,
            params: {search: search}
        })
    }

    public async create(data: Entrepreneurship): Promise<any> {
        return this.http.post({
            route: Router.apiEntrepreneurships,
            data: {...data},
            hasToken: true
        })
    }

    public async update(data: Entrepreneurship): Promise<any> {
        return this.http.patch({
            route: Router.apiEntrepreneurshipDetail.replace(':id', data.id.toString()),
            data: {...data},
            hasToken: true
        })
    }

    public async delete(id: string): Promise<any> {
        return this.http.delete({
            route: Router.apiEntrepreneurshipDetail.replace(':id', id),
            hasToken: true
        })
    }
}

export default EntrepreneurshipApi
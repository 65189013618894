// main libraries
import React from "react";
import {Grid, Tooltip} from "@mui/material";
import {useSelector} from "react-redux";

// components
import ProfileForm from "../../containers/ProfileForm";

// reducers
import {selectUser} from "../../../reducers/user/userSlice";

// icons
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

// styles
import "../../assets/css/ArrowInfo.css";
import {
    AvatarContainer,
    AvatarSection,
    AvatarTitle,
    CardContainer,
    CardForm, CardHeader, CardHeaderTitle,
    Container, Content, EditIconButton,
    EmailText,
    ExpandedBox,
    FullNameText
} from "./styles";

function ProfilePage() {
    const user = useSelector(selectUser)
    const [disabled, setDisabled] = React.useState<boolean>(true)

    const toggleDisabled = () => setDisabled(!disabled)

    return (
        <Container container>
            <AvatarSection item md={4}>
                <ExpandedBox
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <CardContainer>
                        <AvatarTitle>{user?.role}</AvatarTitle>
                        <AvatarContainer>
                            <PersonIcon fontSize="inherit"/>
                        </AvatarContainer>
                        <FullNameText>{user?.firstName} {user?.lastName}</FullNameText>
                        <EmailText>{user?.email}</EmailText>
                    </CardContainer>
                </ExpandedBox>
            </AvatarSection>
            <Grid item md={8}>
                <CardForm elevation={0}>
                    <CardHeader>
                        <CardHeaderTitle>Perfil del emprendedor</CardHeaderTitle>
                        <Tooltip title="Editar">
                            <EditIconButton onClick={toggleDisabled}>
                                {disabled ? <EditIcon/> : <VisibilityIcon/>}
                            </EditIconButton>
                        </Tooltip>
                    </CardHeader>
                    <Content>
                        <ProfileForm isDisabled={disabled}/>
                    </Content>
                </CardForm>
            </Grid>
        </Container>
    )
}

export default ProfilePage
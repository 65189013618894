// main libraries
import {useSearchParams} from "react-router-dom";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// styles
import {Content, EndIcon, Item, StartIcon, Text} from "./styles";

interface Props {
    icon: JSX.Element
    isTransparent?: boolean
    createdAction?: boolean
    disabled?: boolean
    firstTitle: string
    secondTitle: string
    params?: any
    onClick?: () => void
}

function ListItem(props: Props) {
    const {params, icon, isTransparent, createdAction, disabled, firstTitle, secondTitle, onClick} = props
    let [searchParams] = useSearchParams();

    const booleanToString = (value?: boolean) => {
        return value ? 'true' : 'false'
    }

    const isSelected = (): boolean => {
        return searchParams.get('type') === params?.type && !createdAction;
    }

    return (
        <Item
            transparent={booleanToString(isTransparent)}
            disabled={disabled}
            create={booleanToString(createdAction)}
            onClick={onClick}
            selected={isSelected()}
        >
            <StartIcon>
                {icon}
            </StartIcon>
            <Content>
                <Text>{firstTitle}</Text>
                <Text>{secondTitle}</Text>
            </Content>
            <EndIcon>
                <ChevronRightIcon style={{fontSize: 30}}/>
            </EndIcon>
        </Item>
    )
}

export default ListItem
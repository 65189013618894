import {styled} from "@mui/system";
import {Card, Typography} from "@mui/material";
import colors from "../../../utils/colors";

export const CardContainer = styled(Card)({
    width: '100%',
    height: 220,
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '1rem 1.4rem 1rem 1.4rem'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 12,
    flexGrow: 1,
    textTransform: 'uppercase',
    color: colors.grayText
})

export const GraphicContainer = styled('div')({
    width: '100%',
    height: '100%',
    paddingTop: 14
})
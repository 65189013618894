import { PictureAsPdf } from "@mui/icons-material";
import {Card, Grid, IconButton, Tab, Tabs, Typography} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const CardContainer = styled(Card)({
    width: '100%',
    minHeight: 550,
    height: '100%',
    flexGrow: 1,
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '30px 20px',
    marginBottom: 20,
    overflowY: 'inherit'
})

export const Container = styled(Grid)({
    padding: '0 30px'
})

export const Expanded = styled(Grid)({
    padding: '20px 0 0',
})

export const ExpandedContent = styled(Grid)({
    height: '100%',
    minHeight: 'calc(100vh - 110px)'
})

export const List = styled('div')({
    width: '100%'
})

export const EmptyContainer = styled('div')({
    marginTop: 25,
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})

export const EmptyIcon = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    fontSize: 60,
    color: colors.text,
    padding: 8,
})

export const TextEmpty = styled('div')({
    display: 'flex',
    textAlign: 'center',
    fontSize: 23,
    fontWeight: 500,
    color: colors.text,
    padding: 8,
})

export const Content = styled('div')({
})

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 18,
    fontWeight: 700,
    flexGrow: 1
})

export const EditIconButton = styled(IconButton)({
    backgroundColor: colors.second,
    color: colors.whiteColor,
    '&:hover': {
        backgroundColor: 'rgb(47 221 146 / 85%)'
    }
})

export const DeleteIconButton = styled(IconButton)({
    backgroundColor: colors.redColor,
    color: colors.whiteColor,
    marginLeft: 10,
    '&:hover': {
        backgroundColor: 'rgb(255 107 128 / 85%)'
    }
})

export const PdfIcon = styled(PictureAsPdf)({
    color: colors.redColor,
    fontSize: 30,
    cursor: 'pointer'
})

export const TabsStyle = styled(Tabs)({
    fontSize: 10,
    padding: 0,
})

export const TabStyle = styled(Tab)({
    fontSize: 11,
    padding: 0
})
import {Http} from "../utils/http";
import Router from "../router";

class TrackingApi {
    private static instance: TrackingApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list(): Promise<any> {
        return this.http.get({
            route: Router.apiTrackings,
            hasToken: true
        })
    }

    public async findByEntrepreneurship(id: string): Promise<any> {
        return this.http.get({
            route: Router.apiTrackingByEntrepreneurship.replace(':id', id),
            hasToken: true
        })
    }

    public async create(data: any): Promise<any> {
        return this.http.post({
            route: Router.apiTrackings,
            data: data,
            hasToken: true,
            isMultipart: true
        })
    }
}

export default TrackingApi
// main libraries
import {styled} from "@mui/system";
import {Link} from "react-router-dom";
import {Button, InputLabel} from "@mui/material";

// utils
import colors from "../../../utils/colors";

export const SignUpButton = styled(Button)({
    width: '100%',
    height: 50,
    margin: '5px 0',
    backgroundColor: colors.dark,
    color: colors.whiteColor,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.text,
    }
})

export const Scroll = styled('div')({
    overflowY: 'hidden',
    height: '100%',
})

export const LoginLink = styled(Link)({
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'block',
    margin: '15px auto',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline'
    }
});

export const CustomInputLabel = styled(InputLabel)({
    fontSize: 14,
    fontWeight: 600,
    overflow: 'inherit',
    '&.Mui-focused': {
        color: colors.text
    }
})
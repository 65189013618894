import {styled} from "@mui/system";
import {Button} from "@mui/material";
import colors from "../../../utils/colors";

export const ProfileButton = styled(Button)({
    width: '100%',
    height: 50,
    margin: '15px 0 5px 0',
    backgroundColor: colors.dark,
    color: colors.whiteColor,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.text,
    },
    '&:disabled': {
        backgroundColor: 'rgb(16 56 91 / 76%)',
        color: 'rgba(255, 255, 255, 0.26)'
    }
})
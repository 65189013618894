// main libraries
import {FormHelperText} from "@mui/material";

// styles
import {BootstrapInput, CustomFormControl, CustomInputLabel} from "./styles";


interface Props {
    error?: boolean
    required?: boolean
    helperText?: string
    id: string
    name: string
    label?: string
    onChange: (value: any) => void
    onFocus?: (value: any) => void
    value: any
    disabled?: boolean
}

function HourField(props: Props) {
    const {error, required, helperText, id, name, label, value, onChange, onFocus, disabled} = props
    return (
        <CustomFormControl variant="standard" error={error} required={required} disabled={disabled}>
            <CustomInputLabel shrink htmlFor={id}>
                {label}
            </CustomInputLabel>
            <BootstrapInput id={id} name={name} onChange={onChange} onFocus={onFocus} value={value} mask="99:99" defaultValue="00:00" disabled={disabled}/>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </CustomFormControl>
    )
}

export default HourField
import {Card, Grid, IconButton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const Container = styled(Grid)({
    padding: '0 20px',
    marginBottom: 20
})

export const AvatarSection = styled(Grid)({
    padding: '0px 20px'
})

export const CardContainer = styled(Card)({
    minHeight: 300,
    maxHeight: 'calc(100vh - 100px)',
    width: '100%',
    borderRadius: 20,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '15px 20px'
})

export const AvatarTitle = styled(Typography)({
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 16,
    color: colors.text,
    margin: '20px 0'
})

export const AvatarContainer = styled('div')({
    width: 100,
    height: 100,
    backgroundColor: colors.main,
    color: colors.whiteColor,
    borderRadius: 100,
    fontSize: 60,
    margin: '5px auto 30px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const FullNameText = styled(Typography)({
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center'
})

export const EmailText = styled(Typography)({
    fontSize: 14,
    textAlign: 'center',
    color: colors.text,
    marginTop: 8
})

export const ExpandedBox = styled(Grid)({
    height: 'calc(100vh - 100px)',
    width: 'auto',
    padding: '20px 0px',
})

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 10,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Content = styled('div')({
    padding: '30px 50px',
    overflowY: 'inherit'
})

export const EditIconButton = styled(IconButton)({
    backgroundColor: colors.second,
    color: colors.whiteColor,
    '&:hover': {
        backgroundColor: 'rgb(47 221 146 / 85%)'
    }
})

import {styled} from "@mui/system";
import colors from "../../../utils/colors";
import Button from '@mui/material/Button';

const getVisibility = (hide?: string): string => {
    if (hide === "true") return 'none'
    return 'flex'
}

interface ItemProps {
    hide?: string
}

export const Item = styled(Button) <ItemProps> (({hide}) => ({
    cursor: 'pointer',
    display: getVisibility(hide),
    width: '100%',
    height: 50,
    backgroundColor: colors.text,
    borderRadius: 8,
    marginBottom: 10,
    textAlign: 'left',
    '&:hover': {
        backgroundColor: colors.text,
        transition: 'all 0.3s ease-in 0s'
    }
}))

export const Content = styled('div')({
    maxWidth: '69%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    color: colors.whiteColor,
    '& p:last-child': {
        fontWeight: 600
    }
})

export const Text = styled('p')({
    width: '100%',
    margin: 0,
    fontSize: 12,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 26,
    padding: '10px 15px',
    color: 'rgba(255, 255, 255, 0.55)'
})

export const EndIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 30,
    padding: 1,
    color: colors.whiteColor
})
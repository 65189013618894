// main libraries
import React from "react"
import {Alert, Grid} from "@mui/material"
import {useNavigate} from "react-router-dom"
import {useDispatch} from "react-redux";

// components & utils
import {InputField} from "../../components/InputField"
import Router from "../../../router";
import {Auth} from "../../../utils/auth";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";
import {authenticate} from "../../../reducers/user/userSlice";

// api
import SecurityApi from "../../../api/SecurityApi";

// styles
import {ContainerButton, LoginButton, ResetPasswordLink, SignUpButton} from "./styles"

function LoginForm() {
    const [form, setForm] = React.useState({email: '', password: ''})
    const [error, setError] = React.useState({hasError: false, message: ''})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const security = SecurityApi.Instance
    const auth = Auth.Instance

    const handleSubmit = (event: any) => {
        event.preventDefault()
        dispatch(activeLoading())
        
        sessionStorage.clear()
        security.login(form.email, form.password).then((response) => {
            dispatch(deactivateLoading())
            if (response?.status === 200) {
                auth.authenticate(response?.data?.access_token, response?.data?.user)
                dispatch(authenticate(auth.getUser()))
                
                if (auth.getUser()?.role === 'Emprendedor' && !auth.getUser()?.profile?.isCompleted) {
                    navigate(Router.appProfile)
                }
                else {
                    navigate(Router.appEntrepreneurship)
                }
            } else if (response?.status === 400) {
                setError({message: 'Correo electrónico y/o contraseña no válidos', hasError: true})
            } else {
                setError({message: 'Se presentó un error inesperado', hasError: true})
            }
        })
    }

    const handleChange = (event: any) => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    function navigateTo(route: string) {
        navigate(route)
    }

    return (
        <form onSubmit={handleSubmit}>
            {error.hasError ? <Alert severity="error" style={{marginBottom: 15}}>{error.message}</Alert> : null}
            <Grid container>
                <Grid item xs={12} style={{marginBottom: 15}}>
                    <InputField
                        id="email"
                        name="email"
                        label="Correo electrónico"
                        variant="standard"
                        value={form.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputField
                        id="password"
                        name="password"
                        label="Contraseña"
                        type="password"
                        variant="standard"
                        value={form.password}
                        onChange={handleChange}
                    />
                </Grid>
                <ContainerButton
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="centerContainerButton"
                >
                    <LoginButton variant="contained" type="submit" disableElevation>
                        INICIAR SESIÓN
                    </LoginButton>
                    <SignUpButton variant="outlined" onClick={() => navigateTo(Router.appSignUp)}>
                        {'REGISTRARSE'}
                    </SignUpButton>
                </ContainerButton>
                <ResetPasswordLink to={Router.appResetPassword}>
                    {'¿Olvidaste la contraseña?'}
                </ResetPasswordLink>
            </Grid>
        </form>
    )
}

export default LoginForm
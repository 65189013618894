import {styled} from "@mui/system";
import colors from "../../../utils/colors";

const getFont = (second?: string): string[] => {
    if (second === "true") return ['11px', colors.text, 'normal']
    else return ['12px', colors.blackColor, 'bold']
}

const getColors = (iserror?: string): string[] => {
    if (iserror === "false") return [colors.second, colors.grayColor]
    else if (iserror === "true") return [colors.redColor, colors.grayColor]
    else return [colors.blackColor, colors.grayColor]
}

interface ItemProps {
    iserror?: string
    selected?: boolean
    disabled?: boolean
    second?: string
}

export const Text = styled('p') <ItemProps>(({second}) => ({
    width: '100%',
    margin: 0,
    fontSize: getFont(second)[0],
    color: getFont(second)[1],
    fontWeight: getFont(second)[2],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

export const TextError = styled('p') <ItemProps>(({iserror}) => ({
    width: '100%',
    margin: 0,
    fontSize: 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: getColors(iserror)[0]
}))

export const EndIcon = styled('div') <ItemProps>(({iserror}) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 40,
    padding: 1,
    color: getColors(iserror)[0]
}))

export const Item = styled('div')<ItemProps>(({selected, disabled}) => ({
    cursor: disabled ? 'default' : 'pointer',
    display: 'flex',
    width: '100%',
    height: 50,
    borderRadius: 8,
    backgroundColor: selected ? colors.grayColor : colors.whiteColor,
    marginBottom: 10,
    '&:hover': {
        backgroundColor: colors.grayColor,
        transition: 'all 0.3s ease-in 0s'
    }
}))

export const Content = styled('div')({
    maxWidth: '80%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: 20,
})

export const IconContainer = styled('div')({
    backgroundColor: colors.notification,
    padding: 5,
    alignItems: 'center',
    display: 'flex',
    borderRadius: 8
})

export const StartIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    fontSize: 30,
    padding: '10px 15px',
    color: 'rgba(255, 255, 255, 0.8)'
})
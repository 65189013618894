import {Http} from "../utils/http";
import Router from "../router";

class DiagnosticApi {
    private static instance: DiagnosticApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list(): Promise<any> {
        return this.http.get({
            route: Router.apiDiagnostics,
            hasToken: true
        })
    }

    public async find(id: string): Promise<any> {
        return this.http.get({
            route: Router.apiDiagnosticDetail.replace(':id', id),
            hasToken: true
        })
    }

    public async findByEntrepreneurship(id: string): Promise<any> {
        return this.http.get({
            route: Router.apiDiagnosticByEntrepreneurship.replace(':id', id),
            hasToken: true
        })
    }

    public async create(data: any): Promise<any> {
        return this.http.post({
            route: Router.apiDiagnostics,
            data: data,
            hasToken: true
        })
    }

    public async update(id: string, data: any): Promise<any> {
        return this.http.patch({
            route: Router.apiDiagnosticDetail.replace(':id', id),
            data: data,
            hasToken: true
        })
    }
}

export default DiagnosticApi
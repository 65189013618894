// components

// icons
import StorefrontIcon from "@mui/icons-material/Storefront";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssignmentIcon from '@mui/icons-material/Assignment';

// styles
import {
    CardContainer,
    Text,
    ListContainer,
    CardForm,
    CardHeader,
    CardHeaderTitle,
    Row,
    Column,
    IconContainer,
    StartIcon,
} from "./styles";
import { Fragment } from "react";

interface Item {
    name: string
    value: number
    icon?: string
}

interface Props {
    title: string
    type: string
    list: Item[]
}

function StatisticsList(props: Props) {
    const {title, type, list} = props

    const getIcon = (icon: string) => {
        if (icon === 'entrepreneurs') {
            return <AccountBoxIcon fontSize="inherit" style={{ color: '#FFF' }}/>
        }
        else if (icon === 'entrepreneurships') {
            return <StorefrontIcon fontSize="inherit" style={{ color: '#FFF' }}/>
        }
        else if (icon === 'diagnosis') {
            return <AssignmentIcon fontSize="inherit" style={{ color: '#FFF' }}/>
        }

        return <StorefrontIcon fontSize="inherit" style={{ color: '#FFF' }}/>
    }

    return (
        <CardContainer>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>{title}</CardHeaderTitle>
                </CardHeader>
            </CardForm>
            <ListContainer style={{ marginTop: 8 }}>
                {list.map((row: any, index: number) => (
                    <Fragment key={index}>
                        {type === 'generalInfo' &&
                            <Row>
                                <Column size="20">
                                    <StartIcon>
                                        <IconContainer type={row.icon}>
                                            {getIcon(row.icon)}
                                        </IconContainer>
                                    </StartIcon>
                                </Column>
                                <Column size="60">
                                    <Text type="title">{row.name}</Text>
                                </Column>
                                <Column size="20" style={{ textAlign: 'right' }}>
                                    <Text type="value">{row.value}</Text>
                                </Column>
                            </Row>
                        }
                        {type === 'educationalLevel' &&
                            <Row>
                                <Column size="80" line="true">
                                    <Text type="title">{row.name}</Text>
                                </Column>
                                <Column size="20" line="true" style={{ textAlign: 'right' }}>
                                    <Text type="secondValue">{row.total}</Text>
                                </Column>
                            </Row>
                        }
                    </Fragment>
                ))}
            </ListContainer>
        </CardContainer>
    )
}

export default StatisticsList
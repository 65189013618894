import {styled} from "@mui/system";
import {Card, Typography} from "@mui/material";
import colors from "../../../utils/colors";
import TableCell from '@mui/material/TableCell';
import VisibilityIcon from "@mui/icons-material/Visibility";

const getCellStyle = (type?: string): string[] => {
    if (type === 'head') return [colors.main, '12', 'bold']
    else if (type === 'subhead') return [colors.main, '11', 'normal']
    else if (type === 'subbody') return [colors.blackColor, '11', 'normal']
    return [colors.blackColor, '11', 'bold']
}

const getRowStyle = (type?: string): string[] => {
    if (type === 'main') return [colors.blackColor, '11', 'bold']
    return [colors.main, '10', 'normal']
}

const getTextStyle = (isresult?: string): string[] => {
    if (isresult === "true") return [colors.second]
    return [colors.redColor]
}

interface ItemProps {
    type: string
}

interface CellTextProps {
    isresult?: string
}

export const Cell = styled(TableCell) <ItemProps>(({type}) => ({
    color: getCellStyle(type)[0],
    fontSize: parseInt(getCellStyle(type)[1]),
    fontWeight: getCellStyle(type)[2],
}))

export const RowCell = styled('div') <ItemProps>(({type}) => ({
    color: getRowStyle(type)[0],
    fontSize: parseInt(getRowStyle(type)[1]),
    fontWeight: getRowStyle(type)[2],
}))

export const CellText = styled('span') <CellTextProps>(({isresult}) => ({
    color: getTextStyle(isresult)[0],
}))

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Content = styled('div')({
    padding: '20px',
    display: 'flex',
    marginBottom: 20
})

export const Background = styled('div')({
    backgroundColor: colors.grayColor,
    borderRadius: 20,
    width: '100%',
    marginBottom: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
})

export const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: colors.text,
    fontSize: 80,
    border: `3px dashed ${colors.text}`,
    height: '70%',
    width: '78%',
    borderRadius: 10,
    transition: 'all 0.5s ease-in-out',
    cursor: 'pointer',
    '& p:last-child': {
        fontWeight: 600
    },
    '&:hover': {
        backgroundColor: colors.text,
        color: colors.whiteColor,
    },
    '&:hover & p': {
        color: colors.whiteColor,
    }
})

export const Text = styled('p')({
    fontSize: 14,
    color: colors.main,
    fontWeight: 'bold',
    marginTop: 20
})

export const CustomVisibilityIcon = styled(VisibilityIcon)({
    color: colors.second,
})
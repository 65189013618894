// models
import User from "../models/User";
import ObjFormat from "./objFormat";

export class Auth {
    private static instance: Auth
    private tokenAuth: string | null

    private constructor() {
        this.tokenAuth = sessionStorage.getItem('_token_auth')
    }

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public getToken(): string | null {
        return this.tokenAuth
    }

    public isAuthenticated(): boolean {
        if (this.tokenAuth === null || this.tokenAuth === undefined) return false

        return this.getUser() !== null
    }

    public getUser(): User | null {
        try {
            if (this.tokenAuth === null || this.tokenAuth === 'undefined') return null

            let json = sessionStorage.getItem('user')
            if (json === null || json === 'undefined') return null

            return JSON.parse(json)
        } catch (_) {
            return null
        }
    }

    public isRoleAllowed(role: string[]): boolean {
        try {
            if (this.tokenAuth === null || this.tokenAuth === 'undefined') return false

            let json = sessionStorage.getItem('user')
            if (json === null || json === 'undefined') return false

            let result = JSON.parse(json)
            return role.includes(result.role)
        } catch (_) {
            return false
        }
    }

    public authenticate(token: string, user: User): void {
        sessionStorage.setItem('_token_auth', token)
        sessionStorage.setItem('user', JSON.stringify(ObjFormat.snakeToCamelCase(user)))
        this.tokenAuth = sessionStorage.getItem('_token_auth')
    }

    public logout(): void {
        sessionStorage.clear()
    }

    public profileIsCompleted(user: User): void {
        sessionStorage.setItem('user', JSON.stringify(user))
    }
}

// main libraries

// components
import Layout from "../../containers/Layout";
import ChangePasswordForm from "../../containers/ChangePasswordForm";

// styles & assets
import CustomCard from "../../components/CustomCard";

function ChangePasswordPage() {
    return (
        <Layout>
            <CustomCard title="Cambiar contraseña" width={450}>
                <ChangePasswordForm/>
            </CustomCard>
        </Layout>
    )
}

export default ChangePasswordPage
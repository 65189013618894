import {Http} from "../utils/http";
import Router from "../router";

class CityApi {
    private static instance: CityApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async listByStateId(stateId: number) {
        return this.http.get({
            route: Router.apiCities,
            params: {state: stateId},
        })
    }
}

export default CityApi
import {Button, Grid} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";
import {Link} from "react-router-dom";

export const ContainerButton = styled(Grid)({
    margin: '40px 0 0 0'
})

export const LoginButton = styled(Button)({
    height: 50,
    backgroundColor: colors.dark,
    marginRight: 5,
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.text,
    }
})

export const SignUpButton = styled(Button)({
    height: 50,
    border: `2px solid ${colors.dark}`,
    color: colors.dark,
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        borderColor: colors.text,
        backgroundColor: colors.text,
        color: colors.whiteColor,
    }
})

export const ResetPasswordLink = styled(Link)({
    color: 'rgba(0, 0, 0, 0.6)',
    display: 'block',
    margin: '40px auto',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline'
    }
});
// main libraries
import {Toolbar, Tooltip} from "@mui/material";
import {useDispatch} from "react-redux";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";

// styles & assets
import {AppBar, CustomIconButton, Image, ImageContainer} from "./styles";
import logo from "../../assets/images/red-i-logo.png";

// reducers
import {logout} from "../../../reducers/user/userSlice";

interface Props {
    open?: boolean
    onOpen: () => void
}

function CustomAppBar({open, onOpen}: Props) {
    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <CustomIconButton
                    aria-label="open drawer"
                    onClick={onOpen}
                    edge="start"
                    sx={{
                        marginRight: '36px',
                        ...(open && {display: 'none'}),
                    }}
                >
                    <Tooltip title="Desplegar">
                        <ChevronRightIcon style={{ fontSize: '2.6rem' }} />
                    </Tooltip>
                </CustomIconButton>
                <ImageContainer>
                    <Image src={logo} alt="Logo"/>
                </ImageContainer>
                <CustomIconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleLogout}
                >
                    <Tooltip title="Cerrar sesión">
                        <LogoutIcon fontSize="inherit"/>
                    </Tooltip>
                </CustomIconButton>
            </Toolbar>
        </AppBar>
    )
}

export default CustomAppBar
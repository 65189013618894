import {createTheme} from '@mui/material'
import colors from "../../../utils/colors";


export const theme = createTheme({
    palette: {
        primary: {
            main: colors.main,
            dark: colors.darkText,
            light: colors.light,
        },
        secondary: {
            main: colors.second,
        },
    },
    typography: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: colors.redColor,
                    "&$error": {
                        color: colors.redColor,
                    },
                }
            },
        },
    },
})

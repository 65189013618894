import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'

export interface LoadingState {
    value: boolean
}

const initialState: LoadingState = {
    value: false
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        activeLoading: (state) => {
            state.value = true
        },
        deactivateLoading: (state) => {
            state.value = false
        },
    }
})

export const {activeLoading, deactivateLoading} = loadingSlice.actions

export const selectLoading = (state: RootState) => state.loading.value

export default loadingSlice.reducer

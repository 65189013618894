import {Button, Card, Grid, Typography} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const Container = styled(Grid)({
    height: 'calc(100% - 80px)',
})

export const CardContainer = styled(Card)({
    minHeight: 430,
    maxHeight: 'calc(100vh - 100px)',
    width: 360,
    borderRadius: 20,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '30px 20px'
})

export const AvatarTitle = styled(Typography)({
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 18,
    color: colors.text,
    margin: '20px 0'
})

export const AvatarContainer = styled('div')({
    width: 150,
    height: 150,
    backgroundColor: colors.main,
    color: colors.whiteColor,
    borderRadius: 100,
    fontSize: 100,
    margin: '5px auto 30px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const MessageText = styled(Typography)({
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center'
})

export const RedirectButton = styled(Button)({
    width: '100%',
    height: 50,
    margin: '25px 0 5px',
    backgroundColor: colors.dark,
    color: colors.whiteColor,
    borderRadius: 8,
    fontWeight: 600,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.text,
    }
})


import {styled} from "@mui/system";
import colors from "../../../utils/colors";
import { TimelineDot } from "@mui/lab";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Card, Typography } from "@mui/material";

const getColor = (type: string): string[] => {
    if (type === 'register') return [colors.whiteColor, colors.text, colors.text]
    else if (type === 'service') return [colors.dark, colors.dark, colors.grayColor]
    else if (type === 'tracking') return [colors.whiteColor, colors.dark, colors.dark]
    else if (type === 'diagnostic') return [colors.whiteColor, colors.dark, colors.text]
    else if (type === 'characterization') return [colors.text, colors.text, colors.grayColor]
    return [colors.dark, colors.text, colors.text]
}

const getFont = (type: string): string[] => {
    if (type === 'percentage') return [colors.second, '26px', 'bold']
    else if (type === 'title') return [colors.dark, '12px', 'bold']
    return [colors.text, '12px', 'normal']
}

interface ItemProps {
    type: string
}

export const TimelineDotStyle = styled(TimelineDot)<ItemProps>(({type}) => ({
    backgroundColor: getColor(type)[0],
    border: '3px solid ' + getColor(type)[1],
    color: getColor(type)[2],
    margin: 10,
    padding: 6
}))

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 10,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Container = styled('div')({
    width: '100%',
    height: 'calc(100vh - 280px)',
    overflowY: 'scroll',
})

export const Row = styled('div')({
    width: '100%',
})

export const Text = styled('span')<ItemProps>(({type}) => ({
    color: getFont(type)[0],
    fontSize: getFont(type)[1],
    fontWeight: getFont(type)[2],
    paddingTop: '1.5rem !important'
}))

export const TextTypo = styled(Typography)({
    color: colors.dark,
    fontSize: 11,
    fontWeight: 'bold',
    paddingTop: '1rem !important'
})

export const TextTitle = styled(Typography)({
    color: colors.blackColor,
    fontSize: 14,
    fontWeight: 'bold',
})

export const PdfIcon = styled(PictureAsPdfIcon)({
    color: colors.redColor,
    fontSize: 40,
    cursor: 'pointer'
})
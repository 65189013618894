// components
import { Doughnut } from "react-chartjs-2";
import {Chart, registerables } from 'chart.js'

// styles
import {
    CardContainer,
    CardHeaderTitle,
    GraphicContainer,
} from "./styles";

interface DataGraphic {
    labels: string[]
    datasets: any[]
}

interface Props {
    title: string
    data: DataGraphic
}

function CircleGraphic(props: Props) {
    Chart.register(...registerables)
    const {title, data} = props

    let options = {
		responsive: true,
		maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right' as const,
                labels: {
                    font: {
                        size: 11
                    }
                }
            },
        },
    };
    

    return (
        <CardContainer>
            <CardHeaderTitle>{title}</CardHeaderTitle>
            <GraphicContainer>
                <Doughnut data={data} options={options}/>
            </GraphicContainer>
        </CardContainer>
    )
}

export default CircleGraphic
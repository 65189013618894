// main libraries
import React from "react"
import {Grid, Tooltip, Typography} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import moment from "moment";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import EntrepreneurshipForm from "../../containers/EntrepreneurshipForm";
import EntrepreneurshipInfo from "../../containers/EntrepreneurshipInfo";
import EntrepreneurshipsList from "../../containers/EntrepreneurshipsList";
import EntrepreneurshipShow from "../../containers/EntrepreneurshipShow";
import BackListItem from "../../components/BackListItem";
import CharacterizationForm from "../../containers/CharacterizationForm";
import ModalMessage from "../../components/ModalMessage";

// icons
import StorefrontIcon from "@mui/icons-material/Storefront";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

// models
import Entrepreneurship from "../../../models/Entrepreneurship";
import Diagnostic from "../../../models/Diagnostic";
import Service from "../../../models/Service";
import Tracking from "../../../models/Tracking";
import Characterization from "../../../models/Characterization";

// api
import EntrepreneurshipApi from "../../../api/EntrepreneurshipApi";
import CharacterizationApi from "../../../api/CharacterizationApi";
import DiagnosticApi from "../../../api/DiagnosticApi";
import ServiceApi from "../../../api/ServiceApi";
import TrackingApi from "../../../api/TrackingApi";
import TimelineApi from "../../../api/TimelineApi";

// utils
import ObjFormat from "../../../utils/objFormat";
import { Auth } from "../../../utils/auth";

// styles
import {
    Container,
    Expanded,
    ExpandedContent,
    Content,
    CardForm,
    CardHeader,
    CardHeaderTitle,
    EditIconButton,
    DeleteIconButton,
    PdfIcon,
    TabsStyle,
    TabStyle,
} from "./styles";
import CharacterizationShow from "../../containers/CharacterizationShow";
import DiagnosticStatistic from "../../containers/DiagnosticStatistic";
import ServiceForm from "../../containers/ServiceForm";
import ServiceShow from "../../containers/ServiceShow";
import TrackingForm from "../../containers/TrackingForm";
import TrackingShow from "../../containers/TrackingShow";
import EntrepreneurshipTimeline from "../../containers/EntrepreneurshipTimeline";
import ModalProps from "../../../utils/modalProps";
import router from "../../../router";

function TabContainer(props: any) {
	return (
	  <Typography component="div" style={{ padding: 8 * 3 }}>
		{props.children}
	  </Typography>
	);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

function EntrepreneurshipPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    
    const entrepreneurshipRef = React.useRef<any>(null)
    const isInitPageRef = React.useRef(true);
    const isInitPageCharacterizationsRef = React.useRef(true);
    const isInitRef = React.useRef(true);
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    const [currentData, setCurrentData] = React.useState<any>({
        name: '',
        code: ''
    })

    // init API
    const entrepreneurshipApi = EntrepreneurshipApi.Instance
    const characterizationApi = CharacterizationApi.Instance
    const diagnosticApi = DiagnosticApi.Instance
    const serviceApi = ServiceApi.Instance
    const trackingApi = TrackingApi.Instance
    const timelineApi = TimelineApi.Instance

    const auth = Auth.Instance

    // list
    const [entrepreneurships, setEntrepreneurships] = React.useState<Entrepreneurship[]>([])
    const [characterizations, setCharacterizations] = React.useState<Characterization[]>([])
    const [services, setServices] = React.useState<Service[]>([])
    const [diagnostics, setDiagnostics] = React.useState<Diagnostic[]>([])
    const [trackings, setTrackings] = React.useState<Tracking[]>([])
    const [tabs, setTabs] = React.useState<any>([])

    const [disabled, setDisabled] = React.useState<boolean>(true)
    
    const toggleDisabled = () => setDisabled(!disabled)    

    const getForm = () => {
        try { 
            if (searchParams.get('type') === 'new_entrepreneurship') {
                return <EntrepreneurshipForm refresh={getEntrepreneurships}/>
            } 
            else if (searchParams.get('type') === 'timeline_entrepreneurship') {
                isInitRef.current = true
                let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
                if (data) {
                    entrepreneurshipRef.current = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                    isInitPageCharacterizationsRef.current = true
                }
                return showTimeline(entrepreneurshipRef.current.id)
            } 
            else if (searchParams.get('type') === 'show_entrepreneurship') {
                isInitRef.current = true
                let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
                if (data) {
                    entrepreneurshipRef.current = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                    isInitPageCharacterizationsRef.current = true
                    data = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                }
                return showBasicInfo(isInitRef, getEntrepreneurships, data, disabled, setDisabled)
            } 
            else if (searchParams.get('type') === 'new_characterization') {
                let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
                if (data) {
                    entrepreneurshipRef.current = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                    if (isInitPageCharacterizationsRef.current) {
                        getCharacterizations()
                        isInitPageCharacterizationsRef.current = false
                    }
                    return <CharacterizationForm
                        entrepreneurshipId={entrepreneurshipRef.current.id}
                        refresh={getCharacterizations}
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                } else {
                    return <EntrepreneurshipInfo
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                }
            } 
            else if (searchParams.get('type') === 'show_characterization') {
                let data = characterizations[parseInt(searchParams.get('pos') ?? '')]
                if (data) {
                    data = ObjFormat.snakeToCamelCase(data) as Characterization
                    if (isInitPageCharacterizationsRef.current) {
                        getCharacterizations()
                        isInitPageCharacterizationsRef.current = false
                    }
                    return <CharacterizationShow
                        entrepreneurshipId={entrepreneurshipRef.current.id}
                        refresh={getCharacterizations}
                        data={data}
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                } else {
                    return <EntrepreneurshipInfo 
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                }
            } 
            else if (searchParams.get('type') === 'diagnostic') {                
                let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
                if (data) {
                    entrepreneurshipRef.current = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                    if (isInitPageCharacterizationsRef.current) {
                        getDiagnostics()
                        isInitPageCharacterizationsRef.current = false
                    }
                    let diagnostic = null
                    if (searchParams.get('pos')) {
                        diagnostic = diagnostics.find(row => row.id === (parseInt(searchParams.get('pos') ?? '')))
                    }
                    return <DiagnosticStatistic
                        diagnosticId={searchParams.get('pos')} 
                        getDiagnostics={getDiagnostics}
                        isAllowed={isAllowed(getRoles().edit)}
                    />
                } else {
                    return <EntrepreneurshipInfo
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                }
            } 
            else if (searchParams.get('type') === 'new_service') {
                let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
                if (data) {
                    entrepreneurshipRef.current = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                    if (isInitPageCharacterizationsRef.current) {
                        getServices()
                        isInitPageCharacterizationsRef.current = false
                    }
                    return <ServiceForm
                        entrepreneurshipId={entrepreneurshipRef.current.id}
                        refresh={getServices}  
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                }
            } 
            else if (searchParams.get('type') === 'show_service') {
                let data = services[parseInt(searchParams.get('pos') ?? '')]
                if (data) {
                    data = ObjFormat.snakeToCamelCase(data) as Service
                    if (isInitPageCharacterizationsRef.current) {
                        getServices()
                        isInitPageCharacterizationsRef.current = false
                    }
                    return <ServiceShow
                        entrepreneurshipId={entrepreneurshipRef.current.id}
                        refresh={getServices}  
                        data={data}  
                        isAllowed={isAllowed}
                        roles={getRoles()}
                    />
                }
            } 
            else if (searchParams.get('type') === 'new_tracking') {
                let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
                if (data) {
                    entrepreneurshipRef.current = ObjFormat.snakeToCamelCase(data) as Entrepreneurship
                    if (isInitPageCharacterizationsRef.current) {
                        getTrackings()
                        isInitPageCharacterizationsRef.current = false
                    }
                    return <TrackingForm
                        entrepreneurshipId={entrepreneurshipRef.current.id}
                        refresh={getTrackings}
                        isAllowed={isAllowed}
                        roles={getRoles()}  
                    />
                }
            } 
            else if (searchParams.get('type') === 'show_tracking') {
                let data = trackings[parseInt(searchParams.get('pos') ?? '')]
                if (data) {
                    if (isInitPageCharacterizationsRef.current) {
                        getTrackings()
                        isInitPageCharacterizationsRef.current = false
                    }
                    return <TrackingShow
                        data={data} 
                    />
                }
            }
            
            isInitPageCharacterizationsRef.current = true
            return <EntrepreneurshipInfo
                isAllowed={isAllowed}
                roles={getRoles()}
            />
        } catch (e) {
            return <EntrepreneurshipInfo
                isAllowed={isAllowed}
                roles={getRoles()}
            />
        }
    }

    React.useEffect(() => {
        if (searchParams.get('type') === 'timeline_entrepreneurship'
            || searchParams.get('type') === 'show_entrepreneurship'
            || searchParams.get('type') === 'new_characterization'
            || searchParams.get('type') === 'diagnostic'
            || searchParams.get('type') === 'new_service'
            || searchParams.get('type') === 'new_tracking') {
            let data = entrepreneurships.find(row => row.id === (parseInt(searchParams.get('item') ?? '')))
            if (data) setCurrentData({name: data?.name, code: data?.code})
        } 
    }, [searchParams.get('type')])

    const showBasicInfo = (isInitRef: any, getEntrepreneurships: any, data: any, disabled: any, setDisabled: any) => {
        return <CardForm elevation={0}>
            <CardHeader>
                <CardHeaderTitle>Información básica del Emprendimiento</CardHeaderTitle>
                {isAllowed(getRoles().edit) &&
                    <Tooltip title="Editar emprendimiento">
                        <EditIconButton onClick={toggleDisabled}>
                            {disabled ? <EditIcon/> : <VisibilityIcon/>}
                        </EditIconButton>
                    </Tooltip>
                }
                {isAllowed(getRoles().delete) &&
                    <Tooltip title="Inactivar emprendimiento">
                        <DeleteIconButton onClick={handleDelete}>
                            <DeleteIcon style={{ fontWeight: 'bold' }}/>
                        </DeleteIconButton>
                    </Tooltip>
                }
            </CardHeader>
            <Content>
                <TabsStyle
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="full width tabs example"
                    variant="fullWidth"
                >
                    {tabs.map((row: any, index: number) => {
                        return <TabStyle key={index} label={row.name}/>
                    })}
                </TabsStyle>
                <TabContainer>
                    <EntrepreneurshipShow
                        isInitRef={isInitRef}
                        refresh={getEntrepreneurships}
                        data={data}
                        disabled={disabled}
                        setDisabled={setDisabled}
                    />
                </TabContainer>
            </Content>
        </CardForm>
    }

    const showTimeline = (id: any) => {
        return <CardForm elevation={0}>
            <CardHeader>
                <CardHeaderTitle>Información básica del Emprendimiento</CardHeaderTitle>
                {isAllowed(getRoles().get) &&
                    <Tooltip title="Descargar PDF">
                        <PdfIcon onClick={downloadFile}/>
                    </Tooltip>
                }
            </CardHeader>
            <Content>
                <TabsStyle
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="full width tabs example"
                    variant="fullWidth"
                >
                    {tabs.map((row: any, index: number) => {
                        return <TabStyle key={index} label={row.name}/>
                    })}
                </TabsStyle>
                <TabContainer>
                    <EntrepreneurshipTimeline
                        entrepreneurshipId={id}
                    />
                </TabContainer>
            </Content>
        </CardForm>
    }

    const getEntrepreneurships = React.useCallback((search?: string | null) => {
        if (!search) search = null
        dispatch(activeLoading())
        entrepreneurshipApi.list(search).then((response) => {
            if (response?.status === 200) {
                setEntrepreneurships(response.data)
            }
            dispatch(deactivateLoading())
        })
    }, [entrepreneurshipApi, dispatch])

    const getCharacterizations = React.useCallback(() => {
        dispatch(activeLoading())
        characterizationApi.list(entrepreneurshipRef.current.id).then((response) => {
            if (response?.status === 200) {
                setCharacterizations(response.data)
            } else {
                setCharacterizations([])
            }
            dispatch(deactivateLoading())
        })
    }, [characterizationApi, dispatch, entrepreneurshipRef])

    const getServices = React.useCallback(() => {
        dispatch(activeLoading())
        serviceApi.findByEntrepreneurship(entrepreneurshipRef.current.id).then((response) => {
            if (response?.status === 200) {
                let resp = response.data
                let results = new Array(); 
                resp.map((obj: any) => {
                    if (obj.is_active) results.push(obj)
                })
                setServices(results)
            } else {
                setServices([])
            }
            dispatch(deactivateLoading())
        })
    }, [serviceApi, dispatch, entrepreneurshipRef])

    const getTrackings = React.useCallback(() => {
        dispatch(activeLoading())
        trackingApi.findByEntrepreneurship(entrepreneurshipRef.current.id).then((response) => {
            if (response?.status === 200) {
                setTrackings(response.data)
            } else {
                setTrackings([])
            }
            dispatch(deactivateLoading())
        })
    }, [trackingApi, dispatch, entrepreneurshipRef])
    
    const getDiagnostics = React.useCallback(() => {
        dispatch(activeLoading())
        diagnosticApi.findByEntrepreneurship(entrepreneurshipRef.current.id).then((response) => {
            if (response?.status === 200) {
                let data = ObjFormat.snakeToCamelCase(response.data)
                let result = Object.values(data)
                result.map((row: any) => {
                    row.diagnosticDate = `Diagnóstico ${getDate(row.miseDate, 'DD - MM - YYYY')}`
                    row.params = {item: searchParams.get('item'), detail: 'entrepreneurship', type: 'diagnostic', diagnostic: row.id}
                })
                setDiagnostics(result)
            }
            dispatch(deactivateLoading())
        })
    }, [diagnosticApi, dispatch, entrepreneurshipRef])

    const downloadFile = React.useCallback(() => {
        timelineApi.download(entrepreneurshipRef.current.id.toString()).then((response) => {
            if (response?.status === 200) {
                let anchor = window.document.createElement('a');
                anchor.href = window.URL.createObjectURL(response.data);
                anchor.download = 'Línea del tiempo.pdf';
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(anchor.href);
            }
        })
    }, [timelineApi, dispatch])

    const deleteEntrepreneurship = React.useCallback(() => {
        dispatch(activeLoading())
        entrepreneurshipApi.delete(entrepreneurshipRef.current.id.toString()).then((response) => {
            if (response?.status === 204) {
                setModal({
                    open: true,
                    title: 'Emprendimiento inactivado',
                    message: 'El emprendimiento ha sido inactivado satisfactoriamente.',
                    type: 'error',
                    onClick: () => handleClose(true)
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Ha ocurrido un problema inactivando el emprendimiento. Por favor, inténtelo de nuevo más tarde.',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
            dispatch(deactivateLoading())
        })
    }, [entrepreneurshipApi, dispatch, entrepreneurshipRef])

    const handleDelete = (event: any) => {
        setModal({...modal,
            open: true, 
            title: 'Inactivar emprendimiento',
            message: `¿Está seguro de que desea inactivar el emprendimiento: ${entrepreneurshipRef.current.name}?`,
            type: 'confirmation'
        })
    }

    const handleClose = (redirect?: boolean) => {
        setModal({...modal, open: false})
        if (redirect === true) {
            getEntrepreneurships()
            navigate(router.appEntrepreneurship)
        }
    }

    const getTitle = () => {
        if (searchParams.get('type') === 'new_characterization'
            || searchParams.get('type') === 'show_characterization') {
            return ['Información ampliada', 'Actualice la información', 'de su emprendimiento', 'characterization', 'true', '']
        }
        else if (searchParams.get('type') === 'new_service'
            || searchParams.get('type') === 'show_service') {
            return ['Servicios', 'Asignar nuevo', 'Servicio', 'service', 'true', '']
        }
        else if (searchParams.get('type') === 'new_tracking'
            || searchParams.get('type') === 'show_tracking') {
            return ['Seguimientos', 'Asignar nuevo', 'Seguimiento', 'tracking', 'true', '']
        }
        else if (searchParams.get('type') === 'show_entrepreneurship'
            || searchParams.get('type') === 'timeline_entrepreneurship') {
            return ['Opciones', 'Registra tu', 'Emprendimiento', 'entrepreneurship', 'false', 'buttonStyle']
        }
        else if (searchParams.get('type') === 'diagnostic') {
            return ['Diagnósticos', 'Crear nuevo', 'Diagnóstico', 'diagnostic', 'false', '']
        }
        
        return ['Emprendimientos', 'Registra tu', 'Emprendimiento', 'entrepreneurship', 'true', '']
    }

    const getList = () => {
        let itemParam = {}
        if (searchParams.get('item')) {
            itemParam = {item: searchParams.get('item'), detail: 'entrepreneurship'}
        }
        if (entrepreneurshipRef.current) {
            itemParam = {item: entrepreneurshipRef.current.id.toString(), detail: 'entrepreneurship'}
        }

        if (!searchParams.get('detail')) {
            return entrepreneurships.map((item, index) => ({
                key: item.id,
                title: item.name,
                description: `Código: ${item.code}`,
                params: {item: item.id, detail: 'entrepreneurship', type: 'show_entrepreneurship'}
            }))
        }
        if (searchParams.get('type') === 'new_characterization'
            || searchParams.get('type') === 'show_characterization') {
            return characterizations.map((item, index) => ({
                key: item.id,
                title: 'Información actualizada: ',
                description: getDate(item.modified),
                params: {...itemParam, type: 'show_characterization', pos: index}
            }))
        }
        else if (searchParams.get('type') === 'new_service'
            || searchParams.get('type') === 'show_service') {
            return services.map((item, index) => ({
                key: item.id,
                title: item.topic,
                description: item.institution?.name,
                date: getDate(item.created),
                params: {...itemParam, type: 'show_service', pos: index}
            }))
        }
        else if (searchParams.get('type') === 'new_tracking'
            || searchParams.get('type') === 'show_tracking') {
            return trackings.map((item, index) => ({
                key: item.id,
                title: item.user + '',
                description: item.institution,
                isSmall: true,
                date: getDate(item.created),
                params: {...itemParam, type: 'show_tracking', pos: index}
            }))
        }
        else if (searchParams.get('type') === 'new_entrepreneurship') {
            return entrepreneurships.map((item, index) => ({
                key: item.id,
                title: item.name,
                description: `Código: ${item.code}`,
                params: {...itemParam, type: 'show_entrepreneurship', pos: index}
            }))
        }
        else if (searchParams.get('type') === 'diagnostic') {
            return diagnostics.map((item) => ({
                key: item.id,
                title: item.diagnosticDate,
                description: item.phase?.name,
                params: {...itemParam, type: 'diagnostic', pos: item.id}
            }))
        }

        let listOptions = [
            {
                key: 1,
                title: 'información básica del',
                description: 'emprendimiento',
                type: 'show_entrepreneurship',
                params: {...itemParam, type: 'show_entrepreneurship'}
            },
            {
                key: 2,
                title: 'información ampliada del',
                description: 'emprendimiento',
                type: 'new_characterization',
                params: {...itemParam, type: 'new_characterization'}
            },
            {
                key: 3,
                title: 'diagnósticos del',
                description: 'emprendimiento',
                type: 'diagnostic',
                params: {...itemParam, type: 'diagnostic'}
            },
        ]

        if (isAllowed(getRoles().get)) {
            listOptions.push(
                {
                    key: 4,
                    title: 'asignación de',
                    description: 'servicios',
                    type: 'new_service',
                    params: {...itemParam, type: 'new_service'}
                },
                {
                    key: 5,
                    title: 'registro de',
                    description: 'seguimientos',
                    type: 'new_tracking',
                    params: {...itemParam, type: 'new_tracking'}
                },
            )
        }

        return listOptions
    }

    const isAllowed = (allowed: string[]) => {
        if (searchParams.get('type') === 'new_service' 
            || searchParams.get('type') === 'show_service'
            || searchParams.get('type') === 'new_tracking' 
            || searchParams.get('type') === 'show_tracking'
            || searchParams.get('type') === 'new_characterization' 
            || searchParams.get('type') === 'show_characterization'
            || searchParams.get('type') === 'show_entrepreneurship'
            || searchParams.get('type') === 'timeline_entrepreneurship'
            || searchParams.get('type') === 'diagnostic'
            || searchParams.get('type') === null) {
            if (auth.isRoleAllowed(allowed)) return true
            return false
        }
        return true
    }

    const getRoles = () => {
        if (searchParams.get('type') === 'new_service' || searchParams.get('type') === 'show_service') {
            return {
                get: [],
                create: ['Institucion'],
                edit: ['Institucion', 'Admin'],
                delete: ['Institucion', 'Admin']
            }
        }
        else if (searchParams.get('type') === 'new_tracking' || searchParams.get('type') === 'show_tracking') {
            return {
                get: [],
                create: ['Institucion', 'Admin', 'Consultor'],
                edit: ['Institucion', 'Admin', 'Consultor'],
                delete: []
            }
        }
        else if (searchParams.get('type') === 'new_characterization' || searchParams.get('type') === 'show_characterization') {
            return {
                get: [],
                create: ['Emprendedor'],
                edit: ['Emprendedor'],
                delete: []
            }
        }
        else if (searchParams.get('type') === 'show_entrepreneurship') {
            return {
                get: ['Institucion', 'Admin', 'Consultor'],
                create: [],
                edit: ['Emprendedor'],
                delete: ['Admin']
            }
        }
        else if (searchParams.get('type') === 'timeline_entrepreneurship') {
            return {
                get: ['Institucion', 'Admin', 'Consultor'],
                create: [],
                edit: [],
                delete: []
            }
        }
        else if (searchParams.get('type') === 'diagnostic') {
            return {
                get: [],
                create: [],
                edit: ['Consultor'],
                delete: []
            }
        }
        else {
            return {
                get: [],
                create: ['Emprendedor'],
                edit: [],
                delete: []
            }
        }
    }

    const hasBackOption = () => {
        return  searchParams.get('type') === 'new_characterization'
            || searchParams.get('type') === 'show_characterization'
            || searchParams.get('type') === 'diagnostic' 
            || searchParams.get('type') === 'new_service' 
            || searchParams.get('type') === 'show_service' 
            || searchParams.get('type') === 'new_tracking' 
            || searchParams.get('type') === 'show_tracking' 
            || searchParams.get('type') === 'timeline_entrepreneurship' 
            || searchParams.get('type') === 'show_entrepreneurship' 
    }

    const getDate = (date: any, format?: string) => {
        const d = new Date(date);
        if (format) return moment(d).format(format);
        return moment(d).format('DD/MM/YYYY'); 
    }

    React.useEffect(() => {
        if (isInitPageRef.current) {
            getEntrepreneurships()
            setDisabled(true)
            isInitPageRef.current = false
            setTabs([
                {name: 'Información básica'},
                {name: 'Línea del tiempo'},
            ])
        }
    }, [getEntrepreneurships])

    React.useEffect(() => {
        if (getTitle()[3] !== 'entrepreneurship') setValue(0)
    }, [getTitle])

    React.useEffect(() => {
        if (!searchParams.get('type')) setValue(0)
    }, [searchParams.get('type')])

    // Manage tabs
    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
        setParams(newValue)
    };

    const setParams = (value: number) => {
        let params = {}
        if (searchParams.get('item') && value === 0) {
            params = {item: searchParams.get('item'), detail: 'entrepreneurship', type: 'show_entrepreneurship'}
        }
        if (searchParams.get('item') && value === 1) {
            params = {item: searchParams.get('item'), detail: 'entrepreneurship', type: 'timeline_entrepreneurship'}
        }
        setSearchParams(params)
    }

    return (
        <>
            <Container 
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
            >
                <Grid item md={4} style={{paddingRight: '20px'}}>
                    <Expanded>
                        <ExpandedContent
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            {hasBackOption() ?
                                <BackListItem
                                    firstTitle={currentData.name ?? ''}
                                    secondTitle={'Código. ' + currentData.code ?? ''}
                                    back={
                                        searchParams.get('type') === 'timeline_entrepreneurship' 
                                        || searchParams.get('type') === 'show_entrepreneurship'
                                        ? false : true}
                                /> :
                                null}
                            <EntrepreneurshipsList
                                title={getTitle()[0]}
                                firstItemText={getTitle()[1]}
                                secondItemText={getTitle()[2]}
                                list={getList()}
                                type={getTitle()[3]}
                                roles={getRoles()}
                                show={getTitle()[4]}
                                buttonStyle={getTitle()[5]}
                                isAllowed={isAllowed}
                                searchBar={getTitle()[3] === 'entrepreneurship' && getTitle()[4] === 'true'}
                                cardHeight={hasBackOption()}
                                getEntrepreneurships={getEntrepreneurships}
                            />
                        </ExpandedContent>
                    </Expanded>
                </Grid>
                <Grid item md={8}>
                    {getForm()}
                </Grid>
            </Container>
            <ModalMessage
                open={modal.open}
                isConfirmation={modal.type === 'confirmation'}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton={modal.type === 'confirmation' ? 'Confirmar' : 'Aceptar'}
                labelButtonConfirm="Cancelar"
                onClick={modal.type === 'confirmation' ? deleteEntrepreneurship : modal.onClick}
                onClickConfirm={modal.onClick}
                icon={<StorefrontIcon style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default EntrepreneurshipPage
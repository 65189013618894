// main libraries
import React from "react";
import {Grid, SelectChangeEvent} from "@mui/material";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

// icons
import PersonIcon from "@mui/icons-material/Person";

// reducers
import {completed, selectUser} from "../../../reducers/user/userSlice";
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import TextField from "../../components/TextField";
import Section from "../../components/Section";
import CustomSelect from "../../components/CustomSelect";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";
import CheckboxField from "../../components/CheckboxField";
import ModalMessage from "../../components/ModalMessage";

// styles
import {ProfileButton} from "./styles";

// api
import DocumentApi from "../../../api/DocumentApi";
import StateApi from "../../../api/StateApi";
import CityApi from "../../../api/CityApi";
import GenderApi from "../../../api/GenderApi";
import SexApi from "../../../api/SexApi";
import AreaApi from "../../../api/AreaApi";
import MaritalStatusApi from "../../../api/MaritalStatusApi";
import EthnicityApi from "../../../api/EthnicityApi";
import EducationalLevelApi from "../../../api/EducationalLevelApi";
import OccupationApi from "../../../api/OccupationApi";
import LanguageApi from "../../../api/LanguageApi";
import LanguageLevelApi from "../../../api/LanguageLevelApi";
import SisbenApi from "../../../api/SisbenApi";
import EpsApi from "../../../api/EpsApi";
import DisabilityApi from "../../../api/DisabilityApi";
import ContactMeansApi from "../../../api/ContactMeansApi";
import TrainingInstitutionApi from "../../../api/TrainingInstitutionApi";
import VulnerablePopulationApi from "../../../api/VulnerablePopulationApi";
import HousingTenureApi from "../../../api/HousingTenureApi";
import SocialGroupApi from "../../../api/SocialGroupApi";
import HousingTypeApi from "../../../api/HousingTypeApi";
import UserApi from "../../../api/UserApi";

// models
import Document from "../../../models/Document";
import State from "../../../models/State";
import City from "../../../models/City";
import MaritalStatus from "../../../models/MaritalStatus";
import Ethnicity from "../../../models/Ethnicity";
import EducationalLevel from "../../../models/EducationalLevel";
import Occupation from "../../../models/Occupation";
import Language from "../../../models/Language";
import LanguageLevel from "../../../models/LanguageLevel";
import Disability from "../../../models/Disability";
import HousingType from "../../../models/HousingType";
import HousingTenure from "../../../models/HousingTenure";
import VulnerablePopulation from "../../../models/VulnerablePopulation";
import SocialGroup from "../../../models/SocialGroup";
import ContactMeans from "../../../models/ContactMeans";
import TrainingInstitution from "../../../models/TrainingInstitution";
import User from "../../../models/User";

// utils
import ObjFormat from "../../../utils/objFormat";
import Router from "../../../router";
import ModalProps from "../../../utils/modalProps";

interface Props {
    isDisabled: boolean
}

function ProfileForm({isDisabled}: Props) {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const navigate = useNavigate()
    const [isInit, setIsInit] = React.useState(true)
    const [form, setForm] = React.useState<any>({
        email: '',
        firstName: '',
        lastName: '',
        profile: {
            document: '',
            documentNumber: '',
            documentState: '',
            documentCity: '',
            gender: '',
            sex: '',
            birthDate: '',
            nationality: '',
            address: '',
            neighborhood: '',
            commune: '',
            stratum: '',
            area: '',
            phone: '',
            birthCity: '',
            city: '',
            maritalStatus: '',
            currentState: '',
            birthState: '',
            ethnicity: '',
            educationalLevel: '',
            profession: '',
            occupation: '',
            bilingual: false,
            language: '',
            language2: '',
            language3: '',
            languageLevel: '',
            languageLevel2: '',
            languageLevel3: '',
            sisben: false,
            sisbenGroup: '',
            eps: false,
            epsRegime: '',
            epsType: '',
            epsName: '',
            pension: false,
            pensionName: '',
            arl: false,
            arlName: '',
            hasDisability: false,
            disability: '',
            housingType: '',
            housingTenure: '',
            numPeopleLiving: '',
            hasPeopleCharge: false,
            numPeopleCharge: '',
            hasVulnerablePopulation: false,
            vulnerablePopulation: '',
            hasSocialGroup: false,
            socialGroup: '',
            authorizeContact: false,
            contactMeansList: [] as any,
            hasTraining: false,
            trainingInstitution: '',
            trainingInstitutionList: [] as any,
        }
    })
    const [error, setError] = React.useState<any>({
        email: '',
        firstName: '',
        lastName: '',
        profile: {
            document: '',
            documentNumber: '',
            documentState: '',
            documentCity: '',
            gender: '',
            sex: '',
            acceptTerms: false,
            birthDate: '',
            nationality: '',
            address: '',
            neighborhood: '',
            commune: '',
            stratum: '',
            area: '',
            phone: '',
            birthCity: '',
            city: '',
            maritalStatus: '',
            currentState: '',
            birthState: '',
            ethnicity: '',
            educationalLevel: '',
            profession: '',
            occupation: '',
            bilingual: '',
            language: '',
            language2: '',
            language3: '',
            languageLevel: '',
            languageLevel2: '',
            languageLevel3: '',
            sisben: '',
            sisbenGroup: '',
            eps: '',
            epsRegime: '',
            epsType: '',
            epsName: '',
            pension: '',
            pensionName: '',
            arl: '',
            arlName: '',
            hasDisability: '',
            disability: '',
            housingType: '',
            housingTenure: '',
            numPeopleLiving: '',
            hasPeopleCharge: '',
            numPeopleCharge: '',
            hasVulnerablePopulation: '',
            vulnerablePopulation: '',
            hasSocialGroup: '',
            socialGroup: '',
            authorizeContact: false,
            contactMeansList: '',
            hasTraining: '',
            trainingInstitution: '',
            trainingInstitutionList: ''
        }
    })
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    // init API
    const documentApi = DocumentApi.Instance
    const stateApi = StateApi.Instance
    const cityApi = CityApi.Instance
    const genderApi = GenderApi.Instance
    const sexApi = SexApi.Instance
    const areaApi = AreaApi.Instance
    const maritalStatusApi = MaritalStatusApi.Instance
    const ethnicityApi = EthnicityApi.Instance
    const educationalLevelApi = EducationalLevelApi.Instance
    const occupationApi = OccupationApi.Instance
    const languageApi = LanguageApi.Instance
    const languageLevelApi = LanguageLevelApi.Instance
    const sisbenApi = SisbenApi.Instance
    const epsApi = EpsApi.Instance
    const disabilityApi = DisabilityApi.Instance
    const housingTypeApi = HousingTypeApi.Instance
    const housingTenureApi = HousingTenureApi.Instance
    const vulnerablePopulationApi = VulnerablePopulationApi.Instance
    const socialGroupApi = SocialGroupApi.Instance
    const contactMeansApi = ContactMeansApi.Instance
    const trainingInstitutionApi = TrainingInstitutionApi.Instance
    const userApi = UserApi.Instance
    // list of select fields
    const [typeDocuments, setTypeDocuments] = React.useState<Document[]>([])
    const [states, setState] = React.useState<State[]>([])
    const [documentCities, setDocumentCities] = React.useState<City[]>([])
    const [genders, setGenders] = React.useState<string[]>([])
    const [sexes, setSexes] = React.useState<string[]>([])
    const [areas, setAreas] = React.useState<string[]>([])
    const [cities, setCities] = React.useState<City[]>([])
    const [birthCities, setBirthCities] = React.useState<City[]>([])
    const [maritalStatus, setMaritalStatus] = React.useState<MaritalStatus[]>([])
    const [ethnicities, setEthnicities] = React.useState<Ethnicity[]>([])
    const [educationalLevels, setEducationalLevels] = React.useState<EducationalLevel[]>([])
    const [occupations, setOccupations] = React.useState<Occupation[]>([])
    const [languages, setLanguages] = React.useState<Language[]>([])
    const [languageLevels, setLanguageLevels] = React.useState<LanguageLevel[]>([])
    const [sisbenGroups, setSisbenGroups] = React.useState<string[]>([])
    const [epsRegimes, setEpsRegimes] = React.useState<string[]>([])
    const [epsTypes, setEpsTypes] = React.useState<string[]>([])
    const [disabilities, setDisabilities] = React.useState<Disability[]>([])
    const [housingTypes, setHousingTypes] = React.useState<HousingType[]>([])
    const [housingTenures, setHousingTenures] = React.useState<HousingTenure[]>([])
    const [vulnerablePopulations, setVulnerablePopulations] = React.useState<VulnerablePopulation[]>([])
    const [socialGroups, setSocialGroups] = React.useState<SocialGroup[]>([])
    const [contactMeans, setContactMeans] = React.useState<ContactMeans[]>([])
    const [trainingInstitutions, setTrainingInstitutions] = React.useState<TrainingInstitution[]>([])
    const [answerList, ] = React.useState<any[]>([
        {value: true, name: 'Sí'},
        {value: false, name: 'No'},
    ])

    const getCities = React.useCallback(async (setStateHook: React.Dispatch<React.SetStateAction<City[]>>, stateId: number) => {
        cityApi.listByStateId(stateId).then((response) => {
            if (response?.status === 200) {
                setStateHook(response.data)
            }
        })
    }, [cityApi])

    const initData = React.useCallback(async function () {
        await documentApi.list().then((response: any) => {
            if (response?.status === 200) {
                setTypeDocuments(response.data)
            }
        })
        await stateApi.listByCountryId(1).then((response) => {
            if (response?.status === 200) {
                setState(response.data)
            }
        })
        await genderApi.list().then((response) => {
            if (response?.status === 200) {
                setGenders(response.data)
            }
        })
        await sexApi.list().then((response) => {
            if (response?.status === 200) {
                setSexes(response.data)
            }
        })
        await areaApi.list().then((response) => {
            if (response?.status === 200) {
                setAreas(response.data)
            }
        })
        await maritalStatusApi.list().then((response) => {
            if (response?.status === 200) {
                setMaritalStatus(response.data)
            }
        })
        await ethnicityApi.list().then((response) => {
            if (response?.status === 200) {
                setEthnicities(response.data)
            }
        })
        await educationalLevelApi.list().then((response) => {
            if (response?.status === 200) {
                setEducationalLevels(response.data)
            }
        })
        await occupationApi.list().then((response) => {
            if (response?.status === 200) {
                setOccupations(response.data)
            }
        })
        await getCities(setDocumentCities, user?.profile?.documentCity?.state?.id ?? 1)
        await getCities(setBirthCities, user?.profile?.birthCity?.state?.id ?? 1)
        await getCities(setCities, user?.profile?.city?.state?.id ?? 1)
        await languageApi.list().then((response) => {
            if (response?.status === 200) {
                setLanguages(response.data)
            }
        })
        await languageLevelApi.list().then((response) => {
            if (response?.status === 200) {
                setLanguageLevels(response.data)
            }
        })
        await sisbenApi.list().then((response) => {
            if (response?.status === 200) {
                setSisbenGroups(response.data)
            }
        })
        await epsApi.RegimeList().then((response) => {
            if (response?.status === 200) {
                setEpsRegimes(response.data)
            }
        })
        await epsApi.TypesList().then((response) => {
            if (response?.status === 200) {
                setEpsTypes(response.data)
            }
        })
        await disabilityApi.list().then((response) => {
            if (response?.status === 200) {
                setDisabilities(response.data)
            }
        })
        await housingTypeApi.list().then((response) => {
            if (response?.status === 200) {
                setHousingTypes(response.data)
            }
        })
        await housingTenureApi.list().then((response) => {
            if (response?.status === 200) {
                setHousingTenures(response.data)
            }
        })
        await vulnerablePopulationApi.list().then((response) => {
            if (response?.status === 200) {
                setVulnerablePopulations(response.data)
            }
        })
        await socialGroupApi.list().then((response) => {
            if (response?.status === 200) {
                setSocialGroups(response.data)
            }
        })
        await contactMeansApi.list().then((response) => {
            if (response?.status === 200) {
                setContactMeans(response.data)
            }
        })
        await trainingInstitutionApi.list().then((response) => {
            if (response?.status === 200) {
                setTrainingInstitutions(response.data)
            }
        })
    }, [
        documentApi,
        stateApi,
        genderApi,
        sexApi,
        areaApi,
        maritalStatusApi,
        ethnicityApi,
        educationalLevelApi,
        occupationApi,
        languageApi,
        languageLevelApi,
        sisbenApi,
        epsApi,
        disabilityApi,
        housingTypeApi,
        housingTenureApi,
        vulnerablePopulationApi,
        socialGroupApi,
        contactMeansApi,
        trainingInstitutionApi,
        getCities,
        user
    ])

    const initForm = React.useCallback(function () {
        setTimeout(function () {
            setForm({
                email: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profile: {
                    document: user?.profile?.document?.id,
                    documentNumber: user?.profile?.documentNumber,
                    documentState: user?.profile?.documentCity?.state?.id,
                    documentCity: user?.profile?.documentCity?.id,
                    gender: user?.profile?.gender,
                    sex: user?.profile?.sex,
                    birthDate: user?.profile?.birthDate,
                    nationality: user?.profile?.nationality,
                    address: user?.profile?.address,
                    neighborhood: user?.profile?.neighborhood,
                    commune: user?.profile?.commune,
                    stratum: user?.profile?.stratum,
                    area: user?.profile?.area,
                    phone: user?.profile?.phone,
                    birthCity: user?.profile?.birthCity?.id,
                    currentState: user?.profile?.city?.state?.id,
                    city: user?.profile?.city?.id,
                    maritalStatus: user?.profile?.maritalStatus?.id,
                    birthState: user?.profile?.birthCity?.state?.id,
                    ethnicity: user?.profile?.ethnicity?.id ?? '',
                    educationalLevel: user?.profile?.educationalLevel?.id ?? '',
                    profession: user?.profile?.profession ?? '',
                    occupation: user?.profile?.occupation?.id ?? '',
                    bilingual: user?.profile?.bilingual ?? '',
                    language: user?.profile?.language?.id ?? '',
                    language2: user?.profile?.language2?.id ?? '',
                    language3: user?.profile?.language3?.id ?? '',
                    languageLevel: user?.profile?.languageLevel?.id ?? '',
                    languageLevel2: user?.profile?.languageLevel2?.id ?? '',
                    languageLevel3: user?.profile?.languageLevel3?.id ?? '',
                    sisben: user?.profile?.sisben ?? false,
                    sisbenGroup: user?.profile?.sisbenGroup ?? '',
                    eps: user?.profile?.eps ?? false,
                    epsRegime: user?.profile?.epsRegime ?? '',
                    epsType: user?.profile?.epsType ?? '',
                    epsName: user?.profile?.epsName ?? '',
                    pension: user?.profile?.pension ?? false,
                    pensionName: user?.profile?.pensionName ?? '',
                    arl: user?.profile?.arl ?? false,
                    arlName: user?.profile?.arlName ?? '',
                    hasDisability: user?.profile?.hasDisability ?? false,
                    disability: user?.profile?.disability?.id ?? '',
                    housingType: user?.profile?.housingType?.id ?? '',
                    housingTenure: user?.profile?.housingTenure?.id ?? '',
                    numPeopleLiving: user?.profile?.numPeopleLiving ?? '',
                    hasPeopleCharge: user?.profile?.hasPeopleCharge ?? false,
                    numPeopleCharge: user?.profile?.numPeopleCharge ?? '',
                    hasVulnerablePopulation: user?.profile?.hasVulnerablePopulation ?? false,
                    vulnerablePopulation: user?.profile?.vulnerablePopulation ?? '',
                    hasSocialGroup: user?.profile?.hasSocialGroup ?? false,
                    socialGroup: user?.profile?.socialGroup?.id ?? '',
                    authorizeContact: user?.profile?.authorizeContact ?? false,
                    contactMeansList: user?.profile?.contactMeansList ? user.profile.contactMeansList.map(obj => {return obj.id}) : [] as any,
                    hasTraining: user?.profile?.hasTraining ?? false,
                    trainingInstitution: user?.profile?.trainingInstitution?.id ?? '',
                    trainingInstitutionList: user?.profile?.trainingInstitutionList ? user.profile.trainingInstitutionList.map(obj => {return obj.id}) : [] as any,
                }
            })
            dispatch(deactivateLoading())
            setIsInit(false)
        }, 1000)
    }, [setForm, user, dispatch])

    const initDataAsync = React.useCallback(function () {
        dispatch(activeLoading())
        initData().then(() => {
            initForm()
        })
    }, [initData, initForm, dispatch])

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            email: '',
            firstName: '',
            lastName: '',
            profile: {
                document: '',
                documentNumber: '',
                documentState: '',
                documentCity: '',
                gender: '',
                sex: '',
                acceptTerms: false,
                birthDate: '',
                nationality: '',
                address: '',
                neighborhood: '',
                commune: '',
                stratum: '',
                area: '',
                phone: '',
                birthCity: '',
                city: '',
                maritalStatus: '',
                currentState: '',
                birthState: '',
                ethnicity: '',
                educationalLevel: '',
                profession: '',
                occupation: '',
                bilingual: '',
                language: '',
                language2: '',
                language3: '',
                languageLevel: '',
                languageLevel2: '',
                languageLevel3: '',
                sisben: '',
                sisbenGroup: '',
                eps: '',
                epsRegime: '',
                epsType: '',
                epsName: '',
                pension: '',
                pensionName: '',
                arl: '',
                arlName: '',
                hasDisability: '',
                disability: '',
                housingType: '',
                housingTenure: '',
                numPeopleLiving: '',
                hasPeopleCharge: '',
                numPeopleCharge: '',
                hasVulnerablePopulation: '',
                vulnerablePopulation: '',
                hasSocialGroup: '',
                socialGroup: '',
                authorizeContact: false,
                contactMeansList: '',
                hasTraining: '',
                trainingInstitution: '',
                trainingInstitutionList: ''
            }
        }
        setError({...errorsAux, ...errors, profile: {...errorsAux.profile, ...errors?.profile}})
    }

    const redirect = () => {
        setModal({...modal, open: false})
        navigate(Router.appEntrepreneurship)
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        const data = ObjFormat.camelToSnakeCase({
            ...form,
            profile: {
                ...form.profile,
                numPeopleCharge: form.profile.numPeopleCharge === '' ? null : form.profile.numPeopleCharge
            }
        }) as User
        userApi.updateProfile(data).then((response) => {
            if (response?.status === 200) {
                dispatch(completed(ObjFormat.snakeToCamelCase(response.data) as User))
                setModal({
                    open: true,
                    title: user?.profile?.isCompleted ? 'Perfil actualizado' : 'Perfil de Usuario completado',
                    message: user?.profile?.isCompleted ? '¡Se ha actualizado el perfil correctamente!' : 'Ahora debes registrar tu EMPRENDIMIENTO',
                    type: user?.profile?.isCompleted ? 'success' : 'custom',
                    onClick: () => user?.profile?.isCompleted ? handleClose() : redirect()
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data)
                cleanErrors(resp)
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Hay errores en el formulario',
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado, por favor intentelo en unos minutos',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    const getYearsOld = () => {
        const birthDate = moment(form.profile.birthDate !== '' ? form.profile.birthDate : new Date())
        const now = moment()

        return `${now.diff(birthDate, 'years')} años`
    }

    const handleChange = (event: any) => {
        let array = [
            'profile.bilingual', 'profile.sisben', 'profile.eps', 'profile.pension', 'profile.arl', 
            'profile.hasDisability', 'profile.hasPeopleCharge', 'profile.hasVulnerablePopulation', 
            'profile.hasTraining', 'profile.hasSocialGroup', 'profile.authorizeContact'
        ] 
        if (array.includes(event.target.name) && (!event.target.value || event.target.checked === false)) {
            handleClear(event.target.name)
            return
        }

        let names: string = ''
        if (event.target.name.includes('.')) {
            names = event.target.name.split('.')
            if (event.target.type === 'checkbox') {
                setForm({...form, profile: {...form.profile, [names[1]]: event.target.checked}})
            } else {
                setForm({...form, profile: {...form.profile, [names[1]]: event.target.value}})
            }
        } else {
            setForm({...form, [event.target.name]: event.target.value})
        }

        if (event.target.name === 'profile.documentState') {
            setForm({
                ...form, profile: {
                    ...form.profile,
                    documentCity: '',
                    [names[1]]: event.target.value
                }
            })
            getCities(setDocumentCities, parseInt(event.target.value)).then(() => null)
        } else if (event.target.name === 'profile.currentState') {
            setForm({
                ...form, profile: {
                    ...form.profile,
                    city: '',
                    [names[1]]: event.target.value
                }
            })
            getCities(setCities, parseInt(event.target.value)).then(() => null)
        } else if (event.target.name === 'profile.birthState') {
            setForm({
                ...form, profile: {
                    ...form.profile,
                    birthCity: '',
                    [names[1]]: event.target.value
                }
            })
            getCities(setBirthCities, parseInt(event.target.value)).then(() => null)
        }
    }

    const handleClear = (name: any) => {
        let names = name.split('.')
        if (name === 'profile.bilingual') {
            setForm({...form,
                profile: {
                    ...form.profile,
                    language: '',
                    languageLevel: '',
                    language2: '',
                    languageLevel2: '',
                    language3: '',
                    languageLevel3: '',
                    [names[1]]: false
                } 
            })
        }
        else if (name === 'profile.sisben') {
            setForm({...form, profile: {...form.profile, sisbenGroup: '', [names[1]]: false}})
        }
        else if (name === 'profile.authorizeContact') {
            setForm({...form, profile: {...form.profile, contactMeansList: [] as any, [names[1]]: false}})
        }
        else if (name === 'profile.eps') {
            setForm({...form, 
                profile: {
                    ...form.profile, 
                    epsRegime: '',
                    epsType: '',
                    epsName: '',
                    [names[1]]: false
                }
            })
        }
        else if (name === 'profile.pension') {
            setForm({...form, profile: {...form.profile, pensionName: '', [names[1]]: false}})
        }
        else if (name === 'profile.hasSocialGroup') {
            setForm({...form, profile: {...form.profile, socialGroup: '', [names[1]]: false}})
        }
        else if (name === 'profile.arl') {
            setForm({...form, profile: {...form.profile, arlName: '', [names[1]]: false}})
        }
        else if (name === 'profile.hasDisability') {
            setForm({...form, profile: {...form.profile, disability: '', [names[1]]: false}})
        }
        else if (name === 'profile.hasPeopleCharge') {
            setForm({...form, profile: {...form.profile, numPeopleCharge: '', [names[1]]: false}})
        }
        else if (name === 'profile.hasVulnerablePopulation') {
            setForm({...form, profile: {...form.profile, vulnerablePopulation: '', [names[1]]: false}})
        }
        else if (name === 'profile.hasTraining') {
            setForm({...form, profile: {...form.profile, trainingInstitutionList: [] as any, [names[1]]: false}})
        }
    }
    
    const handleChangeSelect = (event: SelectChangeEvent<any>) => {
        const { target: { value, name } } = event;
        if (name.includes('.')) {
            let names = event.target.name.split('.')
            setForm({...form, profile: {...form.profile, [names[1]]: value}})
        }
    };

    const handleLoad = React.useCallback(() => {
        setModal({...modal, open: false})
        if (isInit) {
            initDataAsync()
        }
    }, [initDataAsync, isInit])

    React.useEffect(function () {
        if (!user?.profile?.isCompleted) {
            setModal({
                open: true,
                title: `¡Hola, ${user?.firstName}!`,
                message: `
                    <span style="font-size:0.9rem">
                    Te invitamos a completar tu perfil. 
                    <br>Para ello, presiona el <strong>botón verde con icono de lápiz</strong> para habilitar el formulario.</br> 
                    Ingresa tus datos.
                    <br>Guárdalos.</br>
                    ¡Y listo!
                    </span>
                `,
                type: 'info',
                onClick: () => handleLoad()
            })
            return
        }
        handleLoad()
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid 
                    spacing={2}
                    container  
                    direction="row"
                    alignItems="flex-end"
                >
                    <Grid item md={12}>
                        <Section name="Información Básica"/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="firstName"
                            name="firstName"
                            value={form.firstName}
                            onChange={handleChange}
                            label="Nombres"
                            required
                            error={error.firstName !== ''}
                            helperText={error.firstName[0] ?? ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="lastName"
                            name="lastName"
                            value={form.lastName}
                            onChange={handleChange}
                            label="Apellidos"
                            required
                            error={error.lastName !== ''}
                            helperText={error.lastName[0] ?? ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            label="Correo electrónico"
                            required
                            error={error.email !== ''}
                            helperText={error.email[0] ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Información del perfil"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.gender"
                            label="Género"
                            value={form.profile.gender}
                            onChange={handleChange}
                            required
                            disabled={isDisabled}
                            error={error.profile.gender !== ''}
                            helperText={error.profile.gender[0] ?? ''}
                            options={genders.map((item) => ({
                                    value: item, label: item
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.sex"
                            label="Sexo"
                            value={form.profile.sex}
                            onChange={handleChange}
                            required
                            disabled={isDisabled}
                            error={error.profile.sex !== ''}
                            helperText={error.profile.sex[0] ?? ''}
                            options={sexes.map((item) => ({
                                    value: item, label: item
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.document"
                            label="Tipo de identificación"
                            value={form.profile.document}
                            onChange={handleChange}
                            required
                            disabled={isDisabled}
                            error={error.profile.document !== ''}
                            helperText={error.profile.document[0] ?? ''}
                            options={typeDocuments.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.documentNumber"
                            name="profile.documentNumber"
                            label="Número de identificación"
                            value={form.profile.documentNumber}
                            type="number"
                            onChange={handleChange}
                            required
                            disabled={isDisabled}
                            error={error.profile.documentNumber !== ''}
                            helperText={error.profile.documentNumber[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.documentState"
                            value={form.profile.documentState}
                            label="Departamento de expedición"
                            required
                            disabled={isDisabled}
                            error={error.profile.documentState !== ''}
                            helperText={error.profile.documentState[0] ?? ''}
                            onChange={handleChange}
                            options={states.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.documentCity"
                            value={form.profile.documentCity}
                            label="Ciudad de expedición"
                            required
                            disabled={isDisabled}
                            error={error.profile.documentCity !== ''}
                            helperText={error.profile.documentNumber[0] ?? ''}
                            onChange={handleChange}
                            options={documentCities.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="birthDate"
                            name="profile.birthDate"
                            label="Fecha de nacimiento"
                            required
                            disabled={isDisabled}
                            error={error.profile.birthDate !== ''}
                            helperText={error.profile.birthDate[0] ?? ''}
                            type="date"
                            value={form.profile.birthDate}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.age"
                            name="profile.age"
                            value={getYearsOld()}
                            onChange={handleChange}
                            label="Edad"
                            required
                            disabled
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.birthState"
                            value={form.profile.birthState}
                            label="Departamento de nacimiento"
                            required
                            disabled={isDisabled}
                            error={error.profile.birthState !== ''}
                            helperText={error.profile.birthState[0] ?? ''}
                            onChange={handleChange}
                            options={states.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.birthCity"
                            value={form.profile.birthCity}
                            label="Ciudad de nacimiento"
                            required
                            disabled={isDisabled}
                            error={error.profile.birthCity !== ''}
                            helperText={error.profile.birthCity[0] ?? ''}
                            onChange={handleChange}
                            options={birthCities.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>                    
                    <Grid item md={6}>
                        <TextField
                            id="profile.phone"
                            name="profile.phone"
                            value={form.profile.phone}
                            onChange={handleChange}
                            label="Teléfono"
                            required
                            disabled={isDisabled}
                            error={error.profile.phone !== ''}
                            helperText={error.profile.phone[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.maritalStatus"
                            value={form.profile.maritalStatus}
                            label="Estado civil"
                            required
                            disabled={isDisabled}
                            error={error.profile.maritalStatus !== ''}
                            helperText={error.profile.maritalStatus[0] ?? ''}
                            onChange={handleChange}
                            options={maritalStatus.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.ethnicity"
                            value={form.profile.ethnicity}
                            label="Etnia"
                            required
                            disabled={isDisabled}
                            error={error.profile.ethnicity !== ''}
                            helperText={error.profile.ethnicity[0] ?? ''}
                            onChange={handleChange}
                            options={ethnicities.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.educationalLevel"
                            value={form.profile.educationalLevel}
                            label="Nivel de estudios"
                            required
                            disabled={isDisabled}
                            error={error.profile.educationalLevel !== ''}
                            helperText={error.profile.educationalLevel[0] ?? ''}
                            onChange={handleChange}
                            options={educationalLevels.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.profession"
                            name="profile.profession"
                            label="¿Cuál es su profesión u oficio actualmente?"
                            value={form.profile.profession}
                            onChange={handleChange}
                            required
                            disabled={isDisabled}
                            error={error.profile.profession !== ''}
                            helperText={error.profile.profession[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.occupation"
                            label="¿Cuál es su ocupación principal?"
                            value={form.profile.occupation}
                            onChange={handleChange}
                            required
                            disabled={isDisabled}
                            error={error.profile.occupation !== ''}
                            helperText={error.profile.occupation[0] ?? ''}
                            options={occupations.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Ubicación"/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.nationality"
                            name="profile.nationality"
                            value={form.profile.nationality}
                            label="Nacionalidad"
                            required
                            disabled={isDisabled}
                            error={error.profile.nationality !== ''}
                            helperText={error.profile.nationality[0] ?? ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.address"
                            name="profile.address"
                            value={form.profile.address}
                            onChange={handleChange}
                            label="Dirección de residencia"
                            required
                            disabled={isDisabled}
                            error={error.profile.address !== ''}
                            helperText={error.profile.address[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.neighborhood"
                            name="profile.neighborhood"
                            value={form.profile.neighborhood}
                            onChange={handleChange}
                            label="Barrio/Vereda"
                            required
                            disabled={isDisabled}
                            error={error.profile.neighborhood !== ''}
                            helperText={error.profile.neighborhood[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.commune"
                            name="profile.commune"
                            value={form.profile.commune}
                            onChange={handleChange}
                            label="Comuna/Corregimiento"
                            required
                            disabled={isDisabled}
                            error={error.profile.commune !== ''}
                            helperText={error.profile.commune[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.stratum"
                            name="profile.stratum"
                            value={form.profile.stratum}
                            onChange={handleChange}
                            label="Estrato"
                            required
                            disabled={isDisabled}
                            error={error.profile.stratum !== ''}
                            helperText={error.profile.stratum[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.area"
                            value={form.profile.area}
                            onChange={handleChange}
                            label="Área geográfica"
                            required
                            disabled={isDisabled}
                            error={error.profile.area !== ''}
                            helperText={error.profile.area[0] ?? ''}
                            options={areas.map((item) => ({
                                    value: item, label: item
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.currentState"
                            value={form.profile.currentState}
                            label="Departamento de residencia"
                            required
                            disabled={isDisabled}
                            error={error.profile.currentState !== ''}
                            helperText={error.profile.currentState[0] ?? ''}
                            onChange={handleChange}
                            options={states.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.city"
                            value={form.profile.city}
                            label="Ciudad de residencia"
                            required
                            disabled={isDisabled}
                            error={error.profile.city !== ''}
                            helperText={error.profile.city[0] ?? ''}
                            onChange={handleChange}
                            options={cities.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Idioma"/>
                    </Grid>
                    <Grid item md={6}>                        
                        <CustomSelect
                            name="profile.bilingual"
                            value={form.profile.bilingual}
                            label="¿Domina un segundo idioma?"
                            error={error.profile.bilingual !== ''}
                            helperText={error.profile.bilingual[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}></Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.language"
                            value={form.profile.language}
                            label="Si su respuesta es sí, cuéntenos qué idioma"
                            required={isDisabled || form.profile.bilingual}
                            disabled={isDisabled || !form.profile.bilingual}
                            error={error.profile.language !== ''}
                            helperText={error.profile.language[0] ?? ''}
                            onChange={handleChange}
                            options={languages.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.languageLevel"
                            value={form.profile.languageLevel}
                            label="¿El idioma seleccionado, en qué nivel se encuentra?"
                            required={isDisabled || form.profile.bilingual}
                            disabled={isDisabled || !form.profile.bilingual}
                            error={error.profile.languageLevel !== ''}
                            helperText={error.profile.languageLevel[0] ?? ''}
                            onChange={handleChange}
                            options={languageLevels.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.language2"
                            value={form.profile.language2}
                            label="Segundo idioma"
                            disabled={isDisabled || !form.profile.bilingual}
                            error={error.profile.language2 !== ''}
                            helperText={error.profile.language2[0] ?? ''}
                            onChange={handleChange}
                            options={languages.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.languageLevel2"
                            value={form.profile.languageLevel2}
                            label="¿En qué nivel se encuentra el segundo idioma?"
                            required={form.profile.language2}
                            disabled={isDisabled || !form.profile.bilingual}
                            error={error.profile.languageLevel2 !== ''}
                            helperText={error.profile.languageLevel2[0] ?? ''}
                            onChange={handleChange}
                            options={languageLevels.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.language3"
                            value={form.profile.language3}
                            label="Tercer idioma"
                            disabled={isDisabled || !form.profile.bilingual}
                            error={error.profile.language3 !== ''}
                            helperText={error.profile.language3[0] ?? ''}
                            onChange={handleChange}
                            options={languages.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.languageLevel3"
                            value={form.profile.languageLevel3}
                            label="¿En qué nivel se encuentra el tercer idioma?"
                            required={form.profile.language3}
                            disabled={isDisabled || !form.profile.bilingual}
                            error={error.profile.languageLevel3 !== ''}
                            helperText={error.profile.languageLevel3[0] ?? ''}
                            onChange={handleChange}
                            options={languageLevels.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="SISBEN"/>
                    </Grid>
                    <Grid item md={6}>                 
                        <CustomSelect
                            name="profile.sisben"
                            value={form.profile.sisben}
                            label="¿Está inscrito en el SISBEN?"
                            error={error.profile.sisben !== ''}
                            helperText={error.profile.sisben[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.sisbenGroup"
                            value={form.profile.sisbenGroup}
                            label="¿Si estás inscrito en el SISBEN, a cuál grupo perteneces?"
                            required={isDisabled || form.profile.sisben}
                            disabled={isDisabled || !form.profile.sisben}
                            error={error.profile.sisbenGroup !== ''}
                            helperText={error.profile.sisbenGroup[0] ?? ''}
                            onChange={handleChange}
                            options={sisbenGroups.map((item) => ({
                                    value: item, label: item
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="EPS"/>
                    </Grid>
                    <Grid item md={6}>           
                        <CustomSelect
                            name="profile.eps"
                            value={form.profile.eps}
                            label="¿Está afiliado a algún régimen de salud?"
                            error={error.profile.eps !== ''}
                            helperText={error.profile.eps[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.epsRegime"
                            value={form.profile.epsRegime}
                            label="¿Cuál?"
                            required={isDisabled || form.profile.eps}
                            disabled={isDisabled || !form.profile.eps}
                            error={error.profile.epsRegime !== ''}
                            helperText={error.profile.epsRegime[0] ?? ''}
                            onChange={handleChange}
                            options={epsRegimes.map((item) => ({
                                    value: item, label: item
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.epsType"
                            value={form.profile.epsType}
                            label="Tipo de afiliación"
                            required={isDisabled || form.profile.eps}
                            disabled={isDisabled || !form.profile.eps}
                            error={error.profile.epsType !== ''}
                            helperText={error.profile.epsType[0] ?? ''}
                            onChange={handleChange}
                            options={epsTypes.map((item) => ({
                                    value: item, label: item
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.epsName"
                            name="profile.epsName"
                            value={form.profile.epsName}
                            label="Nombre de la EPS"
                            required={isDisabled || form.profile.eps}
                            disabled={isDisabled || !form.profile.eps}
                            error={error.profile.epsName !== ''}
                            helperText={error.profile.epsName[0] ?? ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Fondo de pensión"/>
                    </Grid>
                    <Grid item md={6}>       
                        <CustomSelect
                            name="profile.pension"
                            value={form.profile.pension}
                            label="¿Está afiliado algún fondo de pensión?"
                            error={error.profile.pension !== ''}
                            helperText={error.profile.pension[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.pensionName"
                            name="profile.pensionName"
                            value={form.profile.pensionName}
                            label="¿Cuál?"
                            required={isDisabled || form.profile.pension}
                            disabled={isDisabled || !form.profile.pension}
                            error={error.profile.pensionName !== ''}
                            helperText={error.profile.pensionName[0] ?? ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="ARL"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.arl"
                            value={form.profile.arl}
                            label="¿Está afiliado a alguna administradora de riesgos laborales?"
                            error={error.profile.arl !== ''}
                            helperText={error.profile.arl[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.arlName"
                            name="profile.arlName"
                            value={form.profile.arlName}
                            label="¿Cuál?"
                            required={isDisabled || form.profile.arl}
                            disabled={isDisabled || !form.profile.arl}
                            error={error.profile.arlName !== ''}
                            helperText={error.profile.arlName[0] ?? ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Discapacidad"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.hasDisability"
                            value={form.profile.hasDisability}
                            label="¿Presenta algún tipo de discapacidad permanente?"
                            error={error.profile.hasDisability !== ''}
                            helperText={error.profile.hasDisability[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.disability"
                            value={form.profile.disability}
                            label="¿Cuál?"
                            required={isDisabled || form.profile.hasDisability}
                            disabled={isDisabled || !form.profile.hasDisability}
                            error={error.profile.disability !== ''}
                            helperText={error.profile.disability[0] ?? ''}
                            onChange={handleChange}
                            options={disabilities.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Vivienda"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.housingType"
                            value={form.profile.housingType}
                            label="Tipo de vivienda"
                            required
                            disabled={isDisabled}
                            error={error.profile.housingType !== ''}
                            helperText={error.profile.housingType[0] ?? ''}
                            onChange={handleChange}
                            options={housingTypes.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.housingTenure"
                            value={form.profile.housingTenure}
                            label="Tenencia de la vivienda"
                            required
                            disabled={isDisabled}
                            error={error.profile.housingTenure !== ''}
                            helperText={error.profile.housingTenure[0] ?? ''}
                            onChange={handleChange}
                            options={housingTenures.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.numPeopleLiving"
                            name="profile.numPeopleLiving"
                            value={form.profile.numPeopleLiving}
                            label="Número de personas que habitan la vivienda"
                            type="number"
                            required
                            disabled={isDisabled}
                            error={error.profile.numPeopleLiving !== ''}
                            helperText={error.profile.numPeopleLiving[0] ?? ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Personas a cargo"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.hasPeopleCharge"
                            value={form.profile.hasPeopleCharge}
                            label="¿Tiene personas a cargo?"
                            error={error.profile.hasPeopleCharge !== ''}
                            helperText={error.profile.hasPeopleCharge[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            id="profile.numPeopleCharge"
                            name="profile.numPeopleCharge"
                            value={form.profile.numPeopleCharge}
                            label="¿Cuántas?"
                            type="number"
                            required={isDisabled || form.profile.hasPeopleCharge}
                            disabled={isDisabled || !form.profile.hasPeopleCharge}
                            error={error.profile.numPeopleCharge !== ''}
                            helperText={error.profile.numPeopleCharge[0] ?? ''}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Población vulnerable"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.hasVulnerablePopulation"
                            value={form.profile.hasVulnerablePopulation}
                            label="¿Pertenece a algún grupo de población vulnerable?"
                            error={error.profile.hasVulnerablePopulation !== ''}
                            helperText={error.profile.hasVulnerablePopulation[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.vulnerablePopulation"
                            value={form.profile.vulnerablePopulation}
                            label="¿Cuál?"
                            required={isDisabled || form.profile.hasVulnerablePopulation}
                            disabled={isDisabled || !form.profile.hasVulnerablePopulation}
                            error={error.profile.vulnerablePopulation !== ''}
                            helperText={error.profile.vulnerablePopulation[0] ?? ''}
                            onChange={handleChange}
                            options={vulnerablePopulations.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Grupo u organización"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.hasSocialGroup"
                            value={form.profile.hasSocialGroup}
                            label="¿Participa de algún grupo u organización?"
                            error={error.profile.hasSocialGroup !== ''}
                            helperText={error.profile.hasSocialGroup[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.socialGroup"
                            value={form.profile.socialGroup}
                            label="¿Cuál?"
                            required={isDisabled || form.profile.hasSocialGroup}
                            disabled={isDisabled || !form.profile.hasSocialGroup}
                            error={error.profile.socialGroup !== ''}
                            helperText={error.profile.socialGroup[0] ?? ''}
                            onChange={handleChange}
                            options={socialGroups.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Medio de contacto"/>
                    </Grid>
                    <Grid item md={6}>
                        <CheckboxField
                            name="profile.authorizeContact"
                            label="Autorizo recibir información por mensaje de texto, aplicaciones de mensajería instantánea (WhatsApp, Telegram, etc.) o por correo electrónico"
                            checked={form.profile.authorizeContact}
                            onChange={handleChange}
                            error={error.profile.authorizeContact !== ''}
                            helperText={error.profile.authorizeContact[0] ?? ''}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelectMultiple
                            name="profile.contactMeansList"
                            value={form.profile.contactMeansList}
                            label="¿Por cuál medio le gustaría que lo contactáramos?"
                            required={isDisabled || form.profile.authorizeContact}
                            disabled={isDisabled || !form.profile.authorizeContact}
                            error={error.profile.contactMeansList !== ''}
                            helperText={error.profile.contactMeansList[0] ?? ''}
                            onChange={handleChangeSelect}
                            options={contactMeans.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Section name="Formación/entrenamiento"/>
                    </Grid>
                    <Grid item md={6}>
                        <CustomSelect
                            name="profile.hasTraining"
                            value={form.profile.hasTraining}
                            label="¿Ha recibido formación/entrenamiento relacionada con emprendimiento?"
                            error={error.profile.hasTraining !== ''}
                            helperText={error.profile.hasTraining[0] ?? ''}
                            onChange={handleChange}
                            options={answerList.map((item) => ({
                                    value: item.value, label: item.name
                                })
                            ) as []}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item md={6}>                        
                        <CustomSelectMultiple
                            name="profile.trainingInstitutionList"
                            value={form.profile.trainingInstitutionList}
                            label="¿Si la respuesta es afirmativa nos podría indicar la institución?"
                            required={isDisabled || form.profile.hasTraining}
                            disabled={isDisabled || !form.profile.hasTraining}
                            error={error.profile.trainingInstitutionList !== ''}
                            helperText={error.profile.trainingInstitutionList[0] ?? ''}
                            onChange={handleChangeSelect}
                            options={trainingInstitutions.map((item) => ({
                                    value: item.id, label: item.name
                                })
                            ) as []}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <ProfileButton type="submit" disabled={isDisabled}>Actualizar</ProfileButton>
                    </Grid>
                </Grid>
            </form>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<PersonIcon style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default ProfileForm
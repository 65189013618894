import {styled} from "@mui/system";
import {Card, Divider, Typography} from "@mui/material";
import colors from "../../../utils/colors";

const getHeight = (cardHeight?: boolean, searchBar?: boolean): string => {
    if (searchBar) return '530px' 
    if (cardHeight) return '490px'
    return '490px'
}

const getListHeight = (cardHeight?: boolean, cardHeightButton?: boolean): string => {
    if (cardHeightButton) return '300px'
    if (cardHeight) return '290px'
    return '300px'
}

interface ItemProps {
    showScroll?: string
    cardHeight?: boolean
    cardHeightButton?: boolean
    searchBar?: boolean
}

export const ListContainer = styled('div')<ItemProps>(({showScroll, cardHeight, cardHeightButton}) => ({
    maxHeight: getListHeight(cardHeight, cardHeightButton),
    overflowY: showScroll === 'true' ? 'scroll' : 'hidden',
    flexGrow: 1,
    width: '100%',
    marginBottom: 20,
}))

export const CardContainer = styled(Card)<ItemProps>(({cardHeight, searchBar}) => ({
    width: '100%',
    height: getHeight(cardHeight, searchBar),
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '12px 18px',
    marginBottom: 20,
}))

export const AvatarTitle = styled(Typography)({
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 14,
    color: colors.text,
    margin: '5px 0 15px'
})

export const CustomDivider = styled(Divider)({
    borderColor: colors.text,
    margin: '15px 0'
})

export const EmptyContainer = styled('div')({
    marginTop: 25,
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})

export const EmptyIcon = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    fontSize: 40,
    color: colors.text,
    padding: 8,
})

export const TextEmpty = styled('div')({
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: colors.text,
    padding: 8,
})
import {Grid} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const Container = styled(Grid)({
    padding: '0 30px'
})

export const Expanded = styled(Grid)({
    padding: '20px 0 0',
})

export const ExpandedContent = styled(Grid)({
    height: '100%',
    minHeight: 'calc(100vh - 110px)'
})

export const List = styled('div')({
    width: '100%'
})

export const SummaryCard = styled('div')({
    width: '100%',
    background: colors.main,
    borderRadius: 8,
    padding: 10,
    textAlign: 'center'
})

export const Title = styled('p')({
    color: colors.second,
    fontWeight: 'bold',
    fontSize: 24,
    margin: 0,
    marginBottom: 2
})

export const Text = styled('p')({
    color: colors.whiteColor,
    margin: 0,
    fontSize: 12
})
// main libraries
import React from "react";
import {FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

// styles
import {CustomFormControl} from "./styles";

interface Options {
    value: string
    label: string
}

interface Target {
    name: string
    value: string
}

interface Event {
    target: Target
}

interface Props {
    options: Options[]
    value: string
    onChange: (event: Event) => void
    label: string
    name: string
    required?: boolean
    error?: boolean
    helperText?: string
}

function SelectField(props: Props) {
    const {value, onChange, label, name, options, required, error, helperText} = props

    const handleChange = (event: SelectChangeEvent) => {
        onChange({target: {name, value: event.target.value}});
    }

    return (
        <CustomFormControl variant="standard" required={required} error={error}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
                label={label}
            >
                <MenuItem value="" disabled>
                    <em>Seleccione una opción</em>
                </MenuItem>
                {options.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </CustomFormControl>
    )
}

export default SelectField
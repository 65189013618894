import { PictureAsPdf } from "@mui/icons-material";
import {Button, Card, Grid, IconButton, Typography} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const CardContainer = styled(Card)({
    width: '100%',
    height: '100%',
    flexGrow: 1,
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '30px 20px',
    marginBottom: 20,
    overflowY: 'inherit'
})

export const Container = styled(Grid)({
    padding: '0 30px'
})

export const Expanded = styled(Grid)({
    padding: '20px 0 0',
})

export const ExpandedContent = styled(Grid)({
})

export const List = styled('div')({
    width: '100%'
})

export const EmptyContainer = styled('div')({
    marginTop: 25,
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
})

export const EmptyIcon = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    fontSize: 60,
    color: colors.text,
    padding: 8,
})

export const TextEmpty = styled('div')({
    display: 'flex',
    textAlign: 'center',
    fontSize: 23,
    fontWeight: 500,
    color: colors.text,
    padding: 8,
})

export const Content = styled('div')({
    minHeight: 700,
    maxHeight: 750,
})

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 6,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 18,
    fontWeight: 700,
    flexGrow: 1
})

export const EditIconButton = styled(IconButton)({
    backgroundColor: colors.second,
    color: colors.whiteColor,
    '&:hover': {
        backgroundColor: 'rgb(47 221 146 / 85%)'
    }
})

export const PdfIcon = styled(PictureAsPdf)({
    color: colors.redColor,
    fontSize: 40,
    cursor: 'pointer'
})

export const ExportButton = styled(Button)({
    width: '35%',
    backgroundColor: colors.second,
    color: colors.whiteColor,
    borderRadius: 4,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    textTransform: 'uppercase',
    '&:hover': {
        backgroundColor: colors.text,
    },
    '&:disabled': {
        backgroundColor: 'rgb(16 56 91 / 76%)',
        color: 'rgba(255, 255, 255, 0.26)'
    }
})

export const SuccessButton = styled(Button)({
    width: '100%',
    height: 37,
    margin: '25px 20px',
    backgroundColor: colors.second,
    color: colors.whiteColor,
    borderRadius: 18,
    fontWeight: 600,
    textTransform: 'capitalize',
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: '#68f7b9',
    }
})

export const CloseButton = styled(Button)({
    width: '100%',
    height: 37,
    margin: '25px 20px',
    backgroundColor: '#d1d2d6',
    color: colors.whiteColor,
    borderRadius: 18,
    fontWeight: 600,
    textTransform: 'capitalize',
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.grayColor,
    }
})
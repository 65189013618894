export const CustomDate = {
    
    getMonthName: (num: number) => {
        let month = "";
        let months = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ]
        
        month = months[num - 1] ? months[num - 1] : 'Enero';
        return month
    },
}
// main libraries
import React from "react";
import {Grid} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import { Password } from "@mui/icons-material";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components & utils
import Router from "../../../router";
import ObjFormat from "../../../utils/objFormat";
import ModalProps from "../../../utils/modalProps";
import ModalMessage from "../../components/ModalMessage";
import TextField from "../../components/TextField";

//api
import SecurityApi from "../../../api/SecurityApi";

// styles
import {ResetPasswordButton, LoginLink} from "./styles";

interface ChangePassword {
    password: string
    passwordConfirmation: string
    token: string
}

function ChangePasswordForm() {
    const params = useParams()
    const [form, setForm] = React.useState({
        password: '',
        passwordConfirmation: '',
        token: '',
    })
    const [error, setError] = React.useState({
        password: '',
        passwordConfirmation: '',
        token: '',
    })
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const security = SecurityApi.Instance

    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            password: '',
            passwordConfirmation: '',
            token: '',
        }
        setError({...errorsAux, ...errors})
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        dispatch(activeLoading())

        const data = ObjFormat.camelToSnakeCase(form) as ChangePassword

        security.updatePassword(data).then((response) => {
            dispatch(deactivateLoading())
            if (response?.status === 200) {
                setModal({
                    open: true,
                    title: 'Contraseña actualizada',
                    message: 'La contraseña ha sido actualizada satisfactoriamente.',
                    type: 'success',
                    onClick: () => redirect()
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data) as ChangePassword
                cleanErrors(resp)

                let message = 'Hay errores en el formulario'
                if (resp?.token) message = resp.token
                setModal({
                    open: true,
                    title: 'Error',
                    message: message,
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado. Por favor, inténtelo en unos minutos.',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    function handleChange(event: any) {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    const redirect = () => {
        setModal({...modal, open: false})
        navigate(Router.appLogin)
    }

    React.useEffect(() => {
        if (!params?.token) navigate(Router.appLogin)
        else setForm({...form, token: params.token})
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid  
                    style={{marginTop: 15}}
                    spacing={2}
                    container  
                    direction="row"
                    alignItems="flex-end"
                >
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            name="password"
                            type="password"
                            value={form.password}
                            onChange={handleChange}
                            label="Contraseña"
                            required
                            error={error.password !== ''}
                            helperText={error.password[0] ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            type="password"
                            value={form.passwordConfirmation}
                            onChange={handleChange}
                            label="Confirmar contraseña"
                            required
                            error={error.passwordConfirmation !== ''}
                            helperText={error.passwordConfirmation[0] ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ResetPasswordButton variant="contained" type="submit" disableElevation>
                            GUARDAR
                        </ResetPasswordButton>
                    </Grid>
                    <LoginLink to={Router.appLogin}>{'Ir a inicio de sesión'}</LoginLink>
                </Grid>
            </form>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<Password style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default ChangePasswordForm
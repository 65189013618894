// eslint-disable-next-line import/no-anonymous-default-export
export default {
    main: '#2F86A6',
    second: '#2FDD92',
    dark: '#10375A',
    light: '#DFF1F7',
    text: '#6789CE',
    darkText: '#1A2947',
    grayText: '#868888',
    disabled: '#B6CCD861',
    notification: '#EEDD82',
    greenColor: '#FF4560',
    orangeColor: '#ffcb85',
    yellowColor: '#FEB019',
    redColor: '#FF4560',
    grayColor: '#EBECF0',
    disabledColor: 'rgba(0, 0, 0, 0.6)',
    whiteColor: '#FFF',
    blackColor: '#000',
}
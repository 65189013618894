import {Http} from "../utils/http";
import Router from "../router";

class TimelineApi {
    private static instance: TimelineApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async findByEntrepreneurship(id: string, page: number): Promise<any> {
        return this.http.get({
            route: Router.apiTimeline.replace(':id', id),
            hasToken: true,
            params: {page: page}
        })
    }

    public async download(id: string): Promise<any> {
        return this.http.getBinary({
            route: Router.apiTimelinePdf.replace(':id', id),
            hasToken: true,
        })
    }
}

export default TimelineApi
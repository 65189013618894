// icons
import AddCircleIcon from '@mui/icons-material/AddCircleOutline'

// styles
import {Background, CardForm, CardHeader, CardHeaderTitle, Container, Content, Text} from "./styles";
import {useSearchParams} from "react-router-dom";

interface Role {
    create: string[]
    edit: string[]
    delete: string[]
}

interface Props {
    isAllowed: (value: any) => boolean 
    roles: Role
}

function EntrepreneurshipInfo(props: Props) {
    const {isAllowed, roles} = props
    let [, setSearchParams] = useSearchParams();

    return (
        <CardForm elevation={0}>
            <CardHeader>
                <CardHeaderTitle>Información del Emprendimiento</CardHeaderTitle>
            </CardHeader>
            <Content>
                <Background>
                    <Container 
                        disabled={!isAllowed(roles.create)} 
                        onClick={() => !isAllowed(roles.create) ? null : setSearchParams({type: 'new_entrepreneurship'})}
                    >
                        <AddCircleIcon fontSize="inherit" style={{marginBottom: 10}}/>
                        <Text>Registra tu</Text>
                        <Text>emprendimiento</Text>
                    </Container>
                </Background>
            </Content>
        </CardForm>
    )
}

export default EntrepreneurshipInfo
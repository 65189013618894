import {FormHelperText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {BootstrapInput, CustomFormControl, CustomInputLabel} from "./styles";

interface Value {
    id: number
    name: string
}

interface Options {
    value: string
    label: string
}

interface Props {
    options: Options[]
    value: Value[]
    onChange: (event: SelectChangeEvent<any>) => void
    label: string
    name: string
    required?: boolean
    disabled?: boolean
    error?: boolean
    helperText?: string
}

function CustomSelect(props: Props) {
    const {value, onChange, label, name, options, required, error, helperText, disabled} = props

    const handleChange = (event: SelectChangeEvent<any>) => {
        onChange(event);
    }

    return (
        <CustomFormControl variant="standard" required={required} error={error} disabled={disabled}>
            <CustomInputLabel>{label}</CustomInputLabel>
            <Select
                name={name}
                multiple
                value={value}
                onChange={handleChange}
                input={<BootstrapInput/>}
            >
                {options.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </CustomFormControl>
    )
}

export default CustomSelect
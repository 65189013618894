// main libraries
import {useSearchParams} from "react-router-dom";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// styles
import {Content, EndIcon, IconContainer, Item, StartIcon, Text} from "./styles";

interface Props {
    icon: JSX.Element
    type: string
    firstTitle: string
    secondTitle: string
    thirdTitle?: string
    institutionTitle?: boolean
    buttonStyle: string
    params?: any
}

function CustomListItem(props: Props) {
    const {params, icon, type, firstTitle, secondTitle, thirdTitle, institutionTitle, buttonStyle} = props
    let [searchParams, setSearchParams] = useSearchParams();

    const isSelected = (): boolean => {
        if (searchParams.get('pos')) return searchParams.get('pos') === params?.pos?.toString();
        else if (searchParams.get('type')) {
            if (searchParams.get('type') === 'timeline_entrepreneurship' && params?.type?.toString() === 'show_entrepreneurship') return true
            return searchParams.get('type') === params?.type?.toString();
        }
        return searchParams.get('item') === params?.item?.toString();
    }

    return (
        <Item
            onClick={() => setSearchParams(params)}
            selected={isSelected()}
        >
            <StartIcon>
                <IconContainer color={type}>
                    {icon}
                </IconContainer>
            </StartIcon>
            <Content isbutton={!buttonStyle}>
                <Text style={{ fontWeight: 600 }}>{firstTitle}</Text>
                <Text small={institutionTitle ? true : false}>{secondTitle}</Text>
                {thirdTitle && <Text>{thirdTitle}</Text>}
            </Content>
            <EndIcon>
                <ChevronRightIcon style={{fontSize: 30}}/>
            </EndIcon>
        </Item>
    )
}

export default CustomListItem
import {Http} from "../utils/http";
import Router from "../router";

class StateApi {
    private static instance: StateApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async listByCountryId(countryId: number) {
        return this.http.get({
            route: Router.apiStates,
            params: {country: countryId}
        })
    }
}

export default StateApi
// main libraries
import React, { Fragment } from "react";
import {Grid, Tooltip} from "@mui/material";
import {useDispatch} from "react-redux";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import Section from "../../components/Section";
import TextField from "../../components/TextField";
import CustomSelect from "../../components/CustomSelect";
import ModalMessage from "../../components/ModalMessage";
import HourField from "../../components/HourField";

// models
import Institution from "../../../models/Institution";
import Service from "../../../models/Service";
import Phase from "../../../models/Phase";

// api
import ServiceApi from "../../../api/ServiceApi";
import PhaseApi from "../../../api/PhaseApi";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BallotIcon from "@mui/icons-material/Ballot";

// utils
import ModalProps from "../../../utils/modalProps";
import ObjFormat from "../../../utils/objFormat";
import moment from "moment";

// styles
import {CardForm, CardHeader, CardHeaderTitle, Content, SaveButton, EditIconButton, DeleteIconButton} from "./styles";

interface Role {
    create: string[]
    edit: string[]
    delete: string[]
}

interface Props {
    refresh: () => void
    entrepreneurshipId: number
    data: Service
    isAllowed: (value: any) => boolean 
    roles: Role
}

function ServiceShow(props: Props) {
    const {refresh, entrepreneurshipId, data, isAllowed, roles} = props
    const dispatch = useDispatch()

    const [form, setForm] = React.useState({
        topic: '',
        hoursTraining: '',
        hoursConsulting: '',
        entrepreneurship: '',
        phase: '',
        note: '',
        dateStart: '',
        dateEnd: '',
    })
    const [institution, setInstitution] = React.useState<Institution>({
        id: 0,
        name: ''
    })
    const [hours, setHours] = React.useState({
        hoursTraining: '00:00',
        hoursConsulting: '00:00',
    })
    const [error, setError] = React.useState({
        topic: '',
        hoursTraining: '',
        hoursConsulting: '',
        entrepreneurship: '',
        phase: '',
        note: '',
        dateStart: '',
        dateEnd: '',
    })
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    const [disabled, setDisabled] = React.useState<boolean>(true)

    // init API
    const serviceApi = ServiceApi.Instance
    const phaseApi = PhaseApi.Instance

    // list of select fields
    const [phases, setPhases] = React.useState<Phase[]>([])

    const toggleDisabled = () => setDisabled(!disabled)

    const init = React.useCallback(async () => {
        dispatch(activeLoading())

        await phaseApi.list().then((response) => {
            if (response?.status === 200) {
                setPhases(response.data)
            }
        })
    }, [dispatch, phaseApi])

    const initForm = React.useCallback(() => {
        setTimeout(function () {
            setForm({
                topic: data.topic,
                hoursTraining: data.hoursTraining,
                hoursConsulting: data.hoursConsulting,
                phase: data?.phase?.id.toString(),
                note: data.note,
                entrepreneurship: data.entrepreneurship.toString(),
                dateStart: data.dateStart ? data.dateStart : '',
                dateEnd: data.dateEnd ? data.dateEnd : '',
            })
            setInstitution(data.institution)
            setHours({
                hoursTraining: decimalToTime(data.hoursTraining),
                hoursConsulting: decimalToTime(data.hoursConsulting),
            })
            dispatch(deactivateLoading())
        }, 1000)
    }, [data, dispatch])

    React.useEffect(() => {
        init().then(() => {
            initForm()
            setDisabled(true)
        })
    }, [init, dispatch, initForm])
    
    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            topic: '',
            hoursTraining: '',
            hoursConsulting: '',
            entrepreneurship: '',
            phase: '',
            note: '',
            dateStart: '',
            dateEnd: '',
        }
        setError({...errorsAux, ...errors})
    }

    const clearForm = () => {
        setForm({
            topic: '',
            hoursTraining: '',
            hoursConsulting: '',
            entrepreneurship: '',
            phase: '',
            note: '',
            dateStart: '',
            dateEnd: '',
        })
        setHours({
            hoursTraining: '00:00',
            hoursConsulting: '00:00',
        })
    }

    const handleChange = (event: any) => {
        let e = event.target

        if (e.name === 'hoursConsulting' || e.name === 'hoursTraining') {
            // Replace all _ to 0
            let re = /_/gi
            e.value = e.value.replace(re, "0")

            // Check if minutes > 59
            let minutes = e.value.split(':')
            if (parseInt(minutes[1]) > 59) e.value = e.value.substring(0, 3) + '59'

            setForm({...form, [e.name]: timeToDecimal(e.value)})
            setHours({...hours, [e.name]: e.value})
        }
        else {
            setForm({...form, [e.name]: e.value})
        }
    }  

    const timeToDecimal = (t: string) => {
        let arr = t.split(':')
        let dec = (parseInt(arr[1]) / 6) * 10
    
        return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '' ) + dec);
    } 

    const decimalToTime = (t: string) => {    
        return moment().startOf('day').add(t, 'hours').format('HH:mm')
    } 

    const handleFocus = (event: any) => event.target.select();

    const refreshData = () => {
        setModal({...modal, open: false})
        refresh()
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        const info = ObjFormat.camelToSnakeCase(form) as Service

        serviceApi.update(data?.id.toString(), info).then((response) => {
            if (response?.status === 200) {
                clearForm()
                setModal({
                    open: true,
                    title: 'Servicio actualizado',
                    message: 'El servicio ha sido actualizado satisfactoriamente.',
                    type: 'custom',
                    onClick: () => refreshData()
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data)
                cleanErrors(resp)
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Hay errores en el formulario',
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado. Por favor, inténtelo de nuevo más tarde.',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    const handleDelete = (event: any) => {
        setModal({...modal,
            open: true, 
            title: 'Eliminar servicio',
            message: `¿Está seguro de que desea eliminar el servicio: ${data.topic}?`,
            type: 'confirmation'
        })
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    const handleConfirmDelete = () => {
        setModal({...modal, open: false})
        
        serviceApi.delete(data?.id.toString()).then((response) => {
            if (response?.status === 204) {
                setModal({
                    open: true,
                    title: 'Servicio eliminado',
                    message: 'El servicio ha sido eliminado satisfactoriamente.',
                    type: 'custom',
                    onClick: () => refreshData()
                })
            } else if (response?.status === 400) {
                if (response.data.detail) {
                    setModal({
                        open: true,
                        title: 'Error',
                        message: response.data.detail,
                        type: 'error',
                        onClick: () => handleClose()
                    })
                }
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Ha ocurrido un problema eliminando el servicio. Por favor, inténtelo de nuevo más tarde.',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    return (
        <>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>Descripción del servicio</CardHeaderTitle>
                    {isAllowed(roles.edit) &&
                        <Fragment>
                            <Tooltip title="Editar servicio">
                                <EditIconButton style={{ marginRight: 5 }} onClick={toggleDisabled}>
                                    {disabled ? <EditIcon/> : <VisibilityIcon/>}
                                </EditIconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar servicio">
                                <DeleteIconButton onClick={handleDelete}>
                                    <DeleteIcon/>
                                </DeleteIconButton>
                            </Tooltip>
                        </Fragment>
                    }
                </CardHeader>
                <Content>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <Section name="Servicio"/>
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="phase"
                                    value={form.phase}
                                    label="Etapa"
                                    required
                                    error={error.phase !== ''}
                                    helperText={error.phase[0] ?? ''}
                                    onChange={handleChange}
                                    options={phases.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="institution"
                                    name="institution"
                                    value={institution.name}
                                    onChange={handleChange}
                                    label="Institución proveedora del servicio"
                                    disabled
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    id="topic"
                                    name="topic"
                                    value={form.topic}
                                    onChange={handleChange}
                                    label="Temas"
                                    placeholder="Liste los temas ofrecidos en todo el programa"
                                    multiline
                                    maxRows={4}
                                    required
                                    error={error.topic !== ''}
                                    helperText={error.topic[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <HourField
                                    id="hoursTraining"
                                    name="hoursTraining"
                                    label="Horas de entrenamiento"
                                    value={hours.hoursTraining}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    required
                                    error={error.hoursTraining !== ''}
                                    helperText={error.hoursTraining[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={2}></Grid>
                            <Grid item md={4}>
                                <HourField
                                    id="hoursConsulting"
                                    name="hoursConsulting"
                                    label="Horas de consultoría"
                                    value={hours.hoursConsulting}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    required
                                    error={error.hoursConsulting !== ''}
                                    helperText={error.hoursConsulting[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={2}></Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="dateStart"
                                    name="dateStart"
                                    label="Fecha de inicio del programa"
                                    required
                                    disabled={disabled}
                                    error={error.dateStart !== ''}
                                    helperText={error.dateStart[0] ?? ''}
                                    type="date"
                                    value={form.dateStart}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="dateEnd"
                                    name="dateEnd"
                                    label="Fecha de terminación del programa"
                                    required
                                    disabled={disabled}
                                    error={error.dateEnd !== ''}
                                    helperText={error.dateEnd[0] ?? ''}
                                    type="date"
                                    value={form.dateEnd}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    id="note"
                                    name="note"
                                    value={form.note}
                                    onChange={handleChange}
                                    label="Objetivo del eje"
                                    placeholder="Describa el alcance o propósito del programa de su institución en esta etapa del emprendimiento"
                                    multiline
                                    maxRows={4}
                                    error={error.note !== ''}
                                    helperText={error.note[0] ?? ''}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <SaveButton type="submit" disabled={disabled}>Guardar</SaveButton>
                            </Grid>
                        </Grid>
                    </form>
                </Content>
            </CardForm>
            <ModalMessage
                open={modal.open}
                isConfirmation={modal.type === 'confirmation'}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton={modal.type === 'confirmation' ? 'Confirmar' : 'Aceptar'}
                labelButtonConfirm="Cancelar"
                onClick={modal.type === 'confirmation' ? handleConfirmDelete : modal.onClick}
                onClickConfirm={modal.onClick}
                icon={<BallotIcon style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default ServiceShow
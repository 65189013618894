import axios from 'axios'
import Router from '../router'
import {Auth} from './auth'

export class Http {
    private static instance: Http
    private auth = Auth.Instance
    private readonly url: string

    private constructor() {
        this.url = Router.apiUrlBase
    }

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    private getUrl(url?: string): string {
        if (url) {
            return url
        }

        return this.url
    }

    private header(hasToken?: boolean, isMultipart?: boolean): Record<string, string> {
        if (isMultipart) {
            return {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Token ' + this.auth.getToken()
            }
        }
        if (hasToken) {
            return {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + this.auth.getToken()
            }
        }

        return {
            'Content-Type': 'application/json',
        }
    }

    private static error(error: any) {
        if (error?.response?.status === 401) {
            window.location.href = Router.appLogin
        }
        // else if (error?.response?.status === 403) {
        //     const hash = window.btoa(
        //         unescape(encodeURIComponent(error.response?.data))
        //     )
        //     window.location.href = `${Router.appForbidden}?name=${hash}`
        // }

        return error.response
    }

    public async get(args: {
        route: string
        url?: string
        params?: {}
        hasToken?: boolean
    }): Promise<any> {
        return axios
            .get(this.getUrl(args.url) + args.route, {
                params: args.params,
                headers: this.header(args.hasToken)
            })
            .then((response) => {
                return response
            })
            .catch((error) => Http.error(error))
    }

    public async getFile(args: {
        route: string
        url?: string
        params: {}
        hasToken?: boolean
    }): Promise<any> {
        let params = new URLSearchParams(args.params).toString();
        return fetch(this.getUrl(args.url) + args.route + `?${params}`, {
            method: 'GET',
            headers: this.header(args.hasToken)
        })
        .then((response) => {
            if (response.status === 400) {
                return response.json()
            }
            return response; 
        })
        .then((data) => {
            return data; 
        })
        .catch((error) => {
            Http.error(error)
        })
    }

    public async getBinary(args: {
        route: string
        url?: string
        params?: {}
        hasToken?: boolean
    }): Promise<any> {
        return axios
            .get(this.getUrl(args.url) + args.route, {
                params: args.params,
                headers: this.header(args.hasToken),
                responseType: 'blob'
            })
            .then((response) => {
                return response
            })
            .catch((error) => Http.error(error))
    }

    public async post(args: {
        route: string
        url?: string
        data?: Object
        hasToken?: boolean
        isMultipart?: boolean
    }): Promise<any> {
        return axios
            .post(this.getUrl(args.url) + args.route, args.data, {
                headers: this.header(args.hasToken, args.isMultipart)
            })
            .then((response) => {
                return response
            })
            .catch((error) => Http.error(error))
    }

    public async put(args: {
        route: string
        url?: string
        data?: Object
        hasToken?: boolean
    }): Promise<any> {
        return axios
            .put(this.getUrl(args.url) + args.route, args.data, {
                headers: this.header(args.hasToken)
            })
            .then((response) => {
                return response
            })
            .catch((error) => Http.error(error))
    }

    public async patch(args: {
        route: string
        url?: string
        data?: Object
        hasToken?: boolean
    }): Promise<any> {
        return axios
            .patch(this.getUrl(args.url) + args.route, args.data, {
                headers: this.header(args.hasToken)
            })
            .then((response) => {
                return response
            })
            .catch((error) => Http.error(error))
    }

    public async delete(args: {
        route: string
        url?: string
        hasToken?: boolean
    }): Promise<any> {
        return axios
            .delete(this.getUrl(args.url) + args.route, {
                headers: this.header(args.hasToken)
            })
            .then((response) => {
                return response
            })
            .catch((error) => Http.error(error))
    }
}

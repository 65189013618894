import {styled} from "@mui/system";
import {Button, Card, IconButton, Typography} from "@mui/material";
import colors from "../../../utils/colors";

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 10,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 50,
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '0 30px'
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Content = styled('div')({
    padding: '30px 50px',
    overflowY: 'inherit',
    overflowX: 'hidden'
})

export const SaveButton = styled(Button)({
    width: '100%',
    height: 50,
    margin: '15px 0 5px 0',
    backgroundColor: colors.dark,
    color: colors.whiteColor,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    textTransform: 'uppercase',
    '&:hover': {
        backgroundColor: colors.text,
    },
    '&:disabled': {
        backgroundColor: 'rgb(16 56 91 / 76%)',
        color: 'rgba(255, 255, 255, 0.26)'
    }
})

export const EditIconButton = styled(IconButton)({
    backgroundColor: colors.second,
    color: colors.whiteColor,
    '&:hover': {
        backgroundColor: 'rgb(47 221 146 / 85%)'
    }
})
import {Http} from "../utils/http";
import Router from "../router";

class OccupationApi {
    private static instance: OccupationApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list() {
        return this.http.get({
            route: Router.apiOccupations,
            hasToken: true,
        })
    }
}

export default OccupationApi
import {TextField, TextFieldProps} from "@mui/material"
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const InputField = styled((props: TextFieldProps) => (
    <TextField
        {...props}
    />
))({
    width: '100%',
    marginTop: 10,
    '& label.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.6)',
    },
    '&:focus': {
        border: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        outline: 'none',
    },
    '& .MuiInput-underline:after': {
        borderBottom: `1px solid ${colors.dark}`,
    }
})
// main libraries
import { useState } from "react";
import {useSearchParams} from "react-router-dom";
import { Assignment, Ballot } from "@mui/icons-material";

// components
import ListItem from "../../components/ListItem";
import TextField from "../../components/TextField";
import CustomListItem from "../../components/CustomListItem";

// icons
import StorefrontIcon from "@mui/icons-material/Storefront";
import InfoIcon from "@mui/icons-material/Info";
import BallotIcon from "@mui/icons-material/Ballot";
import HomeRepairService from "@mui/icons-material/HomeRepairService";
import MyLocationIcon from '@mui/icons-material/MyLocation';

// styles
import {
    AvatarTitle,
    CardContainer,
    CustomDivider,
    EmptyContainer,
    EmptyIcon,
    TextEmpty,
    ListContainer
} from "./styles";

interface Role {
    create: string[]
    edit: string[]
    delete: string[]
}

interface Item {
    key: number,
    title: string
    secondTitle?: string
    description: string
    isSmall?: boolean
    type?: string
    date?: string
    params: any
}

interface Props {
    title: string
    firstItemText: string
    secondItemText: string
    list: Item[]
    type: string
    roles: Role
    show: string
    buttonStyle: string
    cardHeight: boolean
    searchBar: boolean
    isAllowed: (value: any) => boolean 
    getEntrepreneurships: (value: string) => void 
}

function EntrepreneurshipsList(props: Props) {
    const {title, firstItemText, secondItemText, list, type, roles, show, buttonStyle, cardHeight, searchBar, isAllowed, getEntrepreneurships} = props
    let [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('')

    const getIcon = () => {
        if (type === 'characterization') {
            return <BallotIcon fontSize="inherit"/>
        }
        else if (type === 'service') {
            return <HomeRepairService fontSize="inherit"/>
        }
        else if (type === 'tracking') {
            return <MyLocationIcon fontSize="inherit"/>
        }

        return <StorefrontIcon fontSize="inherit"/>
    }

    const getIconList = (icon?: string) => {
        if (icon === 'timeline_entrepreneurship') {
            return <StorefrontIcon style={{ color: "#FFF" }} fontSize="inherit"/>
        }
        else if (icon === 'new_characterization') {
            return <Ballot style={{ color: "#FFF" }} fontSize="inherit"/>
        }
        else if (icon === 'diagnostic') {
            return <Assignment style={{ color: "#FFF" }} fontSize="inherit"/>
        }
        else if (icon === 'new_service') {
            return <HomeRepairService style={{ color: "#FFF" }} fontSize="inherit"/>
        }
        else if (icon === 'new_tracking') {
            return <MyLocationIcon style={{ color: "#FFF" }} fontSize="inherit"/>
        }

        if (type === 'service') {
            return <HomeRepairService style={{ color: "#FFF" }} fontSize="inherit"/>
        }
        else if (type === 'tracking') {
            return <MyLocationIcon style={{ color: "#FFF" }} fontSize="inherit"/>
        }
        else if (type === 'diagnostic') {
            return <Assignment style={{ color: "#FFF" }} fontSize="inherit"/>
        }

        return <StorefrontIcon fontSize="inherit"/>
    }

    const getParams = () => {
        let item = {}
        if (searchParams.get('item')) {
            item = {item: searchParams.get('item')}
        }

        if (type === 'characterization') {
            return {...item, detail: 'entrepreneurship', type: 'new_characterization'}
        }
        else if (type === 'service') {
            return {...item, detail: 'entrepreneurship', type: 'new_service'}
        }
        else if (type === 'tracking') {
            return {...item, detail: 'entrepreneurship', type: 'new_tracking'}
        }

        return {...item, detail: 'entrepreneurship', type: 'new_entrepreneurship'}
    }

    function handleChange(event: any) {
        setSearch(event.target.value)
        if (event.target.value?.length > 2 || event.target.value === '') getEntrepreneurships(event.target.value)
    }

    return (
        <CardContainer cardHeight={cardHeight} searchBar={searchBar}>
            <AvatarTitle>{title}</AvatarTitle>
            {show === 'true' &&
                <ListItem
                    icon={getIcon()}
                    firstTitle={firstItemText}
                    secondTitle={secondItemText}
                    createdAction
                    disabled={!isAllowed(roles.create)}
                    onClick={() => isAllowed(roles.create) ? setSearchParams(getParams()) : null}
                />
            }
            {searchBar &&
                <TextField
                    id="search"
                    name="search"
                    type="search"
                    value={search}
                    onChange={handleChange}
                    label=""
                    placeholder="Buscar emprendimiento"
                />
            }
            <CustomDivider/>
            <ListContainer 
                style={{ marginTop: 20 }}
                showScroll={list.length > 4 && searchParams.get('type') !== 'timeline_entrepreneurship' && searchParams.get('type') !== 'show_entrepreneurship' ? 'true' : 'false'} 
                cardHeight={cardHeight} 
                cardHeightButton={show === 'true'}
            >
                {list.length > 0 ?
                    list.map((item) => (
                        <CustomListItem
                            key={item.key}
                            icon={getIconList(item.type)}
                            type={item.type || type}
                            firstTitle={item.title}
                            secondTitle={item.description}
                            institutionTitle={item.isSmall ? true : false}
                            thirdTitle={item.date}
                            buttonStyle={buttonStyle}
                            params={item.params}
                        />
                    ))
                    :
                    <EmptyContainer>
                        <EmptyIcon>
                            <InfoIcon fontSize="inherit"/>
                        </EmptyIcon>
                        <TextEmpty>No hay información para mostrar</TextEmpty>
                    </EmptyContainer>
                }
            </ListContainer>
        </CardContainer>
    )
}

export default EntrepreneurshipsList
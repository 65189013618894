import {styled} from "@mui/system";
import {Button, Card, Divider, Typography} from "@mui/material";
import colors from "../../../utils/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const getTextStyle = (type?: string): string[] => {
    if (type === 'subtitle') return [colors.text, '12', 'bold', 'left']
    else if (type === 'text') return [colors.blackColor, '12', 'normal', 'left']
    else if (type === 'main') return [colors.second, '40', 'bold', 'center']
    else if (type === 'header') return [colors.whiteColor, '12', 'normal', 'center']
    return [colors.blackColor, '12', 'bold', 'center']
}

const getStatistic = (value?: number): string => {
    if (!value) value = 0
    if (value <= 50) return colors.redColor
    else if (value > 50 && value <= 80) return colors.orangeColor
    else return colors.second
}

const getColor = (color?: string): string => {
    if (color === 'red') return colors.redColor
    else if (color === 'orange') return colors.orangeColor
    else return colors.second
}

interface ItemProps {
    type?: string
    auto?: string
    divider?: string
    top?: string
    size?: string
    right?: string
    color?: string
    value?: number
    phase?: string
}

export const Text = styled('span')<ItemProps>(({type}) => ({
    color: getTextStyle(type)[0],
    fontSize: parseInt(getTextStyle(type)[1]),
    fontWeight: getTextStyle(type)[2],
}))

export const Row = styled('div')<ItemProps>(({divider, top, right, auto}) => ({
    width: '100%',
    display: 'flex',
    borderBottom: divider ? `1px solid ${colors.text}` : 'none',
    paddingBottom: divider ? '1rem' : '',
    marginTop: top ? top : '',
    textAlign: right ? 'right' : 'left',
    margin: auto ? 'auto' : ''
}))

export const Column = styled('div')<ItemProps>(({auto, size}) => ({
    width: size ? `${size}%` : '50%',
    textAlign: auto ? 'center' : 'left',
    margin: 'auto',
}))

export const CustomDivider = styled(Divider)<ItemProps>(({phase}) => ({
    borderColor: phase === 'true' ? colors.text : colors.grayColor,
    margin: '0 16px 0 16px'
}))

export const CustomLinearProgress = styled(LinearProgress)<ItemProps>(({value}) => ({
    height: 20,
    borderRadius: 1,
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: getStatistic(value),
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors.grayColor,
    },
}))

export const Square = styled('div')<ItemProps>(({color}) => ({
    background: getColor(color),
    width: '.8rem',
    height: '.8rem',
    marginRight: 6
}))

export const CardForm = styled(Card)({
    width: '100%',
    borderRadius: 10,
    marginTop: 20,
})

export const CardHeader = styled('div')({
    height: 70,
    width: '100%',
    backgroundColor: colors.main,
    alignItems: 'center',
    color: colors.whiteColor,
    padding: '10px 30px',
})

export const CardHeaderTitle = styled(Typography)({
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1
})

export const Content = styled('div')({
    padding: '20px 40px',
})

export const Background = styled('div')({
    backgroundColor: colors.grayColor,
    borderRadius: 20,
    width: '100%',
    marginBottom: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
})

export const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: colors.text,
    fontSize: 80,
    border: `3px dashed ${colors.text}`,
    height: '70%',
    width: '78%',
    borderRadius: 10,
    transition: 'all 0.5s ease-in-out',
    cursor: 'pointer',
    '& p:last-child': {
        fontWeight: 600
    },
    '&:hover': {
        backgroundColor: colors.text,
        color: colors.whiteColor,
    },
    '&:hover & p': {
        color: colors.whiteColor,
    }
})

export const CustomVisibilityIcon = styled(VisibilityIcon)({
    color: colors.second,
})

export const SuccessButton = styled(Button)({
    width: '100%',
    height: 37,
    margin: '25px 20px',
    backgroundColor: colors.second,
    color: colors.whiteColor,
    borderRadius: 18,
    fontWeight: 600,
    textTransform: 'capitalize',
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: '#68f7b9',
    }
})

export const CancelButton = styled(Button)({
    width: '100%',
    height: 37,
    margin: '25px 20px',
    backgroundColor: '#d1d2d6',
    color: colors.whiteColor,
    borderRadius: 18,
    fontWeight: 600,
    textTransform: 'capitalize',
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.grayColor,
    }
})
import {alpha, styled} from "@mui/material/styles";
import {FormControl, InputBase, InputLabel} from "@mui/material";
import colors from "../../../utils/colors";

export const CustomFormControl = styled(FormControl)({
    width: '100%',
    '& label': {
        transform: 'translate(0, -1.5px) scale(0.75)',
        whiteSpace: 'break-spaces',
        position: 'inherit',
        minWidth: 340
    },
    'label+.MuiInputBase-root': {
        marginTop: 0
    },
    '& div, & div input': {
        width: '100%',
    },
    '& .Mui-error input': {
        border: '1px solid red'
    },
    '& .Mui-error.Mui-focused div': {
        boxShadow: `${alpha(colors.redColor, 0.25)} 0 0 0 0.2rem`,
        borderColor: colors.redColor,
    },
    '& .Mui-disabled div': {
        backgroundColor: colors.disabled,
        cursor: 'not-allowed',
        '-webkit-text-fill-color': colors.disabledColor,
    }
})

export const CustomInputLabel = styled(InputLabel)({
    fontSize: 14,
    fontWeight: 600,
    overflow: 'inherit',
    '&.Mui-focused': {
        color: colors.text
    }
})

export const BootstrapInput = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    }, '& label': {
        transform: 'translate(0, -1.5px) scale(0.75)'
    },
    '& .MuiInputBase-input': {
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(colors.text, 0.25)} 0 0 0 0.2rem`,
            borderColor: colors.text,
        }
    }
}))
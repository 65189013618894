import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'

// models
import User from "../../models/User";

// utils
import {Auth} from '../../utils/auth'

const auth = Auth.Instance

export interface UserState {
    value: User | null
}

const initialState: UserState = {
    value: auth.getUser()
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        authenticate: (state, action) => {
            state.value = action.payload
        },
        logout: (state) => {
            state.value = null
            auth.logout()
        },
        completed: (state, action) => {
            state.value = action.payload
            auth.profileIsCompleted(action.payload)
        }
    }
})

export const {authenticate, logout, completed} = userSlice.actions

export const selectUser = (state: RootState) => state.user.value

export default userSlice.reducer

// main libraries
import React from 'react';
import {useSearchParams} from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";
import { useDispatch } from 'react-redux';

// styles
import { CardForm, CardHeader, CardHeaderTitle, Content, Text, Row, Column, CustomLinearProgress, Square, CustomDivider, SuccessButton, CancelButton} from "./styles";

// models
import Phase from "../../../models/Phase";

// api
import DiagnosticApi from '../../../api/DiagnosticApi';
import PhaseApi from '../../../api/PhaseApi';

// components
import ArrowButton from '../../components/ArrowButton';
import SelectField from '../../components/SelectField';
import ModalMessage from "../../components/ModalMessage";

// utils
import ObjFormat from '../../../utils/objFormat';
import ModalProps from '../../../utils/modalProps';
import { Assignment } from '@mui/icons-material';
import Diagnostic from '../../../models/Diagnostic';

interface Props {
    diagnosticId: any
    getDiagnostics: () => void 
    isAllowed: boolean
}

function DiagnosticStatistic(props: Props) {
    const {diagnosticId, getDiagnostics, isAllowed} = props
    const dispatch = useDispatch();
    let [searchParams, ] = useSearchParams();

    const [detail, setDetail] = React.useState<any>({
        phase: "",
        miseConsultant: "",
        miseResult: 0,
        miseResults: []
    })
    const [open, setOpen] = React.useState(false)
    const [form, setForm] = React.useState<any>({
        phase: ''
    })
    const [phases, setPhases] = React.useState<Phase[]>([])
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleCloseModal()
    })
    const [type, setType] = React.useState<any>()

    // init API
    const diagnosticApi = DiagnosticApi.Instance
    const phaseApi = PhaseApi.Instance

    const getDiagnostic = React.useCallback((id) => {
        dispatch(activeLoading())
        diagnosticApi.find(id).then((response) => {
            if (response?.status === 200) {
                let resp = response.data
                let respFormat = ObjFormat.snakeToCamelCase(resp) as Diagnostic
                setDetail(respFormat)
            }
            dispatch(deactivateLoading())
        })
    }, [])

    const getPhases = React.useCallback(() => {
        dispatch(activeLoading())
        phaseApi.list().then((response) => {
            if (response?.status === 200) {
                setPhases(response.data)
            }
            dispatch(deactivateLoading())
        })
    }, [])

    const handleSave = React.useCallback((phase: any, id: any) => {
        if (!id) {
            setModal({
                open: true,
                title: '',
                message: 'Debe seleccionar un diagnóstico',
                type: 'warning',
                onClick: () => handleCloseModal()
            })
        }

        dispatch(activeLoading())
        let data = {phase: phase.phase}
        diagnosticApi.update(id, data).then((response) => {
            if (response?.status === 204) {
                getDiagnostics()
                getDiagnostic(id)
                setOpen(false)
                setModal({
                    open: true,
                    title: 'Etapa actualizada',
                    message: 'La etapa del diagnóstico se ha actualizado correctamente',
                    type: 'success',
                    onClick: () => handleCloseModal()
                })
                setForm({phase: ''})
            }
            dispatch(deactivateLoading())
        })
    }, [])

    const getRanks = () => {
        return ['0 - 5', '5,1 - 8', '8,1 - 10']; 
    }

    const getValue = (value: number) => {
        return value / 10; 
    }

    const handleChange = (event: any) => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const handleOpen = (value: boolean) => {
        setOpen(value)
    }

    const handleCloseModal = () => {
        setModal({...modal, open: false})
    }
    
    React.useEffect(() => {
        if (searchParams.get('diagnostic')) {
            getDiagnostic(searchParams.get('diagnostic'))
        }
        else if (searchParams.get('pos')) {
            getDiagnostic(searchParams.get('pos'))
        }
    }, [searchParams])
    
    React.useEffect(() => {
        if (diagnosticId) {
            getDiagnostic(diagnosticId);
        }
    }, [diagnosticId])
    
    React.useEffect(() => {
    }, [searchParams.get('diagnostic')])
    
    React.useEffect(() => {
        setType(searchParams.get('type'))
        getPhases()
    }, [])

    return (
        <>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>Diagnóstico del emprendimiento</CardHeaderTitle>
                    {type === 'detail' && 
                        <React.Fragment>
                            <Text type="header">Consultor: </Text><Text type="header">{detail.miseConsultant}</Text>
                        </React.Fragment>
                    }
                </CardHeader>
                <Content>
                    <Row>
                        <Column size="30">
                            <ArrowButton firstTitle="Asignar" secondTitle="ETAPA" disabled={!isAllowed} handleOpen={() => handleOpen(isAllowed)}/>
                        </Column>
                        <Column size="40">
                            <Text type="subtitle" style={{ margin: '0 20px', fontSize: 14 }}>{detail.phase?.name}</Text>
                            <CustomDivider phase={detail.phase?.name ? 'true' : 'false'}/>
                        </Column>
                        <Column auto="true" size="30">
                            <Text type="main">{detail.miseResult}%</Text>
                        </Column>
                    </Row>
                </Content>
            </CardForm>
            <CardForm elevation={0}>
                <Content style={{ minHeight: "360px" }}>
                    <Row right="true">
                        <Column size="65"></Column>
                        <Column size="10" auto="true">
                            <Row auto="true">
                                <Square color="red"/>
                                <Text type="text" style={{ fontSize: 11 }}>{getRanks()[0]}</Text>
                            </Row>
                        </Column>
                        <Column size="10" auto="true">
                            <Row auto="true">
                                <Square color="orange"/>
                                <Text type="text" style={{ fontSize: 11 }}>{getRanks()[1]}</Text>
                            </Row>
                        </Column>
                        <Column size="10" auto="true">
                            <Row auto="true">
                                <Square color="green"/>
                                <Text type="text" style={{ fontSize: 11 }}>{getRanks()[2]}</Text>
                            </Row>
                        </Column>
                        <Column size="5"></Column>
                    </Row>

                    <Row divider="true" top="1rem">
                        <Column auto="true">
                            <Text type="subtitle">Ejes</Text>
                        </Column>
                        <Column auto="true">
                            <Text type="subtitle">Resultados</Text>
                        </Column>
                    </Row>

                    <Row top=".5rem"></Row>
                    {detail.miseResults.map((item: any, index: number) => (
                        <Row top="1rem" key={index}>
                            <Column size="5"></Column>
                            <Column size="45">
                                <Text type="text">{item.axis}</Text>
                            </Column>
                            <Column auto="true" size="40">
                                <CustomLinearProgress variant="determinate" value={item.result}/>
                            </Column>
                            <Column size="10">
                                <Text style={{ marginLeft: 15 }} type="text">{getValue(item.result)}</Text>
                            </Column>
                        </Row>
                    ))}
                </Content>
            </CardForm>

            <Dialog open={open} onClose={() => handleOpen(false)} maxWidth='xs' fullWidth={true}> 
                <DialogContent>
                    <DialogContentText>
                        Asignar etapa
                    </DialogContentText>
                    <SelectField
                        name="phase"
                        value={form.phase}
                        label=""
                        onChange={handleChange}
                        options={phases.map((item) => ({
                                value: item.id, label: item.name
                            })
                        ) as []}
                    />
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => handleOpen(false)}>Cancelar</CancelButton>
                    <SuccessButton onClick={() => handleSave(form, searchParams.get('diagnostic') || searchParams.get('pos'))}>Guardar</SuccessButton>
                </DialogActions>
            </Dialog>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<Assignment style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default DiagnosticStatistic
import {Http} from "../utils/http";
import Router from "../router";
import Service from '../models/Service';

class ServiceApi {
    private static instance: ServiceApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async list(): Promise<any> {
        return this.http.get({
            route: Router.apiServices,
            hasToken: true
        })
    }

    public async find(id: string): Promise<any> {
        return this.http.get({
            route: Router.apiService.replace(':id', id),
            hasToken: true
        })
    }

    public async findByEntrepreneurship(id: string): Promise<any> {
        return this.http.get({
            route: Router.apiServicesByEntrepreneurship.replace(':id', id),
            hasToken: true
        })
    }

    public async create(data: Service): Promise<any> {
        return this.http.post({
            route: Router.apiServices,
            data: {...data},
            hasToken: true
        })
    }

    public async update(id: string, data: Service): Promise<any> {
        return this.http.patch({
            route: Router.apiService.replace(':id', id),
            data: {...data},
            hasToken: true
        })
    }

    public async delete(id: string): Promise<any> {
        return this.http.delete({
            route: Router.apiService.replace(':id', id),
            hasToken: true
        })
    }
}

export default ServiceApi
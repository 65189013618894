// main libraries
import React from "react";
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import Section from "../../components/Section";
import TextField from "../../components/TextField";
import ModalMessage from "../../components/ModalMessage";
import {useDropzone} from 'react-dropzone';

// icons
import BallotIcon from "@mui/icons-material/Ballot";

// api
import TrackingApi from "../../../api/TrackingApi";

// utils
import ModalProps from "../../../utils/modalProps";
import ObjFormat from "../../../utils/objFormat";

// styles
import {CardForm, CardHeader, CardHeaderTitle, Content, SaveButton, SectionDrop, Label} from "./styles";
import { CustomInputLabel } from "../../components/TextField/styles";

interface Role {
    create: string[]
    edit: string[]
    delete: string[]
}

interface Props {
    refresh: () => void
    entrepreneurshipId: number
    isAllowed: (value: any) => boolean 
    roles: Role
}

function TrackingForm(props: Props) {
    const {refresh, entrepreneurshipId, isAllowed, roles} = props
    const dispatch = useDispatch()

    const [form, setForm] = React.useState({
        description: '',
        file: [] as any,
        entrepreneurship: '',
    })
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const [error, setError] = React.useState({
        description: '',
        file: '',
    })
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    const [disabled, setDisabled] = React.useState<boolean>(true)

    // init API
    const trackingApi = TrackingApi.Instance

    const initForm = React.useCallback(() => {
        dispatch(activeLoading())
        setTimeout(function () {
            setForm({...form,
                entrepreneurship: entrepreneurshipId.toString(),
            })
            setDisabled(!isAllowed(roles.create))
            dispatch(deactivateLoading())
        }, 1000)
    }, [dispatch])

    React.useEffect(() => {
        initForm()
    }, [dispatch, initForm])
    
    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            description: '',
            file: '',
        }
        setError({...errorsAux, ...errors})
    }

    const clearForm = () => {
        setForm({
            description: '',
            file: [],
            entrepreneurship: '',
        })
        acceptedFiles.length = 0
    }

    const refreshData = () => {
        setModal({...modal, open: false})
        refresh()
    }

    const handleChange = (event: any) => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        
        let formData = new FormData()
        formData.append('description', form.description)
        formData.append('entrepreneurship', entrepreneurshipId.toString())
        acceptedFiles.map((file: any) => {
            formData.append('file', file)
        })

        trackingApi.create(formData).then((response) => {
            if (response?.status === 201) {
                clearForm()
                setModal({
                    open: true,
                    title: 'Seguimiento creado',
                    message: 'El seguimiento ha sido creado satisfactoriamente.',
                    type: 'custom',
                    onClick: () => refreshData()
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data)
                cleanErrors(resp)
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Hay errores en el formulario',
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado. Por favor, inténtelo de nuevo más tarde.',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    return (
        <>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>Registrar seguimiento</CardHeaderTitle>
                </CardHeader>
                <Content>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <Section name="Seguimiento"/>
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    id="description"
                                    name="description"
                                    value={form.description}
                                    onChange={handleChange}
                                    label="Observaciones"
                                    multiline
                                    maxRows={4}
                                    disabled={disabled}
                                    required
                                    error={error.description !== ''}
                                    helperText={error.description[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <CustomInputLabel shrink>Adjuntar archivos</CustomInputLabel>
                                <div className="container">
                                    <SectionDrop {...getRootProps()}>
                                        <input {...getInputProps()} disabled={disabled}/>
                                        <p>Arrastre y suelte aquí los archivos, o haga clic para seleccionarlos</p>
                                    </SectionDrop>
                                </div>
                                {acceptedFiles.length > 0 &&
                                    <aside>
                                        <h4>Archivos</h4>
                                        <ul>
                                            {acceptedFiles.map((file: any, index: number) => (
                                                <li key={index}>
                                                    {file.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </aside>
                                }
                            </Grid>
                            <Grid item md={4}>
                                <SaveButton type="submit" disabled={disabled}>Guardar</SaveButton>
                            </Grid>
                        </Grid>
                    </form>
                </Content>
            </CardForm>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<BallotIcon style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default TrackingForm
import {styled} from "@mui/system";
import {Button, Card} from "@mui/material";
import colors from "../../../utils/colors";

export const Container = styled('div')({
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(47 134 166 / 90%)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 500ms ease'
})

export const CustomCard = styled(Card)({
    width: 340,
    borderRadius: 10,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 20px',
    padding: 30
})

interface IconContainerProps {
    backgroundColor: string
}

export const IconContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'backgroundColor'
})<IconContainerProps>(({backgroundColor}) => ({
    width: 120,
    height: 120,
    backgroundColor: backgroundColor,
    borderRadius: 100,
    margin: '5px auto 20px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

export const Title = styled('div')({
    fontSize: 18,
    color: colors.dark,
    margin: '0 auto 15px auto',
    textAlign: 'center',
    fontWeight: 'bold',
})

export const Description = styled('div')({
    fontSize: 16,
    color: colors.dark,
    margin: '0 auto 15px auto',
    textAlign: 'center'
})

interface ItemProps {
    isConfirmation?: boolean
}

export const ConfirmButton = styled(Button)<ItemProps> (({isConfirmation}) => ({
    height: 60,
    width: '100%',
    backgroundColor: isConfirmation ? colors.whiteColor : colors.dark,
    border: isConfirmation ? `1px solid ${colors.dark}` : 'none',
    color: isConfirmation ? colors.dark : colors.whiteColor,
    margin: '25px 0 15px 0',
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: 8,
    fontWeight: 500,
    transition: 'all 0.3s ease-in 0s',
    '&:hover': {
        backgroundColor: colors.text,
    }
}))
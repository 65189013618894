// utils
import {Http} from '../utils/http'
import Router from "../router";

// models
import User from "../models/User";

class UserApi {
    private static instance: UserApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async updateProfile(user: User): Promise<any> {
        return this.http.patch({
            route: Router.apiUser,
            data: {...user},
            hasToken: true
        })
    }
}

export default UserApi
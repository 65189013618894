// utils
import {Http} from '../utils/http'
import Router from "../router";

// models
import User from "../models/User";

class SecurityApi {
    private static instance: SecurityApi
    private http = Http.Instance

    public static get Instance() {
        return this.instance || (this.instance = new this())
    }

    public async login(email: string, password: string): Promise<any> {
        return this.http.post({
            route: Router.apiLogin,
            data: {email, password},
        })
    }

    public async signUp(user: User): Promise<any> {
        return this.http.post({
            route: Router.apiSignUp,
            data: {...user}
        })
    }

    public async resetPassword(email: string): Promise<any> {
        return this.http.post({
            route: Router.apiResetPassword,
            data: {email}
        })
    }

    public async updatePassword(data: Object): Promise<any> {
        return this.http.put({
            route: Router.apiResetPassword,
            data: {...data}
        })
    }
}

export default SecurityApi
// main libraries
import React from "react";
import {Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

// components
import {InputField} from "../../components/InputField";
import SelectField from "../../components/SelectField";
import CheckboxField from "../../components/CheckboxField";
import ModalMessage from "../../components/ModalMessage";

// utils
import Router from "../../../router";
import ObjFormat from "../../../utils/objFormat";
import ModalProps from "../../../utils/modalProps";

// models
import Document from "../../../models/Document";
import State from "../../../models/State";
import City from "../../../models/City";
import MaritalStatus from "../../../models/MaritalStatus";
import User from "../../../models/User";

// apis
import DocumentApi from "../../../api/DocumentApi"
import StateApi from "../../../api/StateApi";
import CityApi from "../../../api/CityApi";
import GenderApi from "../../../api/GenderApi";
import SexApi from "../../../api/SexApi";
import MaritalStatusApi from "../../../api/MaritalStatusApi";
import SecurityApi from "../../../api/SecurityApi";
import AreaApi from "../../../api/AreaApi";

// styles
import {LoginLink, Scroll, SignUpButton} from "./styles";

function SignUpForm() {
    const [form, setForm] = React.useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        profile: {
            document: '',
            documentNumber: '',
            documentState: '',
            documentCity: '',
            gender: '',
            sex: '',
            acceptTerms: false,
            birthDate: '',
            nationality: '',
            address: '',
            neighborhood: '',
            commune: '',
            stratum: '',
            area: '',
            phone: '',
            birthCity: '',
            city: '',
            maritalStatus: '',
            currentState: '',
            birthState: '',
        }
    })
    const [error, setError] = React.useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        profile: {
            acceptTerms: '',
            document: '',
            documentNumber: '',
            documentState: '',
            documentCity: '',
            gender: '',
            sex: '',
            birthDate: '',
            nationality: '',
            address: '',
            neighborhood: '',
            commune: '',
            stratum: '',
            area: '',
            phone: '',
            birthCity: '',
            city: '',
            maritalStatus: '',
            currentState: '',
            birthState: '',
        }
    })
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })
    const navigate = useNavigate()
    // init API
    const documentApi = DocumentApi.Instance
    const stateApi = StateApi.Instance
    const cityApi = CityApi.Instance
    const genderApi = GenderApi.Instance
    const sexApi = SexApi.Instance
    const areaApi = AreaApi.Instance
    const maritalStatusApi = MaritalStatusApi.Instance
    const securityApi = SecurityApi.Instance
    // list of select fields
    const [typeDocuments, setTypeDocuments] = React.useState<Document[]>([])
    const [states, setState] = React.useState<State[]>([])
    const [documentCities, setDocumentCities] = React.useState<City[]>([])
    const [genders, setGenders] = React.useState<string[]>([])
    const [sexes, setSexes] = React.useState<string[]>([])
    const [areas, setAreas] = React.useState<string[]>([])
    const [cities, setCities] = React.useState<City[]>([])
    const [birthCities, setBirthCities] = React.useState<City[]>([])
    const [maritalStatus, setMaritalStatus] = React.useState<MaritalStatus[]>([])

    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            email: '',
            password: '',
            passwordConfirmation: '',
            firstName: '',
            lastName: '',
            profile: {
                acceptTerms: '',
                document: '',
                documentNumber: '',
                documentState: '',
                documentCity: '',
                gender: '',
                sex: '',
                birthDate: '',
                nationality: '',
                address: '',
                neighborhood: '',
                commune: '',
                stratum: '',
                area: '',
                phone: '',
                birthCity: '',
                city: '',
                maritalStatus: '',
                currentState: '',
                birthState: '',
            }
        }
        setError({...errorsAux, ...errors, profile: {...errorsAux.profile, ...errors?.profile}})
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = ObjFormat.camelToSnakeCase(form) as User
        securityApi.signUp(data).then((response) => {
            if (response?.status === 201) {
                setModal({
                    open: true,
                    title: 'Registro creado satisfactoriamente',
                    message: '¡Se realizó el registro del usuario satisfactoriamente, procede a autenticarte!',
                    type: 'success',
                    onClick: () => redirect()
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data)
                cleanErrors(resp)
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Hay errores en el formulario',
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado, por favor intentelo en unos minutos',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    const getCities = (setStateHook: React.Dispatch<React.SetStateAction<City[]>>, stateId: number) => {
        cityApi.listByStateId(stateId).then((response) => {
            if (response?.status === 200) {
                setStateHook(response.data)
            }
        })
    }

    React.useEffect(() => {
        documentApi.list().then((response: any) => {
            if (response?.status === 200) {
                setTypeDocuments(response.data)
            }
        })
        stateApi.listByCountryId(1).then((response) => {
            if (response?.status === 200) {
                setState(response.data)
            }
        })
        genderApi.list().then((response) => {
            if (response?.status === 200) {
                setGenders(response.data)
            }
        })
        sexApi.list().then((response) => {
            if (response?.status === 200) {
                setSexes(response.data)
            }
        })
        areaApi.list().then((response) => {
            if (response?.status === 200) {
                setAreas(response.data)
            }
        })
        maritalStatusApi.list().then((response) => {
            if (response?.status === 200) {
                setMaritalStatus(response.data)
            }
        })
    }, [documentApi, stateApi, genderApi, sexApi, areaApi, maritalStatusApi])

    const handleChange = (event: any) => {
        let names: string = ''
        if (event.target.name.includes('.')) {
            names = event.target.name.split('.')
            if (event.target.name === 'profile.acceptTerms') {
                setForm({...form, profile: {...form.profile, [names[1]]: event.target.checked}})
            } else {
                setForm({...form, profile: {...form.profile, [names[1]]: event.target.value}})
            }

        } else {
            setForm({...form, [event.target.name]: event.target.value})
        }

        if (event.target.name === 'profile.documentState') {
            setForm({
                ...form, profile: {
                    ...form.profile,
                    documentCity: '',
                    [names[1]]: event.target.value
                }
            })
            getCities(setDocumentCities, parseInt(event.target.value))
        } else if (event.target.name === 'profile.currentState') {
            setForm({
                ...form, profile: {
                    ...form.profile,
                    city: '',
                    [names[1]]: event.target.value
                }
            })
            getCities(setCities, parseInt(event.target.value))
        } else if (event.target.name === 'profile.birthState') {
            setForm({
                ...form, profile: {
                    ...form.profile,
                    birthCity: '',
                    [names[1]]: event.target.value
                }
            })
            getCities(setBirthCities, parseInt(event.target.value))
        }
    }

    const redirect = () => {
        setModal({...modal, open: false})
        navigate(Router.appLogin)
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container style={{margin: '0 -10px', width: '105%'}}>
                    <Scroll>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <InputField
                                    id="email"
                                    name="email"
                                    label="Correo electrónico"
                                    type="email"
                                    required
                                    variant="standard"
                                    error={error.email !== ''}
                                    helperText={error.email[0] ?? ''}
                                    value={form.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    required
                                    variant="standard"
                                    error={error.password !== ''}
                                    helperText={error.password[0] ?? ''}
                                    value={form.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    label="Confirmar contraseña"
                                    type="password"
                                    required
                                    variant="standard"
                                    error={error.passwordConfirmation !== ''}
                                    helperText={error.passwordConfirmation[0] ?? ''}
                                    value={form.passwordConfirmation}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="firstName"
                                    name="firstName"
                                    label="Nombres"
                                    required
                                    variant="standard"
                                    error={error.firstName !== ''}
                                    helperText={error.firstName[0] ?? ''}
                                    value={form.firstName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="lastName"
                                    name="lastName"
                                    label="Apellidos"
                                    required
                                    variant="standard"
                                    error={error.lastName !== ''}
                                    helperText={error.lastName[0] ?? ''}
                                    value={form.lastName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.document"
                                    value={form.profile.document}
                                    label="Tipo de identificación"
                                    required
                                    error={error.profile.document !== ''}
                                    helperText={error.profile.document[0] ?? ''}
                                    onChange={handleChange}
                                    options={typeDocuments.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="documentNumber"
                                    name="profile.documentNumber"
                                    label="Número de identificación"
                                    required
                                    error={error.profile.documentNumber !== ''}
                                    helperText={error.profile.documentNumber[0] ?? ''}
                                    variant="standard"
                                    value={form.profile.documentNumber}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.documentState"
                                    value={form.profile.documentState}
                                    label="Departamento de expedición"
                                    required
                                    error={error.profile.documentState !== ''}
                                    helperText={error.profile.documentState[0] ?? ''}
                                    onChange={handleChange}
                                    options={states.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.documentCity"
                                    value={form.profile.documentCity}
                                    label="Ciudad de expedición"
                                    required
                                    error={error.profile.documentCity !== ''}
                                    helperText={error.profile.documentCity[0] ?? ''}
                                    onChange={handleChange}
                                    options={documentCities.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.gender"
                                    value={form.profile.gender}
                                    label="Género"
                                    required
                                    error={error.profile.gender !== ''}
                                    helperText={error.profile.gender[0] ?? ''}
                                    onChange={handleChange}
                                    options={genders.map((item) => ({
                                            value: item, label: item
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.sex"
                                    value={form.profile.sex}
                                    label="Sexo"
                                    required
                                    error={error.profile.sex !== ''}
                                    helperText={error.profile.sex[0] ?? ''}
                                    onChange={handleChange}
                                    options={sexes.map((item) => ({
                                            value: item, label: item
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="birthDate"
                                    name="profile.birthDate"
                                    label="Fecha de nacimiento"
                                    required
                                    error={error.profile.birthDate !== ''}
                                    helperText={error.profile.birthDate[0] ?? ''}
                                    variant="standard"
                                    type="date"
                                    value={form.profile.birthDate}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }} 
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="nationality"
                                    name="profile.nationality"
                                    label="Nacionalidad"
                                    required
                                    error={error.profile.nationality !== ''}
                                    helperText={error.profile.nationality[0] ?? ''}
                                    variant="standard"
                                    value={form.profile.nationality}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="address"
                                    name="profile.address"
                                    label="Dirección"
                                    required
                                    error={error.profile.address !== ''}
                                    helperText={error.profile.address[0] ?? ''}
                                    variant="standard"
                                    value={form.profile.address}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="neighborhood"
                                    name="profile.neighborhood"
                                    label="Barrio"
                                    required
                                    error={error.profile.neighborhood !== ''}
                                    helperText={error.profile.neighborhood[0] ?? ''}
                                    variant="standard"
                                    value={form.profile.neighborhood}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="commune"
                                    name="profile.commune"
                                    label="Comuna"
                                    required
                                    error={error.profile.commune !== ''}
                                    helperText={error.profile.commune[0] ?? ''}
                                    variant="standard"
                                    value={form.profile.commune}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="stratum"
                                    name="profile.stratum"
                                    label="Estrato"
                                    variant="standard"
                                    value={form.profile.stratum}
                                    onChange={handleChange}
                                    required
                                    error={error.profile.stratum !== ''}
                                    helperText={error.profile.stratum[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.area"
                                    value={form.profile.area}
                                    label="Área"
                                    required
                                    error={error.profile.area !== ''}
                                    helperText={error.profile.area[0] ?? ''}
                                    onChange={handleChange}
                                    options={areas.map((item) => ({
                                            value: item, label: item
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputField
                                    id="phone"
                                    name="profile.phone"
                                    label="Teléfono"
                                    required
                                    error={error.profile.phone !== ''}
                                    helperText={error.profile.phone[0] ?? ''}
                                    variant="standard"
                                    value={form.profile.phone}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.currentState"
                                    value={form.profile.currentState}
                                    label="Departamento actual"
                                    required
                                    error={error.profile.currentState !== ''}
                                    helperText={error.profile.currentState[0] ?? ''}
                                    onChange={handleChange}
                                    options={states.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.city"
                                    value={form.profile.city}
                                    label="Ciudad actual"
                                    required
                                    error={error.profile.city !== ''}
                                    helperText={error.profile.city[0] ?? ''}
                                    onChange={handleChange}
                                    options={cities.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.birthState"
                                    value={form.profile.birthState}
                                    label="Departamento de nacimiento"
                                    required
                                    error={error.profile.birthState !== ''}
                                    helperText={error.profile.birthState[0] ?? ''}
                                    onChange={handleChange}
                                    options={states.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.birthCity"
                                    value={form.profile.birthCity}
                                    label="Ciudad de nacimiento"
                                    required
                                    error={error.profile.birthCity !== ''}
                                    helperText={error.profile.birthCity[0] ?? ''}
                                    onChange={handleChange}
                                    options={birthCities.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SelectField
                                    name="profile.maritalStatus"
                                    value={form.profile.maritalStatus}
                                    label="Estado civil"
                                    required
                                    error={error.profile.maritalStatus !== ''}
                                    helperText={error.profile.maritalStatus[0] ?? ''}
                                    onChange={handleChange}
                                    options={maritalStatus.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                        </Grid>
                    </Scroll>
                    <Grid item md={12} style={{marginTop: 20}}>
                        <CheckboxField
                            name="profile.acceptTerms"
                            label="Aceptar términos y condiciones"
                            download={true}
                            checked={form.profile.acceptTerms}
                            onChange={handleChange}
                            required
                            error={error.profile.acceptTerms !== ''}
                            helperText={error.profile.acceptTerms[0] ?? ''}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <SignUpButton type="submit">Registrarse</SignUpButton>
                    </Grid>
                    <LoginLink to={Router.appLogin}>{'¿Ya tienes una cuenta? Inicia sesión.'}</LoginLink>
                </Grid>
            </form>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
            />
        </>
    )
}

export default SignUpForm
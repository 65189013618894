// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // App URL
    appUrlBase: `${process.env.REACT_APP_URL}`,
    appLogin: '/login',
    appLogout: '/logout',
    appSignUp: '/sign-up',
    appResetPassword: '/reset-password',
    appChangePasswordToken: '/changepassword/:token',
    appChangePassword: '/changepassword',
    appEntrepreneurship: '/entrepreneurship',
    appDiagnostics: '/diagnostic',
    appProfile: '/profile',
    appDashboard: '/dashboard',

    // API URI
    apiUrlBase: `${process.env.REACT_APP_API_URL}`,
    apiLogin: '/api/login/',
    apiSignUp: '/api/register/',
    apiResetPassword: '/api/reset/',
    apiUser: '/api/profiles/',
    apiDocuments: '/api/documents/',
    apiStates: '/api/states/',
    apiCities: '/api/cities/',
    apiConstants: '/api/constants/',
    apiMaritalStatus: '/api/marital_status/',
    apiEthnicities: '/api/ethnicities/',
    apiEducationalLevels: '/api/education_levels/',
    apiOccupations: '/api/occupations/',
    apiLanguages: '/api/languages/',
    apiLanguageLevels: '/api/languages_levels/',
    apiDisabilities: '/api/disabilities/',
    apiHousingTypes: '/api/housing_types/',
    apiHousingTenures: '/api/housings/',
    apiVulnerablePopulations: '/api/vulnerable_populations/',
    apiSocialGroups: '/api/social_groups/',
    apiContactMeans: '/api/contact_means/',
    apiInstitutions: '/api/institutions/',
    apiEntrepreneurships: '/api/entrepreneurships/',
    apiEntrepreneurshipDetail: '/api/entrepreneurships/:id/',
    apiClassifications: '/api/classifications/',
    apiMotivations: '/api/motivations/',
    apiEconomicActivities: '/api/economic_activities/',
    apiFinances: '/api/financies/',
    apiMarketTimes: '/api/market_times/',
    apiWorkTeams: '/api/workteams/',
    apiDedications: '/api/dedications/',
    apiTopics: '/api/topics/',
    apiEconomicRecords: '/api/economic_records/',
    apiChannels: '/api/channels/',
    apiAverages: '/api/average_sales/',
    apiMarketTypes: '/api/market_types/',
    apiIncomeMethods: '/api/income_methods/',
    apiCharacterization: '/api/entrepreneurships/:id/characterizations/',
    apiCharacterizationEdit: '/api/entrepreneurships/:id/characterizations/:characterizationId',
    apiAxis: '/api/axis/',
    apiDashboard: '/api/dashboard/',
    apiPhases: '/api/phases/',
    apiSocialNetworks: '/api/social_networks/',
    apiStatus: '/api/status/',
    apiCharacterizationReport: '/api/characterization_report/',

    // Diagnostics
    apiDiagnostics: '/api/diagnostics/',
    apiDiagnosticDetail: '/api/diagnostics/:id',
    apiDiagnosticByEntrepreneurship: '/api/entrepreneurships/:id/diagnostics/',

    // Services
    apiServices: '/api/services/',
    apiService: '/api/services/:id',
    apiServicesByEntrepreneurship: '/api/entrepreneurships/:id/services/',

    // Trackings
    apiTrackings: '/api/trackings/',
    apiTrackingDetail: '/api/trackings/:id',
    apiTrackingByEntrepreneurship: '/api/entrepreneurships/:id/trackings/',

    // Timeline
    apiTimeline: '/api/entrepreneurships/:id/timeline/',
    apiTimelinePdf: '/api/entrepreneurships/:id/timeline/pdf/',
}
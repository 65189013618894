// main libraries
import {styled} from "@mui/system";
import {FormControl} from "@mui/material";

// utils
import colors from "../../../utils/colors";

export const CustomFormControl = styled(FormControl)({
    width: '100%',
    margin: '10px 0 0',
    '& .MuiFormLabel-root.Mui-focused': {
        color: 'rgb(59, 59, 59)',
        fontSize: '0.9rem',
    },
    '& .MuiInput-underline:after': {
        borderBottom: `1px solid ${colors.dark}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        outline: 'none',
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 1.5px) scale(0.75)',
        fontSize: '1rem',
    }
});
import '../../assets/css/SpinnerLoader.css'

function SpinnerLoader() {
    return (
        <div className="lds-default">
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    )
}

export default SpinnerLoader
// main libraries
import React from 'react';
import {IconButton} from "@mui/material";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// styles
import {CardForm, CardHeader, CardHeaderTitle, Content, Text, Cell, CellText, RowCell, CustomVisibilityIcon} from "./styles";
import {useSearchParams} from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Item {
    id: number
    date: string
    consultant: string
    phase: string
    result: number
    params?: any
}

interface Rows {
    key: number
    name: string
    classification: string
    entrepreneur: string
    count: string
    email: string
    code: string
    diagnostics: Item[]
}

interface Props {
    data: Rows[]
}

function DiagnosticInfo(props: Props) {
    const {data} = props
    let [, setSearchParams] = useSearchParams();

    const getResult = (value: number) => {
        if (value > 50) return "true"
        return "false"
    }

    function Row(props: any) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
      
        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
                    <Cell type="body">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            color="primary"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Cell>
                    <Cell type="body">
                        <RowCell type="main">{row.name}</RowCell>
                        <RowCell type="second">Cod: {row.code}</RowCell>
                    </Cell>
                    <Cell type="body">
                        <RowCell type="main">{row.classification}</RowCell>
                    </Cell>
                    <Cell type="body">
                        <RowCell type="main">{row.entrepreneur}</RowCell>
                        <RowCell type="second">{row.email}</RowCell>
                    </Cell>
                    <Cell type="body">
                        <RowCell type="main" style={{ textAlign: 'center' }}>{row.count}</RowCell>
                    </Cell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Text>Diagnósticos</Text>
                                <Table size="small" style={{ marginBottom: '2rem' }}>
                                    <TableHead>
                                        <TableRow>
                                            <Cell type="subhead">Fecha</Cell>
                                            <Cell type="subhead">Etapa</Cell>
                                            <Cell type="subhead">Consultor</Cell>
                                            <Cell type="subhead">Resultado</Cell>
                                            <Cell type="subhead">Ver</Cell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.diagnostics.map((detailRow: any) => (
                                            <TableRow key={detailRow.id}>
                                                <Cell type="subbody">{detailRow.date}</Cell>
                                                <Cell type="subbody">{detailRow.phase}</Cell>
                                                <Cell type="subbody">{detailRow.consultant}</Cell>
                                                <Cell type="subbody">
                                                    <CellText isresult={getResult(detailRow.result)}>{detailRow.result}%</CellText>
                                                </Cell>
                                                <Cell type="subbody">
                                                    <IconButton onClick={() => setSearchParams(detailRow.params)}>
                                                        <CustomVisibilityIcon/>
                                                    </IconButton>
                                                </Cell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <CardForm elevation={0}>
            <CardHeader>
                <CardHeaderTitle>Diagnósticos cargados</CardHeaderTitle>
            </CardHeader>
            <Content>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <Cell type="head"></Cell>
                                <Cell type="head">Emprendimiento</Cell>
                                <Cell type="head">Sector comercial</Cell>
                                <Cell type="head">Emprendedor</Cell>
                                <Cell type="head">Diagnósticos</Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <Row key={row.key} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Content>
        </CardForm>
    )
}

export default DiagnosticInfo
// main libraries

// components
import Layout from "../../containers/Layout";
import ResetPasswordForm from "../../containers/ResetPasswordForm";

// styles & assets
import CustomCard from "../../components/CustomCard";

function ResetPasswordPage() {
    return (
        <Layout>
            <CustomCard title="Recuperar Contraseña" width={450}>
                <ResetPasswordForm/>
            </CustomCard>
        </Layout>
    )
}

export default ResetPasswordPage
// main libraries
import React from "react";
import {Grid, SelectChangeEvent} from "@mui/material";
import {useDispatch} from "react-redux";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import Section from "../../components/Section";
import TextField from "../../components/TextField";
import CustomSelect from "../../components/CustomSelect";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";
import ModalMessage from "../../components/ModalMessage";

// models
import City from "../../../models/City";
import SocialNetwork from "../../../models/SocialNetwork";
import State from "../../../models/State";
import Entrepreneurship from "../../../models/Entrepreneurship";

// api
import StateApi from "../../../api/StateApi";
import CityApi from "../../../api/CityApi";
import AreaApi from "../../../api/AreaApi";
import SocialNetworkApi from "../../../api/SocialNetworkApi";
import EntrepreneurshipApi from "../../../api/EntrepreneurshipApi";

// icons
import StorefrontIcon from "@mui/icons-material/Storefront";

// utils
import ObjFormat from "../../../utils/objFormat";
import ModalProps from "../../../utils/modalProps";

// styles
import {CardForm, CardHeader, CardHeaderTitle, Content, SaveButton} from "./styles";
import { useNavigate } from "react-router-dom";

interface Props {
    refresh: () => void
}

function EntrepreneurshipForm(props: Props) {
    const {refresh} = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form, setForm] = React.useState<any>({
        name: '',
        code: '',
        address: '',
        neighborhood: '',
        commune: '',
        stratum: '',
        state: '',
        city: '',
        area: '',
        phone: '',
        cellphone: '',
        email: '',
        hasWebsite: false,
        website: '',
        hasSocialNetwork: false,
        socialNetwork: '',
        socialNetworkList: [] as any,
        hasRut: false,
        rut: '',
    })
    const [error, setError] = React.useState({
        name: '',
        code: '',
        address: '',
        neighborhood: '',
        commune: '',
        stratum: '',
        state: '',
        city: '',
        area: '',
        phone: '',
        cellphone: '',
        email: '',
        hasWebsite: '',
        website: '',
        hasSocialNetwork: '',
        socialNetwork: '',
        socialNetworkList: '',
        hasRut: '',
        rut: '',
    })
    const [answerList, ] = React.useState<any[]>([
        {value: true, name: 'Sí'},
        {value: false, name: 'No'},
    ])
    const [modal, setModal] = React.useState<ModalProps>({
        open: false,
        title: '',
        message: '',
        type: 'error',
        onClick: () => handleClose()
    })

    // init API
    const stateApi = StateApi.Instance
    const cityApi = CityApi.Instance
    const areaApi = AreaApi.Instance
    const socialNetworkApi = SocialNetworkApi.Instance
    const entrepreneurshipApi = EntrepreneurshipApi.Instance

    // list of select fields
    const [states, setStates] = React.useState<State[]>([])
    const [cities, setCities] = React.useState<City[]>([])
    const [areas, setAreas] = React.useState<string[]>([])
    const [socialNetworks, setSocialNetworks] = React.useState<SocialNetwork[]>([])

    const handleChange = (event: any) => {
        let array = ['hasWebsite', 'hasRut', 'hasSocialNetwork'] 
        if (array.includes(event.target.name) && !event.target.value) {
            handleClear(event.target.name)
            return
        }
        
        if (event.target.type === 'checkbox') {
            setForm({...form, [event.target.name]: event.target.checked})
        } else {
            setForm({...form, [event.target.name]: event.target.value})
        }

        if (event.target.name === 'state') {
            setForm({...form, city: '', [event.target.name]: event.target.value})
            getCities(setCities, parseInt(event.target.value))
        }
    }

    const handleClear = (name: any) => {
        if (name === 'hasWebsite') {
            setForm({...form, website: '', [name]: false})
        }
        else if (name === 'hasSocialNetwork') {
            setForm({...form, socialNetworkList: [] as any, [name]: false})
        }
        else if (name === 'hasRut') {
            setForm({...form, rut: '', [name]: false})
        }
    }
    
    const handleChangeSelect = (event: SelectChangeEvent<any>) => {
        const { target: { value, name } } = event;
        setForm({...form, [name]: value})
    };

    const getCities = (setStateHook: React.Dispatch<React.SetStateAction<City[]>>, stateId: number) => {
        cityApi.listByStateId(stateId).then((response) => {
            if (response?.status === 200) {
                setStateHook(response.data)
            }
        })
    }

    const init = React.useCallback(async () => {
        dispatch(activeLoading())
        await stateApi.listByCountryId(1).then((response) => {
            if (response?.status === 200) {
                setStates(response.data)
            }
        })
        await areaApi.list().then((response) => {
            if (response?.status === 200) {
                setAreas(response.data)
            }
        })
        await socialNetworkApi.list().then((response) => {
            if (response?.status === 200) {
                setSocialNetworks(response.data)
            }
        })
    }, [dispatch, stateApi, areaApi, socialNetworkApi])

    React.useEffect(() => {
        init().then(() => {
            dispatch(deactivateLoading())
        })
    }, [init, dispatch])

    const cleanErrors = (errors?: any) => {
        const errorsAux = {
            name: '',
            code: '',
            address: '',
            neighborhood: '',
            commune: '',
            stratum: '',
            state: '',
            city: '',
            area: '',
            phone: '',
            cellphone: '',
            email: '',
            hasWebsite: '',
            website: '',
            hasSocialNetwork: '',
            socialNetwork: '',
            socialNetworkList: '',
            hasRut: '',
            rut: '',
        }
        setError({...errorsAux, ...errors})
    }

    const clearForm = () => {
        setForm(({
            name: '',
            code: '',
            address: '',
            neighborhood: '',
            commune: '',
            stratum: '',
            state: '',
            city: '',
            area: '',
            phone: '',
            cellphone: '',
            email: '',
            hasWebsite: false,
            website: '',
            hasSocialNetwork: false,
            socialNetwork: '',
            socialNetworkList: [] as any,
            hasRut: false,
            rut: '',
        }))
    }

    const handleClose = () => {
        setModal({...modal, open: false})
    }

    const handleRedirect = (id: any) => {
        setModal({...modal, open: false})
        navigate(`/entrepreneurship?item=${id}&detail=entrepreneurship&type=new_characterization`);
    }

    const refreshData = () => {
        setModal({...modal, open: false})
        refresh()
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        
        let data = {...form}
        if (data.socialNetworkList?.length === 0) data.socialNetworkList = null
        let formData = ObjFormat.camelToSnakeCase(data) as Entrepreneurship
        entrepreneurshipApi.create(formData).then((response) => {
            if (response?.status === 201) {
                clearForm()
                refreshData()
                setModal({
                    open: true,
                    title: 'Emprendimiento creado correctamente',
                    message: 'Te invitamos a registrar la información ampliada del emprendimiento',
                    type: 'custom',
                    onClick: () => handleRedirect(response.data.id)
                })
            } else if (response?.status === 400) {
                const resp = ObjFormat.snakeToCamelCase(response.data)
                cleanErrors(resp)
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Hay errores en el formulario',
                    type: 'error',
                    onClick: () => handleClose()
                })
            } else {
                setModal({
                    open: true,
                    title: 'Error',
                    message: 'Se presentó un error inesperado, por favor intentelo en unos minutos',
                    type: 'error',
                    onClick: () => handleClose()
                })
            }
        })
    }

    return (
        <>
            <CardForm elevation={0}>
                <CardHeader>
                    <CardHeaderTitle>Información del Emprendimiento</CardHeaderTitle>
                </CardHeader>
                <Content>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <Section name="Información Básica"/>
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="name"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    label="Nombre del emprendimiento"
                                    required
                                    error={error.name !== ''}
                                    helperText={error.name[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="code"
                                    name="code"
                                    value={form.code}
                                    onChange={handleChange}
                                    label="Identificación del emprendimiento (Nit/Cédula)"
                                    required
                                    error={error.code !== ''}
                                    helperText={error.code[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                    label="Correo electrónico"
                                    required
                                    type="email"
                                    error={error.email !== ''}
                                    helperText={error.email[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="stratum"
                                    name="stratum"
                                    value={form.stratum}
                                    onChange={handleChange}
                                    label="Estrato"
                                    required
                                    error={error.stratum !== ''}
                                    helperText={error.stratum[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="phone"
                                    name="phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                    label="Teléfono"
                                    type="tel"
                                    required
                                    error={error.phone !== ''}
                                    helperText={error.phone[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="cellphone"
                                    name="cellphone"
                                    value={form.cellphone}
                                    onChange={handleChange}
                                    label="Celular"
                                    required
                                    type="tel"
                                    error={error.cellphone !== ''}
                                    helperText={error.cellphone[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Section name="Ubicación"/>
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="state"
                                    value={form.state}
                                    label="Departamento"
                                    required
                                    error={error.state !== ''}
                                    helperText={error.state[0] ?? ''}
                                    onChange={handleChange}
                                    options={states.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="city"
                                    value={form.city}
                                    label="Ciudad"
                                    required
                                    error={error.city !== ''}
                                    helperText={error.city[0] ?? ''}
                                    onChange={handleChange}
                                    options={cities.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="address"
                                    name="address"
                                    value={form.address}
                                    onChange={handleChange}
                                    label="Dirección"
                                    required
                                    error={error.address !== ''}
                                    helperText={error.address[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="neighborhood"
                                    name="neighborhood"
                                    value={form.neighborhood}
                                    onChange={handleChange}
                                    label="Barrio/Vereda"
                                    required
                                    error={error.neighborhood !== ''}
                                    helperText={error.neighborhood[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <CustomSelect
                                    name="area"
                                    value={form.area}
                                    label="Área geográfica"
                                    required
                                    error={error.area !== ''}
                                    helperText={error.area[0] ?? ''}
                                    onChange={handleChange}
                                    options={areas.map((item) => ({
                                            value: item, label: item
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="commune"
                                    name="commune"
                                    value={form.commune}
                                    onChange={handleChange}
                                    label="Comuna/Corregimiento"
                                    required
                                    error={error.neighborhood !== ''}
                                    helperText={error.neighborhood[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Section name="Adicional"/>
                            </Grid>
                            <Grid item md={6}>                  
                                <CustomSelect
                                    name="hasWebsite"
                                    value={form.hasWebsite}
                                    label="¿Tiene un sitio web?"
                                    error={error.hasWebsite !== ''}
                                    helperText={error.hasWebsite[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="website"
                                    name="website"
                                    value={form.website}
                                    onChange={handleChange}
                                    label="Url sitio web"
                                    required={!(!form.hasWebsite)}
                                    disabled={!form.hasWebsite}
                                    type="text"
                                    error={error.website !== ''}
                                    helperText={error.website[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>             
                                <CustomSelect
                                    name="hasSocialNetwork"
                                    value={form.hasSocialNetwork}
                                    label="¿Tiene redes sociales?"
                                    error={error.hasSocialNetwork !== ''}
                                    helperText={error.hasSocialNetwork[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>                
                                <CustomSelectMultiple
                                    name="socialNetworkList"
                                    value={form.socialNetworkList}
                                    label="Redes sociales"
                                    required={!(!form.hasSocialNetwork)}
                                    disabled={!form.hasSocialNetwork}
                                    error={error.socialNetworkList !== ''}
                                    helperText={error.socialNetworkList[0] ?? ''}
                                    onChange={handleChangeSelect}
                                    options={socialNetworks.map((item) => ({
                                            value: item.id, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>          
                                <CustomSelect
                                    name="hasRut"
                                    value={form.hasRut}
                                    label="¿Tiene RUT?"
                                    error={error.hasRut !== ''}
                                    helperText={error.hasRut[0] ?? ''}
                                    onChange={handleChange}
                                    options={answerList.map((item) => ({
                                            value: item.value, label: item.name
                                        })
                                    ) as []}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    id="rut"
                                    name="rut"
                                    value={form.rut}
                                    onChange={handleChange}
                                    label="NIT"
                                    required={!(!form.hasRut)}
                                    disabled={!form.hasRut}
                                    error={error.rut !== ''}
                                    helperText={error.rut[0] ?? ''}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <SaveButton type="submit">Guardar</SaveButton>
                            </Grid>
                        </Grid>
                    </form>
                </Content>
            </CardForm>
            <ModalMessage
                open={modal.open}
                type={modal.type}
                title={modal.title}
                description={modal.message}
                labelButton="Aceptar"
                onClick={modal.onClick}
                icon={<StorefrontIcon style={{fontSize: 100, color: '#fff'}}/>}
            />
        </>
    )
}

export default EntrepreneurshipForm
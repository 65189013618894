// main libraries
import React from "react";
import {CssBaseline, ThemeProvider} from '@mui/material'
import {useSelector} from "react-redux"

// components
import ModalLoading from "../../components/ModalLoading"

// reducers
import {selectLoading} from "../../../reducers/loading/loadingSlice"

// styles
import {theme} from '../../components/Global/style'
import {Container, Content} from './styles'

export interface LayoutProps {
    children: React.ReactNode
}

function Layout({children}: LayoutProps) {
    const isLoading = useSelector(selectLoading)

    return (
        <Container>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Content container>
                    {isLoading ? <ModalLoading title='Cargando...'/> : null}
                    {children}
                </Content>
            </ThemeProvider>
        </Container>
    )
}

export default Layout
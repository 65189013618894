// main libraries
import {useSearchParams} from "react-router-dom";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// styles
import {Content, EndIcon, IconContainer, Item, StartIcon, Text} from "./styles";

interface Props {
    firstTitle: string
    secondTitle: string
    icon: JSX.Element
    params?: any
}

function DiagnosticListItem(props: Props) {
    const {firstTitle, secondTitle, params, icon} = props
    let [searchParams, setSearchParams] = useSearchParams();

    const isSelected = (): boolean => {
        return searchParams.get('diagnostic') === params?.diagnostic?.toString();
    }

    return (
        <Item 
            onClick={() => setSearchParams(params)}
            selected={isSelected()}
        >
            <StartIcon>
                <IconContainer>
                    {icon}
                </IconContainer>
            </StartIcon>
            <Content>
                <Text>{firstTitle}</Text>
                <Text>{secondTitle}</Text>
            </Content>
            <EndIcon>
                <ChevronRightIcon fontSize="inherit"/>
            </EndIcon>
        </Item>
    )
}

export default DiagnosticListItem
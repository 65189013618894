import {styled} from "@mui/system";
import {Checkbox, FormControlLabel} from "@mui/material";
import colors from "../../../utils/colors";

export const CustomCheckbox = styled(Checkbox)({
    color: colors.dark,
});

export const Required = styled('span')({
    color: 'red'
})

export const CustomControlLabel = styled(FormControlLabel)({
    '& .Mui-disabled': {
        cursor: 'not-allowed',
        '-webkit-text-fill-color': colors.disabledColor,
    }
})

export const Anchor = styled('a')({
    color: colors.second,
    textDecoration: 'none'
})
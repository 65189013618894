import {Card, Typography} from "@mui/material";
import {styled} from "@mui/system";
import colors from "../../../utils/colors";

export const Container = styled('div')({
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    backgroundColor: colors.main,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20
})

interface ContainerCardProps {
    width?: number
    height?: number
}

export const ContainerCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'width',
})<ContainerCardProps>(({width, height}) => ({
    width: width ? width : 550,
    minHeight: 550,
    height: '100%',
    margin: 'auto',
    borderRadius: 20,
}))

export const Content = styled(Card)({
    height: '100%',
    width: '100%',
    padding: '5px 50px',
    boxShadow: 'none',
})

export const Image = styled('img')({
    display: 'block',
    margin: '25px auto 10px auto',
    width: 200,
})

export const Title = styled(Typography)({
    width: '100%',
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    color: colors.dark,
    marginBottom: 10,
    paddingTop: 10
})
// main libraries

// components
import Layout from "../../containers/Layout";
import LoginForm from "../../containers/LoginForm";

// styles & assets
import CustomCard from "../../components/CustomCard";

function LoginPage() {
    return (
        <Layout>
            <CustomCard title="Inicio de sesión" width={450} height={500}>
                <LoginForm/>
            </CustomCard>
        </Layout>
    )
}

export default LoginPage
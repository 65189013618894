// main libraries
import React from "react"
import {Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import moment from "moment";

// reducers
import {activeLoading, deactivateLoading} from "../../../reducers/loading/loadingSlice";

// components
import DiagnosticsList from "../../containers/DiagnosticsList";
import BackListItem from "../../components/BackListItem";
import DiagnosticInfo from '../../containers/DiagnosticInfo/index';

// models
import Diagnostic from "../../../models/Diagnostic";

// api
import DiagnosticApi from "../../../api/DiagnosticApi";

// utils
import { Auth } from "../../../utils/auth";

// styles
import {
    Container,
    Expanded,
    ExpandedContent,
    SummaryCard,
    Title,
    Text
} from "./styles";
import DiagnosticStatistic from "../../containers/DiagnosticStatistic";

function DiagnosticPage() {
    let [searchParams, ] = useSearchParams();
    const dispatch = useDispatch();

    // init API
    const diagnosticApi = DiagnosticApi.Instance
    const auth = Auth.Instance

    // list
    const [diagnostics, setDiagnostics] = React.useState<Diagnostic[]>([])
    const [result, setResult] = React.useState<any>({
        detail: [],
        summary: {
            successful: 0,
            total: 0
        }
    })
    const [currentData, setCurrentData] = React.useState<any>({
        name: '',
        code: ''
    })

    const getForm = () => {
        if (searchParams.get('type') === 'detail') {
            return <DiagnosticStatistic 
                diagnosticId={searchParams.get('diagnostic')} 
                getDiagnostics={getDiagnostics}
                isAllowed={isAllowed(['Consultor'])}
            />
        } 
        return <DiagnosticInfo 
            data={getList()}
        />
    }

    React.useEffect(() => {
        if (searchParams.get('type') === 'detail') {
            let data = diagnostics.find(e => e.code === searchParams.get('item'))
            if (data) setCurrentData({name: data?.name, code: data?.code})
        } 
    }, [searchParams.get('type')])

    const getDiagnostics = React.useCallback(() => {
        dispatch(activeLoading())

        diagnosticApi.list().then((response) => {
            if (response?.status === 200) {
                let resp = response.data
                resp.map((row: any) => {
                    row.params = {item: row.code, type: 'detail'}

                    row.diagnostics.map((rowDetail: any, index: number) => {
                        rowDetail.params = {item: row.code, type: 'detail', diagnostic: rowDetail.id}
                    })

                    row.diagnostics.sort(function (a: any, b: any) {
                        if (a.id > b.id) {
                        return 1;
                        }
                        if (a.id < b.id) {
                        return -1;
                        }
                        return 0;
                    })
                })

                setDiagnostics(resp)
                  
            }
            dispatch(deactivateLoading())
        })
    }, [diagnosticApi, dispatch])

    const onFileChange = React.useCallback((event: any) => {
        dispatch(activeLoading())

        let file = event.target.files[0]

        diagnosticApi.create(file).then(async (response) => {
            if (response?.status === 201) {
                setResult({
                    detail: [],
                    summary: {
                        successful: 0,
                        total: 0
                    }
                })
                let resp = response.data
                resp.detail.map((row: any) => {
                    row.params = {item: row.code, type: 'detail'}
                    row.diagnosticDate = `Diagnóstico ${getDate(row.mise_date, 'DD - MM - YYYY')}`
                })
                setResult(resp)
                getDiagnostics()
            }
            dispatch(deactivateLoading())
        })
        
    }, [diagnosticApi, dispatch])

    const getList = () => {
        return diagnostics.map((item) => ({
            key: item.id,
            name: item.name,
            classification: item.classification,
            entrepreneur: item.entrepreneur,
            email: item.email,
            code: item.code,
            count: `${item.count}`,
            diagnostics: item.diagnostics.map((detail) => ({
                id: detail.id,
                date: getDate(detail.load_date),
                consultant: detail.mise_consultant,
                phase: detail.phase,
                result: detail.mise_result,
                params: {item: item.code, type: 'detail', diagnostic: detail.id}
            })),
            params: {item: item.code, type: 'detail'}
        }))
    }

    const getTitle = () => {
        if (searchParams.get('type') === 'detail') {
            return ['Diagnósticos', 'Cargar nuevos', 'Diagnósticos', 'detail']
        }

        return ['Cargar diagnósticos', 'Cargar nuevos', 'Diagnósticos', 'diagnostic']
    }

    const getHide = () => {
        if (searchParams.get('type') === 'detail') return true
        else return false
    }

    const getDate = (date: any, format?: string) => {
        const d = new Date(date);
        if (format) return moment(d).format(format);
        return moment(d).format('DD/MM/YYYY'); 
    }

    const findDiagnostics = () => {
        let data = diagnostics.find(e => e.code === searchParams.get('item'))
        if (data?.diagnostics) {
            data?.diagnostics.map((item: any) => {
                item.diagnosticDate = `Diagnóstico ${getDate(item.mise_date, 'DD - MM - YYYY')}`
            })
        }
        return data?.diagnostics
    }

    const hasBackOption = () => {
        return searchParams.get('type') === 'detail'
    }

    const isAllowed = (allowed: string[]) => {
        if (auth.isRoleAllowed(allowed)) return true
        return false
    }

    React.useEffect(() => {
        getDiagnostics()
    }, [getDiagnostics])

    return (
        <>
            <Container container>
                <Grid item md={4} style={{paddingRight: '20px'}}>
                    <Expanded>
                        <ExpandedContent
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            {hasBackOption() ?
                                <BackListItem
                                    firstTitle={currentData.name ?? ''}
                                    secondTitle={'NIT. ' + currentData.code ?? ''}
                                /> :
                                null
                            }
                            <DiagnosticsList
                                title={getTitle()[0]}
                                firstItemText={getTitle()[1]}
                                secondItemText={getTitle()[2]}
                                result={result}
                                list={findDiagnostics()}
                                type={getTitle()[3]}
                                hide={getHide()}
                                onChange={onFileChange}
                                cardHeight={hasBackOption()}
                            />
                            {(result.detail.length > 0 && !getHide()) &&
                                <SummaryCard>
                                    <Title>{result.summary.successful} de {result.summary.total}</Title>
                                    <Text>diagnósticos se cargaron exitosamente</Text>
                                </SummaryCard>
                            }
                        </ExpandedContent>
                    </Expanded>
                </Grid>
                <Grid item md={8}>
                    {getForm()}
                </Grid>
            </Container>
        </>
    )
}

export default DiagnosticPage
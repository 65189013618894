// main libraries
import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";

// reducers
import {selectUser} from "../../../reducers/user/userSlice";

// utils
import Router from "../../../router";

// icons
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

// styles
import {AvatarContainer, AvatarTitle, CardContainer, Container, MessageText, RedirectButton} from "./styles";

function Security() {
    const user = useSelector(selectUser)
    const navigate = useNavigate()

    const redirect = () => {
        navigate(Router.appProfile)
    }

    return (
        <Container
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <CardContainer>
                <AvatarTitle>¡Hola, {user?.firstName}!</AvatarTitle>
                <AvatarContainer>
                    <AdminPanelSettingsIcon style={{fontSize: 100}}/>
                </AvatarContainer>
                <MessageText>Te invitamos a completar tu perfil para continuar navegando por la plataforma.</MessageText>
                <RedirectButton onClick={redirect}>Aceptar</RedirectButton>
            </CardContainer>
        </Container>
    )
}

export default Security
// main libraries
import React from 'react';
import {
    Box,
    CssBaseline
} from "@mui/material"

// components
import Layout from "../Layout";
import AppBar from "../../components/AppBar"
import Sidebar from "../../components/Sidebar"

// styles & assets
import {Container, DrawerHeader} from './styles'


export default function Dashboard({children}: { children: JSX.Element }) {
    const [open, setOpen] = React.useState(true)

    const handleDrawerOpen = () => {
        setOpen(true)
    };

    const handleDrawerClose = () => {
        setOpen(false)
    };

    return (
        <Layout>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar open={open} onOpen={handleDrawerOpen}/>
                <Sidebar open={open} onClose={handleDrawerClose}/>
                <Container isOpen={open} component="main">
                    <DrawerHeader/>
                    {children}
                </Container>
            </Box>
        </Layout>
    );
}
